import {
    CHECK_GUEST,
    REGISTER_GUEST_SUCCESS,
    GUEST_API_FAILED,
    GUEST_ERROR_CLEAR
} from './actionTypes';

const initialState = {
    loginError: null,
    message: null,
    loading: null,
    guest_session: null
}

const guest = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_GUEST:
            state = {
                ...state,
                guest_session: null,
                loading: true,
                loginError: null
            }
            break;

        case REGISTER_GUEST_SUCCESS:
            state = {
                ...state,
                guest_session: action.payload,
                loading: false,
                loginError: null
            }
            break;

        case GUEST_API_FAILED:
            state = {
                ...state,
                loading: false,
                loginError: action.payload
            }
            break;

        case GUEST_ERROR_CLEAR:
            state = {
                ...state,
                loginError: null,
                guest_session: null,
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default guest;