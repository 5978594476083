import React, { Component } from 'react';
import { Col, Row, Card, CardBody, FormGroup, InputGroup, Label, Button, Alert } from "reactstrap";
import { connect } from "react-redux";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import SelectInput from "../../component/Common/SelectInput";
import ColorPicker from "../../component/Common/ColorPicker";
import ColorTransparentPicker from "../../component/Common/ColorTransparentPicker";
import GradientColor from "../../component/Common/GradientColor";

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, postApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class UrlEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems: this.props.router.params.urlId ? [
                { title: "Dashboard", link: "/dashboard" },
                { title: "Urls", link: "/urls" },
                { title: "Edit Url", link: "#" },
            ]: [
                { title: "Dashboard", link: "/dashboard" },
                { title: "Urls", link: "/urls" },
                { title: "Create Url", link: "#" },
            ],
            urlTypes: [],
            formUrl: {
                id: this.props.router.params.urlId,
                redirection: '',
                redirection_title: '',
                domain_id: 0,
                site_url: '',
                type: '',
                title: '',
                background_type: 'Image',
                background_color: '',
                background_gradient: '',
                background: '',
                background_file: null,
                cover_image: '',
                cover_image_file: null,
                button_background: '',
                button_text: '',
                status: 'ACTIVE'
            },
            errors: null,
            user: this.props.user,
            domains: [],
            formServices: [],
            services: [],
            servicesList: [],
            serviceId: 0,
            formSocials: [],
            socials: [],
            socialsList: [],
            socialId: 0,
            successAlert: '',
            errorAlert: ''
        }

        this.getActiveDomainLink = this.getActiveDomainLink.bind(this);
        this.getDomainHost = this.getDomainHost.bind(this);
        this.getDomainPathname = this.getDomainPathname.bind(this);

        this.onAddService = this.onAddService.bind(this);
        this.onChangeServiceId = this.onChangeServiceId.bind(this);
        this.onChangeService = this.onChangeService.bind(this);
        this.onRemoveService = this.onRemoveService.bind(this);
        this.onAddSocial = this.onAddSocial.bind(this);
        this.onChangeSocialId = this.onChangeSocialId.bind(this);
        this.onChangeSocial = this.onChangeSocial.bind(this);
        this.onRemoveSocial = this.onRemoveSocial.bind(this);

        this.loadDomains = this.loadDomains.bind(this);
        this.loadSocials = this.loadSocials.bind(this);
        this.loadServices = this.loadServices.bind(this);
        this.loadUrlTypes = this.loadUrlTypes.bind(this);
        this.loadUrl = this.loadUrl.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
        this.handleChangeCustomField = this.handleChangeCustomField.bind(this);
    }

    getActiveDomainLink = (domain_id = '') => {
        if (this.state.domains && this.state.domains.length) {
            return this.state.domains.filter((domain) => domain['id'] === (domain_id ? domain_id : this.state.formUrl.domain_id))[0]['link'];
        }
        return '';
    }

    getDomainHost = (url) => {
        try {
            let url_obj = new URL(url);
            return url_obj.origin;
        } catch (_) {
            return '';
        }
    }

    getDomainPathname = (url) => {
        try {
            let url_obj = new URL(url);
            return url_obj.pathname;
        } catch (_) {
            return url;
        }
    }
    
    onAddService = () => {
        let services = this.state.services; let servicesList = this.state.servicesList; let formServices = this.state.formServices; let newServicesList = [];
        for (let service_index = 0; service_index < services.length; service_index++) {
            if (this.state.serviceId === services[service_index]['id'] ) {
                formServices.push({
                    id: services[service_index]['id'],
                    link: services[service_index]['link'],
                    name: services[service_index]['name']
                });
            } else {
                for (let service_list_index = 0; service_list_index < servicesList.length; service_list_index++) {
                    if (services[service_index]['id'] === servicesList[service_list_index]['id']) {
                        newServicesList.push(services[service_index]);
                    }
                }
            }
        }
        this.setState({
            serviceId: newServicesList.length ? newServicesList[0]['id'] : 0,
            servicesList: newServicesList,
            formServices: formServices
        });
    };

    onChangeServiceId = (event) => {
        this.setState({
            serviceId: Number(event.target.value)
        });
    };

    onChangeService = (index, event) => {
        const { name, value } = event.target;

        let formServices = this.state.formServices;
        if (name === 'link') {
            formServices[index][name] = this.getDomainPathname(value).replace(/^\//, '');
        } else {
            formServices[index][name] = value;
        }
        this.setState({
            formServices: formServices
        });
    };

    onRemoveService = (index) => {
        let formServices = this.state.formServices;
        formServices.splice(index, 1);
        let services = this.state.services; let servicesList = [];
        for (let service_index = 0; service_index < services.length; service_index++) {
            let find_service = false;
            for (let form_service_index = 0; form_service_index < formServices.length; form_service_index++) {
                if (services[service_index]['id'] === formServices[form_service_index]['id']) {
                    find_service = true;
                }
            }
            if (!find_service) {
                servicesList.push(services[service_index]);
            }
        }
        this.setState({
            servicesList: servicesList,
            formServices: formServices
        });
    };

    onAddSocial = () => {
        let socials = this.state.socials; let socialsList = this.state.socialsList; let formSocials = this.state.formSocials; let newSocialsList = [];
        for (let social_index = 0; social_index < socials.length; social_index++) {
            if (this.state.socialId === socials[social_index]['id']) {
                formSocials.push({
                    id: socials[social_index]['id'],
                    display_text: '',
                    text: '',
                    display_link: '',
                    link: '',
                    name: socials[social_index]['name'],
                    default_display_text: socials[social_index]['display_text'],
                    input_text: socials[social_index]['input_text'],
                    default_display_link: socials[social_index]['display_link']
                });
            } else {
                for (let social_list_index = 0; social_list_index < socialsList.length; social_list_index++) {
                    if (socials[social_index]['id'] === socialsList[social_list_index]['id']) {
                        newSocialsList.push(socials[social_index]);
                    }
                }
            }
        }
        this.setState({
            socialId: newSocialsList.length ? newSocialsList[0]['id'] : 0,
            socialsList: newSocialsList,
            formSocials: formSocials
        });
    };

    onChangeSocialId = (event) => {
        this.setState({
            socialId: Number(event.target.value)
        });
    };

    onChangeSocial = (index, event) => {
        const { name, value } = event.target;

        let formSocials = this.state.formSocials;
        if (name === 'text') {
            formSocials[index]['display_link'] = formSocials[index]['default_display_text'].replace(/\$input_text/, this.getDomainPathname(value).replace(/^\//, ''));
        }
        formSocials[index][name] = value;
        this.setState({
            formSocials: formSocials
        });
    };

    onRemoveSocial = (index) => {
        let formSocials = this.state.formSocials;
        formSocials.splice(index, 1);
        let socials = this.state.socials; let socialsList = [];
        for (let social_index = 0; social_index < socials.length; social_index++) {
            let find_social = false;
            for (let form_social_index = 0; form_social_index < formSocials.length; form_social_index++) {
                if (socials[social_index]['id'] === formSocials[form_social_index]['id']) {
                    find_social = true;
                }
            }
            if (!find_social) {
                socialsList.push(socials[social_index]);
            }
        }
        this.setState({
            socialsList: socialsList,
            formSocials: formSocials
        });
    };

    loadDomains() {
        getApi('/domains').then(
            (response) => {
                if (response.status === 'success') {
                    this.setState({
                        domains: response.domains.map(domain => {
                            return { ...domain, link: domain['link'].replace(/^http:\/\//, '').replace(/^https:\/\//, '') };
                        }),
                        formUrl: {
                            ...this.state.formUrl,
                            domain_id: response.domains.length ? response.domains[0]['id'] : 0,
                            site_url: '',
                        }
                    });
                } else {
                    this.setState({
                        domains: [],
                        formUrl: {
                            ...this.state.formUrl,
                            domain_id: 0,
                            site_url: '',
                        }
                    });
                }
            },
            (error) => {
                this.setState({
                    domains: [],
                    formUrl: {
                        ...this.state.formUrl,
                        domain_id: 0,
                        site_url: '',
                    }
                });
            }
        )
    }

    loadServices() {
        getApi('/services/active').then(
            (response) => {
                if (response.status === 'success') {
                    if (this.props.router.params.urlId) {
                        let servicesList = [];
                        for (let serviceListIndex = 0; serviceListIndex < this.state.servicesList.length; serviceListIndex++) {
                            let serviceListExist = false;
                            for (let serviceIndex = 0; serviceIndex < response.services.length; serviceIndex++) {
                                if (this.state.servicesList[serviceListIndex]['id'] === response.services[serviceIndex]['id']) {
                                    serviceListExist = true;
                                }
                            }
                            if (!serviceListExist) {
                                servicesList.push(this.state.servicesList[serviceListIndex]);
                            }
                        }
                        this.setState({
                            serviceId : response.services.length ? response.services[0]['id'] : 0,
                            services: response.services,
                            servicesList: servicesList,
                        });
                    } else {
                        this.setState({
                            serviceId : response.services.length ? response.services[0]['id'] : 0,
                            services: response.services,
                            servicesList: response.services,
                        });
                    }
                } else {
                    this.setState({
                        serviceId : 0,
                        services: [],
                        servicesList: [],
                    });
                }
            },
            (error) => {
                this.setState({
                    services: [],
                });
            }
        )
    }

    loadSocials() {
        getApi('/socials/active').then(
            (response) => {
                if (response.status === 'success') {
                    if (this.props.router.params.urlId) {
                        let socialsList = [];
                        for (let socialListIndex = 0; socialListIndex < this.state.socialsList.length; socialListIndex++) {
                            let socialListExist = false;
                            for (let socialIndex = 0; socialIndex < response.socials.length; socialIndex++) {
                                if (this.state.socialsList[socialListIndex]['id'] === response.socials[socialIndex]['id']) {
                                    socialListExist = true;
                                }
                            }
                            if (!socialListExist) {
                                socialsList.push(this.state.socialsList[socialListIndex]);
                            }
                        }
                        this.setState({
                            socialId: response.socials.length ? response.socials[0]['id'] : 0,
                            socials: response.socials,
                            socialsList: socialsList,
                        });
                    } else {
                        this.setState({
                            socialId: response.socials.length ? response.socials[0]['id'] : 0,
                            socials: response.socials,
                            socialsList: response.socials,
                        });
                    }
                } else {
                    this.setState({
                        socials: []
                    });
                }
            },
            (error) => {
                this.setState({
                    socials: []
                });
            }
        )
    }

    loadUrlTypes() {
        getApi('/urls/types').then(
            (response) => {
                if (response.status === 'success') {
                    this.setState({
                        urlTypes: response.types
                    });
                } else {
                    this.setState({
                        urlTypes: []
                    });
                }
            },
            (error) => {
                this.setState({
                    urlTypes: []
                });
            }
        )
    }

    loadUrl() {
        getApi('/urls/' + this.props.router.params.urlId).then(
            (response) => {
                if (response.status === 'success') {
                    let services = this.state.services;
                    let servicesList = [];
                    for (let service_index = 0; service_index < services.length; service_index++) {
                        let find_service = false;
                        for (let response_service_index = 0; response_service_index < response.url.services.length; response_service_index++) {
                            if (services[service_index]['id'] === response.url.services[response_service_index]['id']) {
                                find_service = true;
                            }
                        }
                        if (!find_service) {
                            servicesList.push(services[service_index]);
                        }
                    }
                    let socials = this.state.socials;
                    let socialsList = [];
                    for (let social_index = 0; social_index < socials.length; social_index++) {
                        let find_social = false;
                        for (let response_social_index = 0; response_social_index < response.url.socials.length; response_social_index++) {
                            if (socials[social_index]['id'] === response.url.socials[response_social_index]['id']) {
                                find_social = true;
                            }
                        }
                        if (!find_social) {
                            socialsList.push(socials[social_index]);
                        }
                    }
                    this.setState({
                        formUrl: {
                            id: response.url.id,
                            redirection: response.url.redirection,
                            redirection_title: response.url.redirection_title,
                            domain_id: response.url.domain_id,
                            site_url: this.getDomainPathname(response.url.site_url).replace(/^\//, ''),
                            type: response.url.type,
                            title: response.url.title,
                            background_type: response.url.background_type,
                            background_color: response.url.background_color,
                            background_gradient: response.url.background_gradient,
                            background: response.url.background,
                            cover_image: response.url.cover_image,
                            button_background: response.url.button_background,
                            button_text: response.url.button_text,
                            status: response.url.status
                        },
                        formServices: response.url.services,
                        servicesList: servicesList,
                        serviceId: servicesList.length ? servicesList[0]['id'] : 0,
                        formSocials: response.url.socials,
                        socialsList: socialsList,
                        socialId: socialsList.length ? socialsList[0]['id'] : 0,
                    });
                } else {
                    this.setState({
                        formUrl: {
                            id: 0,
                            redirection: '',
                            redirection_title: '',
                            domain_id: 0,
                            site_url: '',
                            type: '',
                            title: '',
                            background_type: 'Image',
                            background_color: '',
                            background_gradient: '',
                            background: null,
                            cover_image: null,
                            button_background: '',
                            button_text: '',
                            status: 'ACTIVE'
                        }
                    });
                }
            },
            (error) => {
                this.setState({
                    formUrl: {
                        id: 0,
                        redirection: '',
                        redirection_title: '',
                        domain_id: 0,
                        site_url: '',
                        type: '',
                        title: '',
                        background_type: 'Image',
                        background_color: '',
                        background_gradient: '',
                        background: null,
                        cover_image: null,
                        button_background: '',
                        button_text: '',
                        status: 'ACTIVE'
                    }
                });
            }
        )
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let redirection_value = this.state.formUrl.redirection,
            redirection_title_value = this.state.formUrl.redirection_title,
            site_url_value = this.state.formUrl.site_url,
            type_value = this.state.formUrl.type,
            title_value = this.state.formUrl.title;
        if (name === 'redirection') { redirection_value = value; }
        if (!redirection_value) {
            if (!form_errors) {
                form_errors = { redirection: 'Please enter your redirection' };
            } else {
                form_errors['redirection'] = 'Please enter your redirection';
            }
        }
        if (name === 'redirection_title') { redirection_title_value = value; }
        if (!redirection_title_value) {
            if (!form_errors) {
                form_errors = { redirection_title: 'Please enter your redirection title' };
            } else {
                form_errors['redirection_title'] = 'Please enter your redirection title';
            }
        }
        if (name === 'site_url') { site_url_value = value; }
        if (!site_url_value) {
            if (!form_errors) {
                form_errors = { site_url: 'Please enter your site url' };
            } else {
                form_errors['site_url'] = 'Please enter your site url';
            }
        }
        if (name === 'type') { type_value = value; }
        if (!type_value) {
            if (!form_errors) {
                form_errors = { type: 'Please enter your type' };
            } else {
                form_errors['type'] = 'Please enter your type';
            }
        }
        if (name === 'title') { title_value = value; }
        if (!title_value) {
            if (!form_errors) {
                form_errors = { title: 'Please enter your title' };
            } else {
                form_errors['title'] = 'Please enter your title';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value, files } = event.target;

        if (name === 'domain_id') {
            this.setState(prevState => ({
                ...prevState,
                formUrl: {
                    ...prevState.formUrl,
                    domain_id: parseInt(value),
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'background_file' || name === 'cover_image_file') {
            this.setState(prevState => ({
                ...prevState,
                formUrl: {
                    ...prevState.formUrl,
                    [name]: files[0],
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'site_url') {
            this.setState(prevState => ({
                ...prevState,
                formUrl: {
                    ...prevState.formUrl,
                    site_url: value.replace(/^\//, ''),
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                formUrl: {
                    ...prevState.formUrl,
                    [name]: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    handleChangeCustomField(name, value) {
        this.setState(prevState => ({
            ...prevState,
            formUrl: {
                ...prevState.formUrl,
                [name]: value,
            },
            errors: this.validateFormErrors(name, value),
        }));
    }

    handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append("domain_id", this.state.formUrl.domain_id);
        formData.append("site_url", this.state.formUrl.site_url);
        formData.append("type", this.state.formUrl.type);
        formData.append("title", this.state.formUrl.title);
        formData.append("background_type", this.state.formUrl.background_type);
        formData.append("background_color", this.state.formUrl.background_color);
        formData.append("background_gradient", this.state.formUrl.background_gradient);
        formData.append("button_background", this.state.formUrl.button_background);
        formData.append("button_text", this.state.formUrl.button_text);
        if (this.state.formUrl.background_file) formData.append("background", this.state.formUrl.background_file);
        if (this.state.formUrl.cover_image_file) formData.append("cover_image", this.state.formUrl.cover_image_file);
        if (this.state.formUrl.redirection) formData.append("redirection", this.state.formUrl.redirection);
        if (this.state.formUrl.type === 'SONG') formData.append("services", JSON.stringify(this.state.formServices));
        if (this.state.formUrl.type === 'SOCIAL') formData.append("socials", JSON.stringify(this.state.formSocials));
        formData.append("status", this.state.formUrl.status);

        postApi('/urls/' + (this.state.formUrl.id ? this.state.formUrl.id : ''), formData).then(
            (response) => {
                if (response.status === 'success') {
                    let services = this.state.services;
                    let servicesList = [];
                    for (let service_index = 0; service_index < services.length; service_index++) {
                        let find_service = false;
                        for (let response_service_index = 0; response_service_index < response.url.services.length; response_service_index++) {
                            if (services[service_index]['id'] === response.url.services[response_service_index]['id']) {
                                find_service = true;
                            }
                        }
                        if (!find_service) {
                            servicesList.push(services[service_index]);
                        }
                    }
                    let socials = this.state.socials;
                    let socialsList = [];
                    for (let social_index = 0; social_index < socials.length; social_index++) {
                        let find_social = false;
                        for (let response_social_index = 0; response_social_index < response.url.socials.length; response_social_index++) {
                            if (socials[social_index]['id'] === response.url.socials[response_social_index]['id']) {
                                find_social = true;
                            }
                        }
                        if (!find_social) {
                            socialsList.push(socials[social_index]);
                        }
                    }
                    this.setState({
                        successAlert: response.message,
                        errorAlert: '',
                        formUrl: {
                            id: response.url.id,
                            redirection: response.url.redirection,
                            redirection_title: response.url.redirection_title,
                            domain_id: response.url.domain_id,
                            site_url: this.getDomainPathname(response.url.site_url).replace(/^\//, ''),
                            type: response.url.type,
                            title: response.url.title,
                            background_type: response.url.background_type,
                            background_color: response.url.background_color,
                            background_gradient: response.url.background_gradient,
                            background: response.url.background,
                            cover_image: response.url.cover_image,
                            button_background: response.url.button_background,
                            button_text: response.url.button_text,
                            status: response.url.status
                        },
                        formServices: response.url.services,
                        servicesList: servicesList,
                        serviceId: servicesList.length ? servicesList[0]['id'] : 0,
                        formSocials: response.url.socials,
                        socialsList: socialsList,
                        socialId: socialsList.length ? socialsList[0]['id'] : 0,
                        breadcrumbItems: [
                            { title: "Dashboard", link: "/dashboard" },
                            { title: "Urls", link: "/urls" },
                            { title: "Edit Url", link: "#" },
                        ]
                    });
                    this.props.setBreadcrumbItems("Edit Url", this.state.breadcrumbItems);
                } else {
                    this.setState({
                        successAlert: '',
                        errorAlert: response.message
                    });
                }
            },
            (error) => {
                this.setState({
                    successAlert: '',
                    errorAlert: error
                });
            }
        )
    }

    componentDidMount() {
        this.loadDomains();
        this.loadServices();
        this.loadSocials();
        this.loadUrlTypes();

        if (this.props.router.params.urlId) {
            this.loadUrl();
            this.props.setBreadcrumbItems("Edit Url", this.state.breadcrumbItems);
        } else {
            this.props.setBreadcrumbItems("Create Url", this.state.breadcrumbItems);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                url: this.props.url
            });
        }
    }

    render() {
        const { formUrl, formServices, formSocials, urlTypes, domains, servicesList, socialsList, successAlert, errorAlert } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                { successAlert && <Alert color="success">{ successAlert }</Alert> }

                                { errorAlert && <Alert color="danger">{ errorAlert }</Alert> }

                                <form className="form-horizontal" onSubmit={ this.handleSubmit }>
                                    <FormGroup>
                                        <Label>URL</Label>
                                        <InputGroup>
                                            <span className="input-group-text">
                                                <SelectInput
                                                    name="domain_id"
                                                    iconPosition="left"
                                                    options={ domains }
                                                    value={ formUrl.domain_id }
                                                    optionId="id"
                                                    optionText="link"
                                                    onChange={ this.handleChangeCustomField }
                                                />
                                            </span>
                                            <input
                                                type="text"
                                                name="site_url"
                                                className={ `form-control ${ this.state.errors && 'site_url' in this.state.errors ? 'error' : '' }` }
                                                placeholder="Enter Site URL"
                                                value={ formUrl.site_url }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "site_url" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.site_url }</span>
                                            }
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Type</Label>
                                        <SelectInput
                                            name="type"
                                            options={ urlTypes }
                                            value={ formUrl.type }
                                            onChange={ this.handleChangeCustomField }
                                        />
                                    </FormGroup>
                                    {
                                        formUrl.type === 'REDIRECT' &&
                                        <FormGroup>
                                            <Label>Redirection URL</Label>
                                            <input
                                                type="text"
                                                name="redirection"
                                                className={ `form-control mb-1 ${this.state.errors && 'redirection' in this.state.errors ? 'error' : ''}` }
                                                placeholder="Enter Redirection URL"
                                                value={ formUrl.redirection }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "redirection" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.redirection }</span>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formUrl.type === 'REDIRECT' && formUrl.redirection_title &&
                                        <FormGroup>
                                            <Label>Redirection Title</Label>
                                            <input
                                                type="text"
                                                name="redirection_title"
                                                className={ `form-control mb-1 ${this.state.errors && 'redirection_title' in this.state.errors ? 'error' : ''}` }
                                                placeholder="Enter Redirection Title"
                                                value={ formUrl.redirection_title }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "redirection_title" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.redirection_title }</span>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formUrl.type === 'SONG' && servicesList && servicesList.length &&
                                        <FormGroup className="row">
                                            <div className="col-12 col-md-10">
                                                <SelectInput
                                                    name="service_id"
                                                    options={ servicesList }
                                                    optionId="id"
                                                    optionText="name"
                                                    value={ formUrl.service_id }
                                                    onChange={ this.handleChangeCustomField }
                                                />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary font-size-14 waves-effect"
                                                    onClick={ this.onAddService }
                                                >
                                                    <i className="mdi mdi-plus"></i> Add Service Url
                                                </button>
                                            </div>
                                        </FormGroup>
                                    }
                                    {
                                        formUrl.type === 'SONG' && formServices &&
                                        formServices.map((formService, i) =>
                                            <FormGroup key={i} className="row">
                                                <Label className="col-12 col-md-2">{ formService.name }:</Label>
                                                <div className="col-12 col-md-9">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="link"
                                                        value={ formService.link }
                                                        onChange={ (e) => this.onChangeService(i, e) }
                                                    />
                                                </div>
                                                <div className="col-12 col-md-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger font-size-14 waves-effect"
                                                        onClick={ (e) => this.onRemoveService(i) }
                                                    >
                                                        <i className="mdi mdi-close"></i>
                                                    </button>
                                                </div>
                                            </FormGroup>
                                        )
                                    }
                                    {
                                        formUrl.type === 'SOCIAL' && socialsList && !!socialsList.length &&
                                        <FormGroup className="row">
                                            <div className="col-12 col-md-10">
                                                <SelectInput
                                                    name="social_id"
                                                    options={ socialsList }
                                                    optionId="id"
                                                    optionText="wide_icon"
                                                    optionAltText="name"
                                                    optionType="image"
                                                    value={ formUrl.social_id }
                                                    onChange={ this.handleChangeCustomField }
                                                />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary font-size-14 waves-effect"
                                                    onClick={ this.onAddSocial }
                                                >
                                                    <i className="mdi mdi-plus"></i> Add Social Url
                                                </button>
                                            </div>
                                        </FormGroup>
                                    }
                                    {
                                        formUrl.type === 'SOCIAL' && formSocials &&
                                        formSocials.map((formSocial, i) =>
                                            <FormGroup key={i} className="row">
                                                <Label className="col-12 col-md-2">{ formSocial.name }:</Label>
                                                <div className="col-12 col-md-9">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="display_text"
                                                                    value={ formSocial.display_text }
                                                                    onChange={ (e) => this.onChangeSocial(i, e) }
                                                                />
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="text"
                                                                    value={ formSocial.text }
                                                                    onChange={ (e) => this.onChangeSocial(i, e) }
                                                                />
                                                            </InputGroup>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup>
                                                                <span className="input-group-text">Display Link</span>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="display_link"
                                                                    value={ formSocial.display_link }
                                                                    onChange={ (e) => this.onChangeSocial(i, e) }
                                                                />
                                                            </InputGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger font-size-14 waves-effect"
                                                        onClick={ (e) => this.onRemoveSocial(i) }
                                                    >
                                                        <i className="mdi mdi-close"></i>
                                                    </button>
                                                </div>
                                            </FormGroup>
                                        )
                                    }
                                    {
                                        (formUrl.type === 'SONG' || formUrl.type === 'SOCIAL' || formUrl.type === 'ALBUM') &&
                                        <>
                                            <FormGroup>
                                                <Label>Page Title</Label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className={`form-control mb-1 ${this.state.errors && 'title' in this.state.errors ? 'error' : ''}`}
                                                    placeholder="Enter Title"
                                                    value={ formUrl.title }
                                                    onChange={ this.handleChangeField } />
                                                {
                                                    this.state.errors && "title" in this.state.errors &&
                                                    <span className="error-message text-danger small">{this.state.errors.title}</span>
                                                }
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Background Type</Label>
                                                <SelectInput
                                                    name="background_type"
                                                    options={['Image', 'Color', 'Gradient']}
                                                    value={ formUrl.background_type }
                                                    onChange={ this.handleChangeCustomField }
                                                />
                                            </FormGroup>
                                            <Tabs defaultActiveKey="background" className="mb-3">
                                                {
                                                    formUrl.type !== 'SOCIAL' &&
                                                    <Tab eventKey="cover-image" title="Cover Image">
                                                        <FormGroup>
                                                            <input
                                                                type="file"
                                                                name="cover_image_file"
                                                                className={`form-control mb-1 ${this.state.errors && 'cover_image' in this.state.errors ? 'error' : ''}`}
                                                                placeholder="Choose Cover Image"
                                                                onChange={this.handleChangeField}/>
                                                            {
                                                                this.state.errors && "cover_image" in this.state.errors &&
                                                                <span
                                                                    className="error-message text-danger small">{this.state.errors.cover_image}</span>
                                                            }
                                                            {
                                                                formUrl.cover_image &&
                                                                <img src={`/uploads/${formUrl.cover_image}`} alt={formUrl.title} className="img-fluid mwp-200"/>
                                                            }
                                                        </FormGroup>
                                                    </Tab>
                                                }
                                                <Tab eventKey="background" title="Background">
                                                    <FormGroup>
                                                        {
                                                            formUrl.background_type === 'Image' && (
                                                                <>
                                                                    <input
                                                                        type="file"
                                                                        name="background_file"
                                                                        className={`form-control mb-1 ${this.state.errors && 'background' in this.state.errors ? 'error' : ''}`}
                                                                        placeholder="Choose Background"
                                                                        onChange={ this.handleChangeField } />
                                                                    {
                                                                        this.state.errors && "background" in this.state.errors &&
                                                                        <span className="error-message text-danger small">{this.state.errors.background}</span>
                                                                    }
                                                                    {
                                                                        formUrl.background &&
                                                                        <img src={`/uploads/${formUrl.background}`} alt={formUrl.title} className="img-fluid mwp-200"/>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            formUrl.background_type === 'Color' && (
                                                                <>
                                                                    <ColorPicker
                                                                        color={ formUrl.background_color }
                                                                        onColorChange={ (color) => { this.handleChangeCustomField('background_color', color) } }
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            formUrl.background_type === 'Gradient' && (
                                                                <>
                                                                    <GradientColor
                                                                        color={ formUrl.background_gradient }
                                                                        onColorChange={ (color) => { this.handleChangeCustomField('background_gradient', color) } }
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    </FormGroup>
                                                </Tab>
                                                <Tab eventKey="button-color" title="Button Color">
                                                    <FormGroup>
                                                        <Label>Background Color</Label>
                                                        <ColorTransparentPicker
                                                            color={ formUrl.button_background }
                                                            onColorChange={ (color) => { this.handleChangeCustomField('button_background', color) } }
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Text Color</Label>
                                                        <ColorTransparentPicker
                                                            color={ formUrl.button_text }
                                                            onColorChange={ (color) => { this.handleChangeCustomField('button_text', color) } }
                                                        />
                                                    </FormGroup>
                                                </Tab>
                                            </Tabs>
                                        </>
                                    }
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <SelectInput
                                            name="status"
                                            options={ ['ACTIVE', 'DISABLE'] }
                                            value={ formUrl.status }
                                            onChange={ this.handleChangeCustomField }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color="primary" className="w-md waves-effect waves-light mr-1" type="submit">{formUrl.id ? 'Update' : 'Create'}</Button>
                                        <button className="btn p-0" type="button"><a className="btn btn-danger w-md waves-effect waves-light" href={`/urls`}>Cancel</a></button>
                                    </FormGroup>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    postApi
})(UrlEdit));