import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, deleteApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class AllUrls extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "All Urls", link : "#" },
            ],
            tableData: {
                columns: [
                    { label: 'Type', field: 'type', sort: 'asc', width: 450 },
                    { label: 'Redirection', field: 'redirection', sort: 'asc', width: 450 },
                    { label: 'Date', field: 'last_view', sort: 'asc', width: 200 },
                    { label: 'IP', field: 'last_ip', sort: 'asc', width: 200 },
                    { label: 'Clicks', field: 'clicks', sort: 'asc', width: 100 },
                    { label: 'Actions', field: 'actions', sort: 'disabled', width: 100 }
                ],
                rows: []
            },
            user: this.props.user
        }

        this.handleDeleteUrl = this.handleDeleteUrl.bind(this);
    }

    handleDeleteUrl(urlId) {
        deleteApi('/urls/' + urlId).then(
            (response) => {
                this.loadUrls();
            },
            (error) => {
            }
        )
    }

    loadUrls() {
        getApi('/urls/all').then(
            (response) => {
                let urls = response.urls;
                for (let url_index = 0; url_index < urls.length; url_index++) {
                    let redirection_url = urls[url_index]['redirection'];
                    if (redirection_url && redirection_url.length > 55) {
                        redirection_url = redirection_url.substr(0, 55) + '[...]';
                    }
                    urls[url_index]['last_view'] = urls[url_index]['date'] ? (dateFormat(urls[url_index]['date'], "mmm dd, yyyy") + ' ' + (urls[url_index]['time'] ? urls[url_index]['time'] : '00:00:00')) : '';
                    urls[url_index]['redirection'] = <div>
                        <Link target="_blank" to={{ pathname: urls[url_index]['redirection'] }}>{ urls[url_index]['redirection_title'] }</Link><br/>
                        <small><Link target="_blank" to={{ pathname: urls[url_index]['redirection'] }}>{ redirection_url }</Link></small>
                    </div>
                    urls[url_index]['actions'] = <div>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/urls/${urls[url_index]['id']}`}>Edit</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/geo/language/${urls[url_index]['id']}`}><i className="mdi mdi-web"></i>&nbsp;Language</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/geo/location/${urls[url_index]['id']}`}><i className="mdi mdi-map-marker"></i>&nbsp;Location</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/browser-os/${urls[url_index]['id']}`}><i className="fas fa-broom"></i>&nbsp;Browser OS</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/mobile-device/${urls[url_index]['id']}`}><i className="fas fa-mobile"></i>&nbsp;Mobile Device</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/acquisition/${urls[url_index]['id']}`}><i className="fas fa-window-maximize"></i>&nbsp;Acquisition</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/top-ips/${urls[url_index]['id']}`}><i className="fas fa-network-wired"></i>&nbsp;Top IPs</Link></button>
                        <button className="btn me-1 p-0" rounded=""><Link className="btn btn-info waves-effect waves-light" to={`/visitors-per-day/${urls[url_index]['id']}`}><i className="fas fa-chart-area"></i>&nbsp;Visitors Per Day</Link></button>
                        <button className="btn btn-danger waves-effect waves-light" rounded="" onClick={(e) => window.confirm("Are you sure you wish to delete this user?") && this.handleDeleteUrl(urls[url_index]['id']) }>Delete</button>
                    </div>
                }

                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: urls
                    }
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/urls');
            return;
        }
        this.props.setBreadcrumbItems("All Urls", this.state.breadcrumbItems);

        this.loadUrls();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { tableData } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div className="mb-2 text-right">
                                    <button className="btn me-1 p-0" rounded=""><a className="btn btn-primary waves-effect waves-light" href={`/urls/create`}>Create New</a></button>
                                </div>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    deleteApi
})(AllUrls));