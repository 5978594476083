import React, { Component } from 'react';
import { Container } from "reactstrap";
import { connect } from 'react-redux';

import { withRouter } from '../../../helpers/funcUtils';

//Import Components
import TopBar from "./TopBar";
import Breadcrumb from "../../Common/breadcrumb";
import Navbar from "./Navbar";
import Footer from "./Footer";

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpened: false
        };
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if(this.props.isPreloader === true) {
                document.getElementById('preloader').style.display = "block";
                document.getElementById('status').style.display = "block";
    
                setTimeout(function(){
                    document.getElementById('preloader').style.display = "none";
                    document.getElementById('status').style.display = "none";
                }, 2500);
            } else {
                document.getElementById('preloader').style.display = "none";
                document.getElementById('status').style.display = "none";
            }
        }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        let currentPage = this.capitalizeFirstLetter(this.props.router.location.pathname);
    
        document.title = currentPage + " | Tilk - Tiny Links";
    }

    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    openMenu = e => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
    };

    render() {
        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                <div id="layout-wrapper">
                    <div className="layout-bg-image"></div>
                    <div className="layout-bg-color"></div>

                    <header id="page-top-bar">
                        <TopBar isMenuOpened={this.state.isMenuOpened} openLeftMenuCallBack={this.openMenu.bind(this)}/>
                        <div className="top-navigation">
                            <div className="page-title-content">
                                <Container fluid>
                                    <Breadcrumb />
                                </Container>
                            </div>
                            <Navbar menuOpen={this.state.isMenuOpened} />
                        </div>
                    </header>
                    <div className="main-content">
                        <div className="page-content">
                            <Container fluid>
                                {this.props.children}
                            </Container>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { isPreloader } = state.Layout;
    return { isPreloader };
};
  
export default withRouter(connect(mapStateToProps, {})(Layout));