import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import dateFormat from 'dateformat';
import 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { MDBDataTable } from 'mdbreact';
import {
    GroupingState,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow
} from '@devexpress/dx-react-grid-material-ui';

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class VisitorExplorer extends Component {
    constructor(props) {
        super(props);

        let locationQuery = queryString.parse(this.props.router.location.search);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Visitor Explorer", link : "#" },
            ],
            client_id: locationQuery.client_id ? locationQuery.client_id : '',
            title: 'Visitor Explorer',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            lineChartData: {
                data: { labels: [], datasets: [] },
                options: { responsive: true }
            },
            tableData: {
                columns: [
                    { label: 'Client Id', field: 'client_id', width: 150 },
                    { label: 'Page Views', field: 'count', sort: 'desc', width: 150 },
                ],
                rows: []
            },
            gridTableData: {
                columns: [
                    { name: 'date', title: 'Date' },
                    { name: 'time', title: 'Time' },
                    { name: 'site_url', title: 'Site Url' },
                ],
                rows: []
            },
            visitor: {},
            user: this.props.user
        }

        this.changeDayRange = this.changeDayRange.bind(this);

        this.loadVisitorExplorer = this.loadVisitorExplorer.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadVisitorExplorer();
        }, 500);
    }

    loadVisitorExplorer = () => {
        getApi('/analytic/visitor-explorer/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') +
            '?range=' + this.state.range +
            (this.state.client_id ? '&client_id=' + this.state.client_id : '')).then(
            (response) => {
                let visitorExplorer = response.visitor_explorer;
                let table_data = visitorExplorer.data;
                let grid_table_data = visitorExplorer.grid_data;
                if (this.state.client_id) {
                    for (let table_data_index = 0; table_data_index < grid_table_data.length; table_data_index++) {
                        grid_table_data[table_data_index]['date'] = dateFormat(grid_table_data[table_data_index]['date'], "mmm dd, yyyy");
                        grid_table_data[table_data_index]['site_url'] = <Link to={{ pathname: grid_table_data[table_data_index]['site_url'] }} target="_blank">{grid_table_data[table_data_index]['site_url']}</Link>;
                    }
                } else {
                    for (let table_data_index = 0; table_data_index < table_data.length; table_data_index++) {
                        if (table_data[table_data_index]['client_id']) {
                            table_data[table_data_index]['client_id'] = <Link to={`/visitor-explorer/${(this.props.router.params.urlId ? this.props.router.params.urlId + '/': '')}?client_id=${table_data[table_data_index]['client_id']}`}>{table_data[table_data_index]['client_id']}</Link>
                        }
                    }
                }
                this.setState({
                    lineChartData: {
                        ...this.state.lineChartData,
                        data: {
                            labels: visitorExplorer.line_data.labels,
                            datasets: [{ label: 'Page Views', data: visitorExplorer.line_data.data, borderColor: '#1967d2' }]
                        }
                    },
                    tableData: {
                        ...this.state.tableData,
                        rows: table_data
                    },
                    gridTableData: {
                        ...this.state.gridTableData,
                        rows: grid_table_data
                    },
                    visitor: visitorExplorer.visitor
                })
            },
            (error) => {
                this.setState({
                    lineChartData: {
                        ...this.state.lineChartData,
                        data: {
                            labels: '',
                            datasets: []
                        }
                    },
                    tableData: {
                        ...this.state.tableData,
                        rows: []
                    },
                    gridTableData: {
                        ...this.state.gridTableData,
                        rows: []
                    },
                    visitor: {}
                });
            }
        );
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems('Visitor Explorer', this.state.breadcrumbItems);

        this.loadVisitorExplorer();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });

            if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
                window.location.reload();
            }
        }
    }

    render() {
        const { user, client_id, title, range, dayRanges, tableData, lineChartData, gridTableData, visitor } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {
                            client_id &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Visitor</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs="12" md="4">
                                            <p><strong>Client ID:</strong> <span>{visitor.client_id}</span></p>
                                            {user.role === 'ADMIN' &&
                                            <p><strong>IP:</strong> <span>{visitor.ip}</span></p>
                                            }
                                            <p><strong>Connect:</strong> <span>{visitor.ua_from}</span></p>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <p><strong>Country:</strong> <span>{visitor.country}</span></p>
                                            <p><strong>State:</strong> <span>{visitor.state}</span></p>
                                            <p><strong>City:</strong> <span>{visitor.city}</span></p>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <p><strong>Browser:</strong> <span>{visitor.ua_browser_family} {visitor.ua_browser_version}</span></p>
                                            <p><strong>Device:</strong> <span>{visitor.ua_device_brand} {visitor.ua_device_family}</span></p>
                                            <p><strong>Operating System:</strong> <span>{visitor.ua_os_family} {visitor.ua_os_version}</span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs="12">
                                        <div className="mb-3">
                                            <h5>{title}</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="12" md="3">
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={dayRange['value']}>{dayRange['label']}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="6">
                                        <Line data={lineChartData.data} options={lineChartData.options}/>
                                    </Col>
                                    {
                                        !client_id &&
                                        <Col xs="12" md="6">
                                            <MDBDataTable
                                                responsive
                                                data={ tableData }
                                            />
                                        </Col>
                                    }
                                    {
                                        client_id &&
                                        <Col xs="12" md="6">
                                            <Grid
                                                rows={gridTableData.rows}
                                                columns={gridTableData.columns}
                                            >
                                                <GroupingState
                                                    grouping={[{ columnName: 'date' }]}
                                                />
                                                <IntegratedGrouping />
                                                <PagingState
                                                    defaultCurrentPage={0}
                                                    pageSize={5}
                                                />
                                                <IntegratedPaging />
                                                <Table />
                                                <TableHeaderRow />
                                                <PagingPanel />
                                                <TableGroupRow />
                                            </Grid>
                                        </Col>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(VisitorExplorer));