import {FORGET_USER, FORGET_USER_SUCCESS, FORGET_API_FAILED} from './actionTypes';

export const forgetUser = (username, history) => {
    return {
        type: FORGET_USER,
        payload: { username, history }
    }
}

export const forgetUserSuccessful = (message) => {
    return {
        type: FORGET_USER_SUCCESS,
        payload: message
    }
}

export const apiForgetError = (error) => {
    return {
        type: FORGET_API_FAILED,
        payload: error
    }
}