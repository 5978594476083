import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from "react-redux";

import VerticalLayout from "./component/Layout/VerticalLayout";
import HorizontalLayout from "./component/Layout/HorizontalLayout";
import LayoutF from "./component/FrontendLayout";
import LayoutU from "./component/UrlViewLayout";

// Import scss
import "./App.css";
import "./assets/scss/theme.scss";

import AppRoute from "./AppRoute";

import Logout from "./pages/Authentication/Logout";
import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Register";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import PageLockScreen from "./pages/Authentication/PageLockScreen";
import Page404 from "./pages/Frontend/Page404";
import Page500 from "./pages/Frontend/Page500";

import Dashboard from "./pages/Dashboard/Dashboard";
import Overview from "./pages/Dashboard/Overview";
import Realtime from "./pages/Dashboard/Realtime";
import Audience from "./pages/Dashboard/Audience";
import VisitorExplorer from "./pages/Dashboard/VisitorExplorer";
import Location from "./pages/Dashboard/Location";
import Language from "./pages/Dashboard/Language";
import NewVsReturn from "./pages/Dashboard/NewVsReturn";
import BrowserOS from "./pages/Dashboard/BrowserOS";
import MobileDevice from "./pages/Dashboard/MobileDevice";
import Acquisition from "./pages/Dashboard/Acquisition";
import TopIPs from "./pages/Dashboard/TopIPs";
import VisitorsPerDay from "./pages/Dashboard/VisitorsPerDay";
import UrlEdit from "./pages/Dashboard/UrlEdit";
import UrlStatistics from "./pages/Dashboard/UrlStatistics";
import Urls from "./pages/Dashboard/Urls";
import AllUrls from "./pages/Dashboard/AllUrls";
import Visitors from "./pages/Dashboard/Visitors";
import DomainEdit from "./pages/Dashboard/DomainEdit";
import DomainUrls from "./pages/Dashboard/DomainUrls";
import Domains from "./pages/Dashboard/Domains";
import SocialEdit from "./pages/Dashboard/SocialEdit";
import Socials from "./pages/Dashboard/Socials";
import ServiceEdit from "./pages/Dashboard/ServiceEdit";
import Services from "./pages/Dashboard/Services";
import UserUrls from "./pages/Dashboard/UserUrls";
import UserEdit from "./pages/Dashboard/UserEdit";
import Users from "./pages/Dashboard/Users";

import Home from "./pages/Frontend/Home";
import ShortUrlView from "./pages/Frontend/ShortUrlView";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <React.Fragment>
                <Routes>
                    <Route path="/logout" element={ <AppRoute component={Logout} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/login" element={ <AppRoute component={Login} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/register" element={ <AppRoute component={Register} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/forget-password" element={ <AppRoute component={ForgetPassword} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/page-lock-screen" element={ <AppRoute component={PageLockScreen} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/page-404" element={ <AppRoute component={Page404} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/page-500" element={ <AppRoute component={Page500} layout={LayoutF} isAuthProtected={false} /> } />

                    <Route path="/dashboard" element={ <AppRoute component={Dashboard} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/overview" element={ <AppRoute component={Overview} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/realtime" element={ <AppRoute component={Realtime} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/audience" element={ <AppRoute component={Audience} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/visitor-explorer" element={ <AppRoute component={VisitorExplorer} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/geo/location/:urlId" element={ <AppRoute component={Location} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/geo/location" element={ <AppRoute component={Location} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/geo/language/:urlId" element={ <AppRoute component={Language} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/geo/language" element={ <AppRoute component={Language} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/new-vs-return" element={ <AppRoute component={NewVsReturn} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/browser-os/:urlId" element={ <AppRoute component={BrowserOS} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/browser-os" element={ <AppRoute component={BrowserOS} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/mobile-device/:urlId" element={ <AppRoute component={MobileDevice} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/mobile-device" element={ <AppRoute component={MobileDevice} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/acquisition/:urlId" element={ <AppRoute component={Acquisition} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/acquisition" element={ <AppRoute component={Acquisition} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/top-ips/:urlId" element={ <AppRoute component={TopIPs} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/top-ips" element={ <AppRoute component={TopIPs} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/visitors-per-day/:urlId" element={ <AppRoute component={VisitorsPerDay} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/visitors-per-day" element={ <AppRoute component={VisitorsPerDay} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/urls/create" element={ <AppRoute component={UrlEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/urls/:urlId/statistics" element={ <AppRoute component={UrlStatistics} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/urls/:urlId" element={ <AppRoute component={UrlEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/urls" element={ <AppRoute component={Urls} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/all-urls" element={ <AppRoute component={AllUrls} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/visitors" element={ <AppRoute component={Visitors} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/domains/create" element={ <AppRoute component={DomainEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/domains/:domainId/urls" element={ <AppRoute component={DomainUrls} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/domains/:domainId" element={ <AppRoute component={DomainEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/domains" element={ <AppRoute component={Domains} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/socials/create" element={ <AppRoute component={SocialEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/socials/:socialId" element={ <AppRoute component={SocialEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/socials" element={ <AppRoute component={Socials} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/services/create" element={ <AppRoute component={ServiceEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/services/:serviceId" element={ <AppRoute component={ServiceEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/services" element={ <AppRoute component={Services} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/users/:userId/urls" element={ <AppRoute component={UserUrls} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/users/:userId" element={ <AppRoute component={UserEdit} layout={Layout} isAuthProtected={true} /> } />
                    <Route path="/users" element={ <AppRoute component={Users} layout={Layout} isAuthProtected={true} /> } />

                    <Route path="/" element={ <AppRoute component={Home} layout={LayoutF} isAuthProtected={false} /> } />
                    <Route path="/*" element={ <AppRoute component={ShortUrlView} layout={LayoutU} isAuthProtected={false} /> } />
                </Routes>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);