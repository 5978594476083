import React, { Component } from 'react';
import iro from '@jaames/iro';

class GradientColor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            draggingStart: false,
            draggingEnd: false,
            startColor: '',
            startPercent: 0,
            endColor: '',
            endPercent: 0,
        }

        this.getColor = this.getColor.bind(this);

        this.mouseLeaveUp = this.mouseLeaveUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    componentDidMount() {
        let color = this.props.hasOwnProperty('color') ? (this.props.color ? this.props.color : '') : '';
        color = color.replace(/^linear-gradient\(/, '').replace(/\)$/, '');
        let color_array = color.split(',');
        let color_start_array = color_array.length ? color_array[0].trim().split(' ') : [];
        let color_end_array = color_array.length > 1 ? color_array[1].trim().split(' ') : [];
        let start_color = color_start_array.length ? (color_start_array[0] ? color_start_array[0] : 'rgb(0, 0, 0)') : 'rgb(0, 0, 0)';
        let end_color = color_end_array.length ? (color_end_array[0] ? color_end_array[0] : 'rgb(0, 0, 0)') : 'rgb(0, 0, 0)';
        this.setState({
            startColor: start_color,
            startPercent: color_start_array.length > 1 ? color_start_array[1].replace(/%$/, '') : 0,
            endColor: end_color,
            endPercent: color_end_array.length > 1 ? color_end_array[1].replace(/%$/, '') : 100,
        });

        this.startColorPicker = new iro.ColorPicker(this.startPicker, { color: start_color });
        this.startColorPicker.on('color:change', (color) => {
            this.setState({ startColor: color.hexString });
            if (this.props.onColorChange) this.props.onColorChange(this.getColor({ startColor: color.hexString }));
        });

        this.endColorPicker = new iro.ColorPicker(this.endPicker, { color: end_color });
        this.endColorPicker.on('color:change', (color) => {
            this.setState({ endColor: color.hexString });
            if (this.props.onColorChange) this.props.onColorChange(this.getColor({ endColor: color.hexString }));
        });

    }

    componentDidUpdate(prevProps, prevState) {
        // linear-gradient(rgba(2, 0, 36, 1) 6%, rgba(0, 212, 255, 1) 83%);
        const {color, ...colorPickerState} = this.props;

        if (this.state.startColor) this.startColorPicker.color.set(this.state.startColor);
        this.startColorPicker.setState(colorPickerState);

        if (this.state.endColor) this.endColorPicker.color.set(this.state.endColor);
        this.endColorPicker.setState(colorPickerState);

        if ((this.state.draggingStart && !prevState.draggingStart) || (this.state.draggingEnd && !prevState.draggingEnd)) {
            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseup', this.onMouseUp);
        }
        if ((!this.state.draggingStart && prevState.draggingStart) && (!this.state.draggingEnd && prevState.draggingEnd)) {
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('mouseup', this.onMouseUp);
        }
    }

    onMouseDown (e) {
        if (e.target === this.startPoint && !this.state.draggingStart) {
            this.setState({ draggingStart: true });
        }
        if (e.target === this.endPoint && !this.state.draggingEnd) {
            this.setState({ draggingEnd: true });
        }
        e.stopPropagation();
        e.preventDefault();
    }

    mouseLeaveUp (e) {
        if (e.target === this.startPoint && this.state.draggingStart) {
            this.setState({ draggingStart: false });
        }
        if (e.target === this.endPoint && this.state.draggingEnd) {
            this.setState({ draggingEnd: false });
        }
        e.stopPropagation();
        e.preventDefault();
    }

    onMouseUp (e) {
        this.mouseLeaveUp(e);
    }

    onMouseLeave (e) {
        let el = e, that = this;
        setTimeout(function() {
            that.mouseLeaveUp(el);
        }, 300);
    }

    onMouseMove (e) {
        if (this.state.draggingStart) {
            let startPercent = (e.clientY - this.barPoints.getBoundingClientRect().top) / this.barPoints.clientHeight * 100;
            if (startPercent > this.state.endPercent) {
                startPercent = this.state.endPercent;
            }
            this.setState({ startPercent: startPercent });
            if (this.props.onColorChange) this.props.onColorChange(this.getColor({ startPercent: startPercent }));
        }
        if (this.state.draggingEnd) {
            let endPercent = (e.clientY - this.barPoints.getBoundingClientRect().top) / this.barPoints.clientHeight * 100;
            if (endPercent < this.state.startPercent) {
                endPercent = this.state.startPercent;
            }
            this.setState({ endPercent: endPercent });
            if (this.props.onColorChange) this.props.onColorChange(this.getColor({ endPercent: endPercent }));
        }
        e.stopPropagation();
        e.preventDefault();
    }

    getColor = (data) => {
        let startColor = this.state.startColor, startPercent = this.state.startPercent, endColor = this.state.endColor, endPercent = this.state.endPercent;
        if (data && data.hasOwnProperty('startColor')) startColor = data.startColor;
        if (data && data.hasOwnProperty('endColor')) endColor = data.endColor;
        return `linear-gradient(${startColor} ${startPercent}%, ${endColor} ${endPercent}%)`;
    }

    render() {
        const { startColor, startPercent, endColor, endPercent } = this.state;

        return (
            <div className="gradient-color">
                <div className="gradient-bar-points" ref={ el => this.barPoints = el }>
                    <div className="gradient-bar" style={{ background: this.getColor() }}></div>
                    <div className="gradient-points">
                        <div className="start-point" ref={ el => this.startPoint = el } onMouseDown={ this.onMouseDown } onMouseLeave={ this.onMouseLeave } style={{ background: startColor, top: `${startPercent}%` }}></div>
                        <div className="end-point" ref={ el => this.endPoint = el } onMouseDown={ this.onMouseDown } onMouseLeave={ this.onMouseLeave } style={{ background: endColor, top: `${endPercent}%` }}></div>
                    </div>
                </div>
                <div className="color-pickers">
                    <div ref={ el => this.startPicker = el } />
                    <div ref={ el => this.endPicker = el } />
                </div>
            </div>
        );
    }
}

export default GradientColor;