import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, postApi, deleteApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class Domains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Domains", link : "#" },
            ],
            tableData: {
                columns: [
                    { label: 'Name', field: 'name', sort: 'asc', width: 150 },
                    { label: 'Link', field: 'link', sort: 'asc', width: 250 },
                    { label: 'Status', field: 'status', sort: 'asc', width: 200 },
                    { label: 'Actions', field: 'actions', sort: 'disabled', width: 100 }
                ],
                rows: []
            },
            user: this.props.user
        }
        this.handleDeleteDomain = this.handleDeleteDomain.bind(this);
        this.handleActiveDomain = this.handleActiveDomain.bind(this);
        this.handleDisableDomain = this.handleDisableDomain.bind(this);
    }

    handleDeleteDomain(domainId) {
        deleteApi('/domains/' + domainId).then(
            (response) => {
                this.loadDomains();
            },
            (error) => {
            }
        )
    }

    handleActiveDomain(domainId) {
        postApi('/domains/active/' + domainId).then(
            (response) => {
                this.loadDomains();
            },
            (error) => {
            }
        )
    }

    handleDisableDomain(domainId) {
        postApi('/domains/disable/' + domainId).then(
            (response) => {
                this.loadDomains();
            },
            (error) => {
            }
        )
    }

    loadDomains() {
        getApi('/domains/').then(
            (response) => {
                let domains = response.domains;
                for (let domain_index = 0; domain_index < domains.length; domain_index++) {
                    domains[domain_index]['link'] = <Link to={{ pathname: domains[domain_index]['link'] }} target="_blank">{ domains[domain_index]['link'] }</Link>
                    if (domains[domain_index]['status'] !== 'ACTIVE') {
                        domains[domain_index]['domain'] = <Link to={{ pathname: domains[domain_index]['domain'] }} target="_blank">{ domains[domain_index]['domain'] }</Link>
                        domains[domain_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><Link target="_blank" className="btn btn-info waves-effect waves-light" to={`/domains/${domains[domain_index]['id']}/urls`}>Urls ({domains[domain_index]['urls']})</Link></button>
                            <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/domains/${domains[domain_index]['id']}`}>Edit</Link></button>
                            <button className="btn btn-success waves-effect waves-light me-1" rounded="" onClick={(e) => this.handleActiveDomain(domains[domain_index]['id'])}>Active</button>
                            <button className="btn btn-danger waves-effect waves-light" rounded=""
                                    onClick={(e) =>
                                        window.confirm("Are you sure you wish to delete this domain?") && this.handleDeleteDomain(domains[domain_index]['id'])
                                    }>Delete</button>
                        </div>
                    } else {
                        domains[domain_index]['domain'] = <Link to={{ pathname: domains[domain_index]['domain'] }} target="_blank">{ domains[domain_index]['domain'] }</Link>
                        domains[domain_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><Link target="_blank" className="btn btn-info waves-effect waves-light" to={`/domains/${domains[domain_index]['id']}/urls`}>Urls ({domains[domain_index]['urls']})</Link></button>
                            <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/domains/${domains[domain_index]['id']}`}>Edit</Link></button>
                            <button className="btn btn-warning waves-effect waves-light me-1" rounded="" onClick={(e) => this.handleDisableDomain(domains[domain_index]['id'])}>Disable</button>
                            <button className="btn btn-danger waves-effect waves-light" rounded=""
                                    onClick={(e) =>
                                        window.confirm("Are you sure you wish to delete this domain?") && this.handleDeleteDomain(domains[domain_index]['id'])
                                    }>Delete</button>
                        </div>
                    }
                }
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: domains
                    }
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && ((this.props.user.role !== 'PLUS' && this.props.user.role !== 'ADMIN') || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Domains", this.state.breadcrumbItems);

        this.loadDomains();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { tableData } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div className="mb-2 text-right">
                                    <button className="btn me-1 p-0" rounded=""><a className="btn btn-primary waves-effect waves-light " href={`/domains/create`}>Create New</a></button>
                                </div>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    postApi,
    deleteApi
})(Domains));