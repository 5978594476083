import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import { MDBDataTable } from 'mdbreact';
import GoogleChart from "react-google-charts";

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class Realtime extends Component {
    constructor(props) {
        super(props);

        let self = this;

        this.state = {
            breadcrumbItems : [
                { title : "Realtime", link : "#" },
            ],
            title: 'Visitors',
            timer: 0,
            timePeriod: 5 * 1000,
            pageViews: {
                minutes: {
                    data: { labels: [], datasets: [] },
                    options: {
                        responsive: true,
                        plugins: { title: { display: true, text: 'Per Minute' }}
                    }
                },
                seconds: {
                    data: { labels: [], datasets: [] },
                    options: {
                        responsive: true,
                        plugins: { title: { display: true, text: 'Per Second' }}
                    }
                },
            },
            tableOptions: {},
            referrals: {
                columns: [
                    { label: 'Source', field: 'referral', sort: 'asc', width: 150 },
                    { label: 'Active Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            socials: {
                columns: [
                    { label: 'Source', field: 'social', sort: 'asc', width: 150 },
                    { label: 'Active Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            locations: {
                data: [],
                options: {}
            },
            fromsBar: {
                data: { labels: [], datasets: [] },
                options: {
                    responsive: true,
                    indexAxis: 'y',
                    scales: {
                        x: {
                            ticks: {
                                callback: function(value, index, values) {
                                    return value + '%';
                                }
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.label + ' ' + context.formattedValue + '%';
                                },
                                title: function(context) {
                                    return self.state.dayLabel;
                                }
                            }
                        }
                    }
                }
            },
            froms: [],
            now: 0,
            user: this.props.user
        }

        this.loadRealtimeData = this.loadRealtimeData.bind(this);
    }

    loadRealtimeData = () => {
        getApi('/analytic/realtime/overview').then(
            (response) => {
                let realtime = response.realtime;
                let locations = realtime.locations;
                let geo_chart_options = {};
                let geo_locations = [];
                let froms_bar_data = { labels: [], datasets: [] };
                let froms_bar_dataset = [];
                let froms_total_value = 0;
                let from_data_index;
                let froms_data = [];
                for (from_data_index = 0; from_data_index < realtime.froms.length; from_data_index++) {
                    froms_total_value += parseInt(realtime.froms[from_data_index]['count']);
                }
                for (from_data_index = 0; from_data_index < realtime.froms.length; from_data_index++) {
                    froms_bar_data.labels.push(realtime.froms[from_data_index]['label']);
                    froms_bar_dataset.push(parseFloat(realtime.froms[from_data_index]['count'] / froms_total_value * 100));
                    froms_data.push({ label: realtime.froms[from_data_index]['label'], percent: realtime.froms[from_data_index]['count'] / froms_total_value * 100});
                }
                froms_bar_data.datasets.push({ label: 'Active Visitors', data: froms_bar_dataset, backgroundColor: "#28bbe3", borderColor: "#28bbe3"});
                if (locations.data.length) {
                    if (locations.key === 'state') {
                        geo_chart_options = {
                            region: locations.region,
                            resolution: 'provinces'
                        }
                    } else if (locations.key === 'city') {
                        geo_chart_options = {
                            region: locations.region,
                            displayMode: "markers",
                            resolution: 'provinces'
                        }
                    }
                }
                geo_locations.push(['Location', 'Visitors']);
                for (let location_index = 0; location_index < locations.data.length; location_index++) {
                    geo_locations.push([locations.data[location_index][locations.key], locations.data[location_index]['count']]);
                }

                this.setState({
                    pageViews: {
                        ...this.state.pageViews,
                        minutes : {
                            ...this.state.pageViews.minutes,
                            data: {
                                labels: realtime.pageViews.minutes.labels,
                                datasets: [{ label: 'Page Views', data: realtime.pageViews.minutes.data, borderColor: '#1967d2' }]
                            },
                        },
                        seconds : {
                            ...this.state.pageViews.seconds,
                            data: {
                                labels: realtime.pageViews.seconds.labels,
                                datasets: [{ label: 'Page Views', data: realtime.pageViews.seconds.data, borderColor: '#1967d2' }]
                            },
                        }
                    },
                    referrals : {
                        ...this.state.referrals,
                        rows: realtime.referrals,
                    },
                    socials : {
                        ...this.state.socials,
                        rows: realtime.socials,
                    },
                    locations: {
                        data: geo_locations,
                        options: geo_chart_options
                    },
                    fromsBar : {
                        ...this.state.froms,
                        data: froms_bar_data,
                    },
                    froms: froms_data,
                    now: realtime.now
                })
            },
            (error) => {
                this.setState({
                    pageViews: {
                        ...this.state.pageViews,
                        minutes : {
                            ...this.state.pageViews.minutes,
                            data: {
                                labels: [],
                                datasets: [{ label: 'Per Minute', data: [], borderColor: '#1967d2' }]
                            },
                        },
                        seconds : {
                            ...this.state.pageViews.seconds,
                            data: {
                                labels: [],
                                datasets: [{ label: 'Per Second', data: [], borderColor: '#1967d2' }]
                            },
                        },
                    },
                    referrals : {
                        ...this.state.referrals,
                        rows: [],
                    },
                    socials : {
                        ...this.state.socials,
                        rows: [],
                    },
                    locations: {
                        data: [],
                        options: {}
                    },
                    fromsBar : {
                        ...this.state.froms,
                        data: {
                            labels: [],
                            datasets: [{ data: [], borderColor: '#1967d2' }]
                        },
                    },
                    froms: [],
                    now: 0
                });
            }
        );
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Realtime", this.state.breadcrumbItems);

        this.loadRealtimeData();
        if (this.state.timer === 0) {
            this.setState({
                timer: setInterval(this.loadRealtimeData, this.state.timePeriod)
            });
        }
    }

    componentWillUnmount() {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
    }

    render() {
        const { title, pageViews, referrals, socials, locations, froms, now } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col xs="12" md="4">
                                        <div className="mb-3">
                                            <h5>{title}</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="12" md="4">
                                        <div className="mb-3 text-center">
                                            <h3>Right now</h3>
                                            <h1>{now}</h1>
                                            <p>active visitors on site</p>
                                        </div>
                                        {froms.map((from, i) =>
                                            <div key={i}>
                                                <Label>{from['label']}</Label>
                                                <div style={{ position: 'relative' }}>
                                                    <div style={{ width: `${from['percent']}%`, height: '20px', background: 'green' }}></div>
                                                    <div style={{ position: 'absolute', top: '0', left: `calc(${from['percent'] / 2}% - 15px)`, color: 'white' }}>{from['percent']}%</div>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs="12" md="8">
                                        <Row>
                                            <Col xs="12" md="8">
                                                <Bar data={pageViews.minutes.data} options={pageViews.minutes.options} style={{ maxHeight: '275px'}}/>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <Bar data={pageViews.seconds.data} options={pageViews.seconds.options} height="250"/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="4">
                                        <Card>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <h5>Top Refererals</h5>
                                                </div>
                                                <MDBDataTable
                                                    responsive
                                                    bordered
                                                    btn
                                                    paging={false}
                                                    searching={false}
                                                    data={referrals}
                                                />
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <h5>Top Socials</h5>
                                                </div>
                                                <MDBDataTable
                                                    responsive
                                                    bordered
                                                    btn
                                                    paging={false}
                                                    searching={false}
                                                    data={socials}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="8">
                                        <Card>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <h5>Top Locations</h5>
                                                </div>
                                                <GoogleChart
                                                    chartType="GeoChart" width="100%" height="400px"
                                                    data={locations.data}
                                                    options={locations.options}
                                                    mapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Realtime));