import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, deleteApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Users", link : "#" },
            ],
            tableData: {
                columns: [
                    { label: 'Name', field: 'name', sort: 'asc', width: 150 },
                    { label: 'Email', field: 'email', sort: 'asc', width: 250 },
                    { label: 'Role', field: 'role', sort: 'asc', width: 200 },
                    { label: 'Status', field: 'status', sort: 'asc', width: 100 },
                    { label: 'Actions', field: 'actions', sort: 'disabled', width: 100 }
                ],
                rows: []
            },
            user: this.props.user
        }
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
    }

    handleDeleteUser(userId) {
        deleteApi('/users/' + userId).then(
            (response) => {
                this.loadUsers();
            },
            (error) => {
            }
        )
    }

    loadUsers() {
        getApi('/users').then(
            (response) => {
                let users = response.users;
                for (let user_index = 0; user_index < users.length; user_index++) {
                    if (users[user_index]['id'] === this.state.user.id) {
                        users[user_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><Link target="_blank" to={`/users/${users[user_index]['id']}/urls`} className="btn btn-info waves-effect waves-light">Urls ({users[user_index]['urls']})</Link></button>
                            <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/users/${users[user_index]['id']}`}>Edit</Link></button>
                        </div>
                    } else {
                        users[user_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><Link target="_blank" to={`/users/${users[user_index]['id']}/urls`} className="btn btn-info waves-effect waves-light">Urls ({users[user_index]['urls']})</Link></button>
                            <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/users/${users[user_index]['id']}`}>Edit</Link></button>
                            <button className="btn btn-danger waves-effect waves-light" rounded=""
                                    onClick={(e) =>
                                        window.confirm("Are you sure you wish to delete this user?") && this.handleDeleteUser(users[user_index]['id'])
                                    }>Delete</button>
                        </div>
                    }
                }
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: users
                    }
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Users", this.state.breadcrumbItems);
        this.loadUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { tableData } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    deleteApi
})(Users));