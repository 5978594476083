import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { toggleSidebar, changeSidebarType } from '../../../store/actions';
import { withRouter } from '../../../helpers/funcUtils';

//Import Menus
import FullScreen from "../Menus/full-screen";
import ProfileMenu from "../Menus/profile-menu";

//Import Images
import logo_v1 from "../../../assets/images/logo-v1.svg";
// import logo_sm from "../../../assets/images/logo-sm.png";
// import logo_dark from "../../../assets/images/logo-dark.png";
// import logo_light from "../../../assets/images/logo-light.png";

class TopBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        };
    }
    
    sidebarToggle = () =>{
        if (this.props.leftSideBarType === "default") {
            this.props.changeSidebarType("condensed", this.state.isMobile);
        } else if (this.props.leftSideBarType === "condensed") {
            this.props.changeSidebarType("default", this.state.isMobile);
        }
     }

    render() {
        return (
            <React.Fragment>
                <header id="page-top-bar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/dashboard" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={ logo_v1 } alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={ logo_v1 } alt="" height="30" />
                                    </span>
                                </Link>

                                <Link to="/dashboard" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={ logo_v1 } alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={ logo_v1 } alt="" height="30" />
                                    </span>
                                </Link>
                            </div>

                            <button type="button" onClick={ this.sidebarToggle } className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                                <i className="mdi mdi-menu"></i>
                            </button>
                        </div>

                        <div className="d-flex">
                            <FullScreen/>
                    
                            <ProfileMenu/>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { is_toggle, leftSideBarType } = state.Layout;
    return { is_toggle, leftSideBarType };
}

export default withRouter(connect(mapStateToProps, { toggleSidebar, changeSidebarType })(TopBar));