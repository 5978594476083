import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert
} from "reactstrap";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, postApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class SocialEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems: this.props.router.params.socialId ? [
                { title: "Dashboard", link: "/dashboard" },
                { title: "Socials", link: "/socials" },
                { title: "Edit Social", link: "#" },
            ]: [
                { title: "Dashboard", link: "/dashboard" },
                { title: "Socials", link: "/socials" },
                { title: "Create Social", link: "#" },
            ],
            iconTypes: [
                { 'value': 'ICON_ONLY', 'label': 'Icon only' },
                { 'value': 'ICON_NAME', 'label': 'Icon with name' },
                { 'value': 'ICON_TEXT', 'label': 'Icon with text' },
            ],
            formSocial: {
                id: 0,
                name: '',
                link: '',
                input_text: '',
                display_text: '',
                display_link: '',
                icon_type: 'ICON_ONLY',
                icon: null,
                icon_with_name: null,
                icon_with_text: null,
                icon_text: '',
                wide_icon: null,
                status: 'ACTIVE'
            },
            errors: null,
            user: this.props.user,
            successAlert: '',
            errorAlert: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        if (this.state.formSocial.icon) formData.append("icon", this.state.formSocial.icon);
        if (this.state.formSocial.icon_with_name) formData.append("icon_with_name", this.state.formSocial.icon_with_name);
        if (this.state.formSocial.icon_with_text) formData.append("icon_with_text", this.state.formSocial.icon_with_text);
        formData.append("name", this.state.formSocial.name);
        formData.append("link", this.state.formSocial.link);
        formData.append("input_text", this.state.formSocial.input_text);
        formData.append("display_text", this.state.formSocial.display_text);
        formData.append("display_link", this.state.formSocial.display_link);
        formData.append("icon_type", this.state.formSocial.icon_type);
        formData.append("icon_text", this.state.formSocial.icon_text ? this.state.formSocial.icon_text : '');
        if (this.state.formSocial.wide_icon) formData.append("wide_icon", this.state.formSocial.wide_icon);
        formData.append("status", this.state.formSocial.status);

        postApi('/socials/' + (this.state.formSocial.id ? this.state.formSocial.id : ''), formData).then(
            (response) => {
                if (response.status === 'success') {
                    this.setState({
                        successAlert: response.message,
                        errorAlert: '',
                        formSocial: {
                            id: response.social.id,
                            name: response.social.name,
                            link: response.social.link,
                            input_text: response.social.input_text,
                            display_text: response.social.display_text,
                            display_link: response.social.display_link,
                            icon_type: response.social.icon_type,
                            icon: response.social.icon,
                            icon_with_name: response.social.icon_with_name,
                            icon_with_text: response.social.icon_with_text,
                            icon_text: response.social.icon_text,
                            wide_icon: response.social.wide_icon,
                            status: response.social.status
                        },
                        breadcrumbItems: [
                            {title: "Dashboard", link: "/dashboard"},
                            {title: "Socials", link: "/socials"},
                            {title: "Edit Social", link: "#"},
                        ]
                    });
                    this.props.setBreadcrumbItems("Edit Social", this.state.breadcrumbItems);
                } else {
                    this.setState({
                        successAlert: '',
                        errorAlert: response.message
                    });
                }
            },
            (error) => {
                this.setState({
                    successAlert: '',
                    errorAlert: error
                });
            }
        )
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let name_value = this.state.formSocial.name,
            link_value = this.state.formSocial.link;
        if (name === 'name') {
            name_value = value;
        }
        if (!name_value) {
            if (!form_errors) {
                form_errors = { name: 'Please enter your name' };
            } else {
                form_errors['name'] = 'Please enter your name';
            }
        }
        if (name === 'link') {
            link_value = value;
        }
        if (!link_value) {
            if (!form_errors) {
                form_errors = { link: 'Please enter your link' };
            } else {
                form_errors['link'] = 'Please enter your link';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value, files } = event.target;
        if (name === 'icon' || name === 'icon_with_name' || name === 'icon_with_text') {
            this.setState(prevState => ({
                ...prevState,
                formSocial: {
                    ...prevState.formSocial,
                    [name]: files[0],
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                formSocial: {
                    ...prevState.formSocial,
                    [name]: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        if (this.props.router.params.socialId) {
            this.props.setBreadcrumbItems("Edit Social", this.state.breadcrumbItems);

            getApi('/socials/' + this.props.router.params.socialId).then(
                (response) => {
                    if (response.status === 'success') {
                        this.setState({
                            formSocial: {
                                id: response.social.id,
                                name: response.social.name,
                                link: response.social.link,
                                input_text: response.social.input_text,
                                display_text: response.social.display_text,
                                display_link: response.social.display_link,
                                icon_type: response.social.icon_type,
                                icon: response.social.icon,
                                icon_with_name: response.social.icon_with_name,
                                icon_with_text: response.social.icon_with_text,
                                icon_text: response.social.icon_text,
                                wide_icon: response.social.wide_icon,
                                status: response.social.status
                            }
                        });
                    } else {
                        this.setState({
                            formSocial: {
                                id: 0,
                                name: '',
                                link: '',
                                input_text: '',
                                display_text: '',
                                display_link: '',
                                icon_type: 'ICON_ONLY',
                                icon: null,
                                icon_with_name: null,
                                icon_with_text: null,
                                icon_text: '',
                                wide_icon: null,
                                status: 'ACTIVE'
                            }
                        });
                    }
                },
                (error) => {
                    this.setState({
                        formSocial: {
                            id: 0,
                            name: '',
                            link: '',
                            input_text: '',
                            display_text: '',
                            display_link: '',
                            icon_type: 'ICON_ONLY',
                            icon: null,
                            icon_with_name: null,
                            icon_with_text: null,
                            icon_text: '',
                            wide_icon: null,
                            status: 'ACTIVE'
                        }
                    });
                }
            )
        } else {
            this.props.setBreadcrumbItems("Create Social", this.state.breadcrumbItems);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { iconTypes, formSocial, successAlert, errorAlert } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                { successAlert && <Alert color="success">{successAlert}</Alert> }

                                { errorAlert && <Alert color="danger">{errorAlert}</Alert> }

                                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <input
                                            type="text"
                                            name="name"
                                            className={ `form-control mb-1 ${this.state.errors && 'name' in this.state.errors ? 'error' : ''}` }
                                            value={ formSocial.name }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "name" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.name }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Link</Label>
                                        <input
                                            type="text"
                                            name="link"
                                            className={ `form-control mb-1 ${this.state.errors && 'link' in this.state.errors ? 'error' : ''}` }
                                            value={ formSocial.link }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "link" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.link }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Input Text</Label>
                                        <input
                                            type="text"
                                            name="input_text"
                                            className={ `form-control mb-1 ${this.state.errors && 'link' in this.state.errors ? 'error' : ''}` }
                                            value={ formSocial.input_text }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "input_text" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.input_text }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Display Text</Label>
                                        <input
                                            type="text"
                                            name="display_text"
                                            className={ `form-control mb-1 ${this.state.errors && 'link' in this.state.errors ? 'error' : ''}` }
                                            value={ formSocial.display_text }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "display_text" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.display_text }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Display Link</Label>
                                        <input
                                            type="text"
                                            name="display_link"
                                            className={ `form-control mb-1 ${this.state.errors && 'link' in this.state.errors ? 'error' : ''}` }
                                            value={ formSocial.display_link }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "display_link" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.display_link }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Icon Type</Label>
                                        <select
                                            className="form-control"
                                            name="icon_type"
                                            onChange={ this.handleChangeField }
                                        >
                                            {
                                                iconTypes.map((iconType, i) =>
                                                    <option key={i} value={iconType['value']}>{iconType['label']}</option>
                                                )
                                            }
                                        </select>
                                    </FormGroup>
                                    {
                                        formSocial.icon_type === 'ICON_ONLY' &&
                                        <FormGroup>
                                            <Label>Icon</Label>
                                            <input
                                                type="file"
                                                name="icon"
                                                className={ `form-control mb-1 ${ this.state.errors && 'icon' in this.state.errors ? 'error' : '' }` }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon }</span>
                                            }
                                            {
                                                formSocial.icon &&
                                                <img src={ formSocial.icon } alt={ formSocial.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formSocial.icon_type === 'ICON_NAME' &&
                                        <FormGroup>
                                            <Label>Icon with name</Label>
                                            <input
                                                type="file"
                                                name="icon_with_name"
                                                className={ `form-control mb-1 ${ this.state.errors && 'icon_with_name' in this.state.errors ? 'error' : '' }` }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon_with_name" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon_with_name }</span>
                                            }
                                            {
                                                formSocial.icon_with_name &&
                                                <img src={ formSocial.icon_with_name } alt={ formSocial.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formSocial.icon_type === 'ICON_TEXT' &&
                                        <FormGroup>
                                            <Label>Icon</Label>
                                            <input
                                                type="file"
                                                name="icon_with_text"
                                                className={ `form-control mb-1 ${ this.state.errors && 'icon_with_text' in this.state.errors ? 'error' : '' }` }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon_with_text" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon_with_text }</span>
                                            }
                                            {
                                                formSocial.icon_with_text &&
                                                <img src={ formSocial.icon_with_text } alt={ formSocial.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formSocial.icon_type === 'ICON_TEXT' &&
                                        <FormGroup>
                                            <Label>Text with Icon</Label>
                                            <input
                                                type="text"
                                                name="icon_text"
                                                className={ `form-control mb-1 ${ this.state.errors && 'icon_text' in this.state.errors ? 'error' : '' }` }
                                                value={ formSocial.icon_text }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon_text" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon_text }</span>
                                            }
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label>Wide Icon</Label>
                                        <input
                                            type="file"
                                            name="wide_icon"
                                            className={ `form-control mb-1 ${ this.state.errors && 'wide_icon' in this.state.errors ? 'error' : '' }` }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "wide_icon" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.icon }</span>
                                        }
                                        {
                                            formSocial.wide_icon &&
                                            <img src={ formSocial.wide_icon } alt={ formSocial.name } className="img-fluid mwp-200"/>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <select
                                            className="form-control"
                                            name="status"
                                            value={ formSocial.status }
                                            onChange={ this.handleChangeField }
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="DISABLE">DISABLE</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color="primary" className="w-md waves-effect waves-light mr-1" type="submit">{ formSocial.id ? 'Update' : 'Create' }</Button>
                                        <button className="btn p-0" type="button"><a className="btn btn-danger w-md waves-effect waves-light" href={`/socials`}>Cancel</a></button>
                                    </FormGroup>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    postApi
})(SocialEdit));