import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { forgetUser } from '../../store/actions';
import { withRouter } from '../../helpers/funcUtils';

import logo_dark from "../../assets/images/logo-dark.png";

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            errors: null,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.props.forgetUser(this.state.email, this.props.history);
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let email_value = this.state.email;
        if (name === 'email') {
            email_value = value;
        }
        if (!email_value) {
            if (!form_errors) {
                form_errors = { username: 'Please enter your email' };
            } else {
                form_errors['username'] = 'Please enter your email';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'email') {
            this.setState(prevState => ({
                ...prevState,
                email: value,
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                        <Link to="/" className="logo logo-admin"><img src={logo_dark} height="30" alt="logo"/></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-3 text-center">Reset Password</h4>
                                            {
                                                this.props.message !== null ?
                                                    <Alert color="success">Password Reset successful</Alert>
                                                :
                                                    <Alert color="info">Enter your Email and instructions will be sent to you!</Alert>
                                            }

                                            <form className="form-horizontal mt-4" onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <label for="email">Email</label>
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className={ `form-control mb-1 ${this.state.errors && 'email' in this.state.errors ? 'error' : ''}` }
                                                            placeholder="Enter name"
                                                            onChange={ this.handleChangeField } />
                                                        {
                                                            this.state.errors && "email" in this.state.errors &&
                                                            <span className="error-message text-danger small">{ this.state.errors.email }</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12 text-right">
                                                    {
                                                        this.props.loading ?
                                                            <Button color="primary" classNameName="w-md waves-effect waves-light">Loading ...</Button>
                                                        :
                                                            <Button color="primary" classNameName="w-md waves-effect waves-light" type="submit">Reset Password</Button>
                                                    }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center text-shadow">
                                    <p>Remember It ? <Link to="/login" className="text-primary"> Sign In Here </Link> </p>
                                    <p>© {new Date().getFullYear()} Tilk.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { message, loginError, loading } = state.Forget;
    return { message, loginError, loading };
}

export default withRouter(connect(mapStateToProps, { forgetUser })(ForgetPassword));