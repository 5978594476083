import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import { connect } from "react-redux";
import classnames from 'classnames';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, deleteApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class UrlStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Urls", link : "/urls" },
                { title : "Url Statistics", link : "#" },
            ],
            activeTab: 'statistics',
            user: this.props.user
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Url Statistics", this.state.breadcrumbItems);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { activeTab } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'statistics' })}
                                            onClick={() => { this.toggle('statistics'); }}
                                        >
                                            Traffic statistics
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'location' })}
                                            onClick={() => { this.toggle('location'); }}
                                        >
                                            Traffic location
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'sources' })}
                                            onClick={() => { this.toggle('sources'); }}
                                        >
                                            Traffic sources
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'share' })}
                                            onClick={() => { this.toggle('share'); }}
                                        >
                                            Share
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="statistics">
                                    </TabPane>
                                    <TabPane tabId="location">
                                    </TabPane>
                                    <TabPane tabId="sources">
                                    </TabPane>
                                    <TabPane tabId="share">
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    deleteApi
})(UrlStatistics));