import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                ©{new Date().getFullYear()} Tilk.
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;