import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import { connect } from "react-redux";
import GoogleChart from "react-google-charts";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class GeoBarChart extends Component {
    constructor(props) {
        super(props);

        let self = this;

        this.state = {
            title: '',
            actionUrl: '',
            actionParam: '',
            range: '7days',
            day_ranges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            dayLabel: '',
            geoChartData: {
                data: [],
                options: {
                }
            },
            barChartData: {
                data: [],
                options: {
                    indexAxis: 'y',
                    scales: {
                        x: {
                            ticks: {
                                callback: function(value, index, values) {
                                    return value + '%';
                                }
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.label + ' ' + context.formattedValue + '%';
                                },
                                title: function(context) {
                                    return self.state.dayLabel;
                                }
                            }
                        }
                    }
                }
            },
        }

        this.changeDayRange = this.changeDayRange.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadData();
        }, 500);
    }

    loadData = () => {
        if (!this.state.actionUrl) return;

        getApi(this.state.actionUrl + (this.state.range ? '?range=' + this.state.range : '')).then(
            (response) => {
                if (this.state.actionParam && response[this.state.actionParam]) {
                    let locations = response[this.state.actionParam];
                    let geo_chart_data = [[locations.label, 'Visitors']];
                    let bar_chart_data = { labels: [], datasets: [] };
                    let bar_chart_dataset = [];
                    let bar_chart_total_value = 0;

                    for (let location_data_index = 0; location_data_index < locations.data.length; location_data_index++) {
                        geo_chart_data.push([locations.data[location_data_index]['label'], locations.data[location_data_index]['value']]);
                        bar_chart_total_value += parseInt(locations.data[location_data_index]['value']);
                    }
                    for (let location_data_index = 0; location_data_index < locations.data.length; location_data_index++) {
                        bar_chart_data.labels.push(locations.data[location_data_index]['label']);
                        bar_chart_dataset.push(parseFloat(locations.data[location_data_index]['value'] / bar_chart_total_value * 100));
                    }
                    bar_chart_data.datasets.push(
                        {
                            label: 'Visitors',
                            data: bar_chart_dataset,
                            backgroundColor: "#28bbe3",
                            borderColor: "#28bbe3"
                        }
                    );

                    this.setState({
                        dayLabel: locations.label,
                        geoChartData: {
                            ...this.state.geoChartData,
                            data: geo_chart_data
                        },
                        barChartData: {
                            ...this.state.barChartData,
                            data: bar_chart_data
                        }
                    });
                }
            },
            (error) => {
                this.setState({
                });
            }
        )
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.action_url && this.props.action_param) {
                setTimeout(() => {
                    this.loadData();
                }, 500);
            }

            this.setState({
                title: this.props.title,
                actionUrl: this.props.action_url,
                actionParam: this.props.action_param
            });
        }
    }

    render() {
        const { title, range, day_ranges, geoChartData, barChartData } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12">
                                        <div className="mb-3">
                                            <h5>{ title }</h5>
                                        </div>
                                        <div className="mb-3">
                                            <Label>Day of Range</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    day_ranges.map((day_range, i) =>
                                                    <option key={i} value={ day_range['value'] }>{ day_range['label'] }</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <GoogleChart
                                            chartType="GeoChart"
                                            width="100%"
                                            height="400px"
                                            data={ geoChartData.data }
                                            options={ geoChartData.options }
                                            mapsApiKey={ process.env.REACT_APP_GOOGLE_API_KEY }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        {
                                            barChartData.data && barChartData.data.datasets && !!barChartData.data.datasets.length &&
                                            <Bar
                                                data={ barChartData.data }
                                                options={ barChartData.options }
                                            />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
}

export default withRouter(connect(mapStateToProps, {
    getApi
})(GeoBarChart));