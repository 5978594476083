import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from 'query-string';

import 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class NewVsReturn extends Component {
    constructor(props) {
        super(props);

        let locationQuery = queryString.parse(this.props.router.location.search);

        this.state = {
            breadcrumbItems : [
                { title : "New vs Returning", link : "#" },
            ],
            title: 'New vs Returning',
            type: locationQuery.type ? locationQuery.type : '',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            lineChartData: {
                data: { labels: [], datasets: [] },
                options: { responsive: true }
            },
            tableData: {
                columns: [
                    { label: 'Visitor Type', field: 'type', width: 150 },
                    { label: 'Visitors', field: 'count', sort: 'desc', width: 150 },
                ],
                rows: []
            },
            user: this.props.user
        }

        this.changeDayRange = this.changeDayRange.bind(this);
        this.toggleTab = this.toggleTab.bind(this);

        this.loadLanguage = this.loadLanguage.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadLanguage();
        }, 500);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    loadLanguage = () => {
        getApi('/analytic/new-vs-return/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') +
            '?range=' + this.state.range +
            (this.state.type ? '&type=' + this.state.type : '')).then(
            (response) => {
                let new_vs_return = response.new_vs_return;
                let table_data = new_vs_return.data;
                for (let table_data_index = 0; table_data_index < table_data.length; table_data_index++) {
                    if (table_data[table_data_index]['type_link']) {
                        table_data[table_data_index]['type'] = <Link to={`/new-vs-return/${(this.props.router.params.urlId ? this.props.router.params.urlId + '/': '')}?type=${table_data[table_data_index]['type_link']}`}>{table_data[table_data_index]['type']}</Link>
                    }
                }
                this.setState({
                    lineChartData: {
                        ...this.state.lineChartData,
                        data: {
                            labels: new_vs_return.line_data.labels,
                            datasets: [{ label: 'Visitors', data: new_vs_return.line_data.data, borderColor: '#1967d2' }]
                        }
                    },
                    tableData: {
                        ...this.state.tableData,
                        rows: new_vs_return.data
                    }
                })
            },
            (error) => {
                this.setState({
                    lineChartData: {
                        ...this.state.lineChartData,
                        data: {
                            labels: '',
                            datasets: []
                        }
                    },
                    tableData: {
                        ...this.state.tableData,
                        rows: []
                    }
                });
            }
        );
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("New vs Returning", this.state.breadcrumbItems);

        this.loadLanguage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });

            if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
                window.location.reload();
            }
        }
    }

    render() {
        const { title, range, dayRanges, lineChartData, tableData } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs="12" md="3">
                                        <div className="mb-3">
                                            <h5>{title}</h5>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={dayRange['value']}>{dayRange['label']}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Line data={lineChartData.data} options={lineChartData.options}/>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <MDBDataTable
                                            responsive
                                            data={ tableData }
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(NewVsReturn));