import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert
} from "reactstrap";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi, postApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class UserEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Users", link : "/users" },
                { title : "Edit User", link : "#" },
            ],
            roles: [],
            user: this.props.user,
            formUser: {
                id: 0,
                name: '',
                email: '',
                role: '',
                status: ''
            },
            errors: null,
            successAlert: '',
            errorAlert: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let name_value = this.state.formUser.name,
            email_value = this.state.formUser.email;
        if (name === 'name') { name_value = value; }
        if (!name_value) {
            if (!form_errors) {
                form_errors = { name: 'Please enter your name' };
            } else {
                form_errors['name'] = 'Please enter your name';
            }
        }
        if (name === 'email') { email_value = value; }
        if (!email_value) {
            if (!form_errors) {
                form_errors = { email: 'Please enter your email' };
            } else {
                form_errors['email'] = 'Please enter your email';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'name') {
            this.setState(prevState => ({
                ...prevState,
                formUser: {
                    ...prevState.name,
                    name: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'email') {
            this.setState(prevState => ({
                ...prevState,
                formUser: {
                    ...prevState.email,
                    email: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'role') {
            this.setState(prevState => ({
                ...prevState,
                formUser: {
                    ...prevState.role,
                    role: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'status') {
            this.setState(prevState => ({
                ...prevState,
                formUser: {
                    ...prevState.role,
                    status: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        postApi('/users/' + this.state.formUser.id, { name: this.state.formUser.name, email: this.state.formUser.email, role: this.state.formUser.role, status: this.state.formUser.status }).then(
            (response) => {
                this.setState({
                    successAlert: response.message,
                    errorAlert: '',
                    formUser: {
                        id: response.user.id,
                        name: response.user.name,
                        email: response.user.email,
                        role: response.user.role,
                        status: response.user.status
                    }
                });
            },
            (error) => {
                this.setState({
                    successAlert: '',
                    errorAlert: error
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Edit User", this.state.breadcrumbItems);

        getApi('/users/roles').then(
            (response) => {
                this.setState({
                    roles: response.roles
                });
            },
            (error) => {
                this.setState({
                    roles: []
                });
            }
        )

        getApi('/users/' + this.props.router.params.userId).then(
            (response) => {
                this.setState({
                    formUser: {
                        id: response.user.id,
                        name: response.user.name,
                        email: response.user.email,
                        role: response.user.role,
                        status: response.user.status
                    }
                });
            },
            (error) => {
                this.setState({
                    formUser: {
                        id: 0,
                        name: '',
                        email: '',
                        role: '',
                        status: ''
                    }
                });
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { roles, successAlert, errorAlert } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                {successAlert && <Alert color="success">{successAlert}</Alert>}

                                {errorAlert && <Alert color="danger">{errorAlert}</Alert>}

                                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <input
                                            type="text"
                                            name="name"
                                            className={ `form-control mb-1 ${this.state.errors && 'name' in this.state.errors ? 'error' : ''}` }
                                            placeholder="Enter Name"
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "name" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.name }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <input
                                            type="email"
                                            name="email"
                                            className={ `form-control mb-1 ${this.state.errors && 'email' in this.state.errors ? 'error' : ''}` }
                                            placeholder="Enter Email"
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "email" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.email }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Role</Label>
                                        <select
                                            className="form-control"
                                            name="role"
                                            onChange={ this.handleChangeField }
                                        >
                                            {
                                                roles.map(function(user_role, i) {
                                                    return <option key={i} value={user_role}>{user_role}</option>;
                                                })
                                            }
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <select
                                            className="form-control"
                                            name="status"
                                            onChange={ this.handleChangeField }
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="DISABLE">DISABLE</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color="primary" className="w-md waves-effect waves-light mr-1" type="submit">Update</Button>
                                        <button className="btn p-0" type="button"><a className="btn btn-danger w-md waves-effect waves-light" href={`/users`}>Cancel</a></button>
                                    </FormGroup>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    postApi
})(UserEdit));