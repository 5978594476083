import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from 'query-string';

import { MDBDataTable } from 'mdbreact';
import 'chart.js/auto';
import { Line, Pie } from 'react-chartjs-2';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class MobileDevice extends Component {
    constructor(props) {
        super(props);

        let locationQuery = queryString.parse(this.props.router.location.search);

        this.state = {
            breadcrumbItems : this.props.router.params.urlId ? [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Mobile Device", link : "/mobile-device" },
                { title : 'Url Mobile Device', link : "#" },
            ]: [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Mobile Device", link : "#" },
            ],
            title: 'Mobile Device',
            type: locationQuery.type ? locationQuery.type : 'info',
            view_types: [
                { 'value': 'info', 'label': 'Device Info' },
                { 'value': 'brand', 'label': 'Device Branding' }
            ],
            brand_info: locationQuery.brand_info ? locationQuery.brand_info : '',
            model: locationQuery.model ? locationQuery.model : '',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            url: {},
            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Mobile Device',
                        data: [],
                        fill: false,
                    },
                ]
            },
            pieChartData: {
                columns: [],
                datasets: [],
            },
            tableData: {
                columns: [
                    { label: 'Mobile Device Info', field: 'mobile_device', sort: 'asc', width: 150 },
                    { label: 'Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            user: this.props.user
        }

        this.changeDayRange = this.changeDayRange.bind(this);

        this.loadUrl = this.loadUrl.bind(this);
        this.loadMobileDevice = this.loadMobileDevice.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadMobileDevice();
        }, 500);
    }

    loadUrl = () => {
        if (this.props.router.params.urlId) {
            getApi('/urls/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
                (response) => {
                    this.setState({
                        url: response.url
                    });
                },
                (error) => {
                    this.props.router.navigate('/mobile-device');
                    return;
                }
            );
        }
    }

    loadMobileDevice = () => {
        getApi('/mobile-device/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') +
            '?range=' + this.state.range + '&type=' + this.state.type + (this.state.brand_info ? '&brand_info=' + this.state.brand_info : '') +
            (this.state.model ? '&model=' + this.state.model : '')).then(
            (response) => {
                let mobile_device = response.mobile_device;
                for (let mobile_device_index = 0; mobile_device_index < mobile_device.table_data.length; mobile_device_index++) {
                    if (mobile_device.table_data[mobile_device_index]['brand_info'] || mobile_device.table_data[mobile_device_index]['model']) {
                        mobile_device.table_data[mobile_device_index]['mobile_device'] = <Link to={`/mobile-device/${(this.props.router.params.urlId ? this.props.router.params.urlId + '/': '')}?type=${this.state.type}${mobile_device.table_data[mobile_device_index]['brand_info'] ? '&brand_info=' + mobile_device.table_data[mobile_device_index]['brand_info'] : ''}${mobile_device.table_data[mobile_device_index]['model'] ? '&model=' + mobile_device.table_data[mobile_device_index]['model'] : ''}`}>{mobile_device.table_data[mobile_device_index]['mobile_device']}</Link>
                    }
                }
                this.setState({
                    pieChartData: {
                        labels: mobile_device.pie_chart.labels,
                        datasets: mobile_device.pie_chart.data
                    },
                    lineChartData: {
                        labels: mobile_device.line_chart.labels,
                        datasets: [
                            {
                                label: this.state.lineChartData.datasets[0].label,
                                fill: this.state.lineChartData.datasets[0].fill,
                                data: mobile_device.line_chart.data
                            }
                        ]
                    },
                    tableData: {
                        columns: [
                            {
                                ...this.state.tableData.columns[0],
                                label: mobile_device.table_label
                            },
                            this.state.tableData.columns[1],
                        ],
                        rows: mobile_device.table_data
                    }
                });
            },
            (error) => {
                this.setState({
                    pieChartData: {
                        labels: [],
                        datasets: []
                    },
                    lineChartData: {
                        labels: [],
                        datasets: [
                            {
                                label: this.state.lineChartData.datasets[0].label,
                                fill: this.state.lineChartData.datasets[0].fill,
                                data: []
                            }
                        ]
                    },
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Mobile Device", this.state.breadcrumbItems);

        this.loadUrl();
        this.loadMobileDevice();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });

            if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
                window.location.reload();
            }
        }
    }

    render() {
        const { title, range, dayRanges, view_types, url, lineChartData, pieChartData, tableData, brand, device } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {this.props.router.params.urlId &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Url</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <p><strong>Link:</strong> <span><Link target="_blank" to={ url.site_url }>{ url.site_url }</Link></span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs="12" md="3">
                                        <div className="mb-3">
                                            <h5> {title }</h5>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={ dayRange['value'] }>{ dayRange['label'] }</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="12" md="4" lg="3">
                                        <div>
                                            <ul className="d-flex fa-ul">
                                                {view_types.map((view_type, i) =>
                                                    <li className="p-2" key={i} value={view_type['value']}><Link to={`/mobile-device/?type=${view_type['value']}`}>{view_type['label']}</Link></li>
                                                )}
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>

                                {(brand || device) &&
                                    <Row>
                                        <Col xs="12" md="6">
                                            <Line data={ lineChartData } options={{ scales: { y: { beginAtZero: true } } }} />
                                        </Col>
                                        <Col xs="12" md="6">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                btn
                                                data={ tableData }
                                            />
                                        </Col>
                                    </Row>
                                }
                                {(!brand && !device) &&
                                    <Row>
                                        <Col xs="12" md="4">
                                            <Pie data={ pieChartData } />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <Line data={ lineChartData } options={{ scales: { y: { beginAtZero: true } } }} />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                btn
                                                data={ tableData }
                                            />
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(MobileDevice));