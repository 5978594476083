import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkLogin, clearLoginError } from '../../store/actions';
import { withRouter } from '../../helpers/funcUtils';

import logo_dark from "../../assets/images/logo-dark.png";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            errors: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.props.checkLogin({ username: this.state.username, password: this.state.password }, this.props.router.navigate);
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let username_value = this.state.username, password_value = this.state.password;
        if (name === 'username') {
            username_value = value;
        }
        if (!username_value) {
            if (!form_errors) {
                form_errors = { username: 'Please enter your name' };
            } else {
                form_errors['username'] = 'Please enter your name';
            }
        }
        if (name === 'password') {
            password_value = value;
        }
        if (!password_value) {
            if (!form_errors) {
                form_errors = { password: 'Please enter your password' };
            } else {
                form_errors['password'] = 'Please enter your password';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'username') {
            this.setState(prevState => ({
                ...prevState,
                username: value,
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'password') {
            this.setState(prevState => ({
                ...prevState,
                password: value,
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    componentDidMount() {
        this.props.clearLoginError();
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="/" className="logo logo-admin"><img src={logo_dark} height="30" alt="logo" /></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-1 text-center">Sign in</h4>
                                            {
                                                this.props.user &&
                                                <Alert color="success">Your Login is successful.</Alert>
                                            }
                                            {
                                                this.props.loginError &&
                                                <Alert color="danger"> { this.props.loginError }</Alert>
                                            }
                                            <form className="form-horizontal mt-4" onSubmit={ this.handleSubmit }>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="username">Username</label>
                                                    <input
                                                        type="text"
                                                        name="username"
                                                        className={ `form-control mb-1 ${this.state.errors && 'username' in this.state.errors ? 'error' : ''}` }
                                                        placeholder="Enter name"
                                                        onChange={ this.handleChangeField } />
                                                    {
                                                        this.state.errors && "username" in this.state.errors &&
                                                        <span className="error-message text-danger small">{ this.state.errors.username }</span>
                                                    }
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className={ `form-control mb-1 ${this.state.errors && 'password' in this.state.errors ? 'error' : ''}` }
                                                        placeholder="Enter password"
                                                        onChange={ this.handleChangeField } />
                                                    {
                                                        this.state.errors && "password" in this.state.errors &&
                                                        <span className="error-message text-danger small">{ this.state.errors.password }</span>
                                                    }
                                                </div>

                                                <div className="form-group row mt-4">
                                                    <Col xs="6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input me-2" id="customControlInline" />
                                                            <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6" className="text-right">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                    </Col>
                                                </div>
                                                <div className="form-group mb-0 row">
                                                    <Col xs="12" className="mt-4">
                                                        <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                                    </Col>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center text-shadow">
                                    <p>Don't have an account ? <Link to="/register" className="text-primary"> Signup Now </Link></p>
                                    <p>©{new Date().getFullYear()} Tilk.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}

export default withRouter(connect(mapStateToProps, { checkLogin, clearLoginError })(Login));