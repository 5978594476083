import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN } from './actionTypes';
import { apiLoginError, loginUserSuccess } from './actions';

// AUTH related methods
import { setLoggedInUser, postAuth } from '../../../helpers/authUtils';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, navigate } }) {
    try {
        const response = yield call(postAuth, '/login', user);
        setLoggedInUser(response.user, response.access_token);
        yield put(loginUserSuccess(response.user));
        navigate('/dashboard');
    } catch (error) {
        yield put(apiLoginError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

function* loginSaga() {
    yield all([fork(watchUserLogin)]);
}

export default loginSaga;