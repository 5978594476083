import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, postApi, deleteApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class Socials extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Socials", link : "#" },
            ],
            tableData: {
                columns: [
                    { label: 'Name', field: 'name', sort: 'asc', width: 150 },
                    { label: 'Icon', field: 'icon_img', sort: 'asc', width: 250 },
                    { label: 'Wide Icon', field: 'wide_icon', sort: 'asc', width: 250 },
                    { label: 'Link', field: 'link', sort: 'asc', width: 250 },
                    { label: 'Status', field: 'status', sort: 'asc', width: 200 },
                    { label: 'Actions', field: 'actions', sort: 'disabled', width: 100 }
                ],
                rows: []
            },
            user: this.props.user
        }
        this.handleDeleteSocial = this.handleDeleteSocial.bind(this);
        this.handleActiveSocial = this.handleActiveSocial.bind(this);
        this.handleDisableSocial = this.handleDisableSocial.bind(this);
    }

    handleDeleteSocial(socialId) {
        deleteApi('/socials/' + socialId).then(
            (response) => {
                this.loadSocials();
            },
            (error) => {
            }
        )
    }

    handleActiveSocial(socialId) {
        postApi('/socials/active/' + socialId).then(
            (response) => {
                this.loadSocials();
            },
            (error) => {
            }
        )
    }

    handleDisableSocial(socialId) {
        postApi('/socials/disable/' + socialId).then(
            (response) => {
                this.loadSocials();
            },
            (error) => {
            }
        )
    }

    loadSocials() {
        getApi('/socials/').then(
            (response) => {
                let socials = response.socials;
                for (let social_index = 0; social_index < socials.length; social_index++) {
                    socials[social_index]['icon_img'] = '';
                    if (socials[social_index]['icon_type'] === 'ICON_ONLY' && socials[social_index]['icon']) {
                        socials[social_index]['icon_img'] = <img src={ socials[social_index]['icon'] } alt={ socials[social_index]['name'] } className="img-fluid mwp-100"/>
                    }
                    if (socials[social_index]['icon_type'] === 'ICON_NAME' && socials[social_index]['icon_with_name']) {
                        socials[social_index]['icon_img'] = <img src={ socials[social_index]['icon_with_name'] } alt={ socials[social_index]['name'] } className="img-fluid mwp-100"/>
                    }
                    if (socials[social_index]['icon_type'] === 'ICON_TEXT' && socials[social_index]['icon_with_text']) {
                        socials[social_index]['icon_img'] = <img src={ socials[social_index]['icon_with_text'] } alt={ socials[social_index]['name'] } className="img-fluid mwp-100"/>
                    }
                    socials[social_index]['link'] = <Link to={{ pathname: socials[social_index]['link'] }} target="_blank">{ socials[social_index]['link'] }</Link>
                    if (socials[social_index]['status'] !== 'ACTIVE') {
                        socials[social_index]['social'] = <Link to={{ pathname: socials[social_index]['social'] }} target="_blank">{ socials[social_index]['social'] }</Link>
                        socials[social_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/socials/${socials[social_index]['id']}`}>Edit</Link></button>
                            <button className="btn btn-success waves-effect waves-light mr-1" rounded="" onClick={(e) => this.handleActiveSocial(socials[social_index]['id'])}>Active</button>
                            <button className="btn btn-danger waves-effect waves-light" rounded=""
                                    onClick={(e) =>
                                        window.confirm("Are you sure you wish to delete this social?") && this.handleDeleteSocial(socials[social_index]['id'])
                                    }>Delete</button>
                        </div>
                    } else {
                        socials[social_index]['social'] = <Link to={{ pathname: socials[social_index]['social'] }} target="_blank">{ socials[social_index]['social'] }</Link>
                        socials[social_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><Link className="btn btn-primary waves-effect waves-light" to={`/socials/${socials[social_index]['id']}`}>Edit</Link></button>
                            <button className="btn btn-warning waves-effect waves-light mr-1" rounded="" onClick={(e) => this.handleDisableSocial(socials[social_index]['id'])}>Disable</button>
                            <button className="btn btn-danger waves-effect waves-light" rounded=""
                                    onClick={(e) =>
                                        window.confirm("Are you sure you wish to delete this social?") && this.handleDeleteSocial(socials[social_index]['id'])
                                    }>Delete</button>
                        </div>
                    }
                    if (socials[social_index]['wide_icon']) {
                        socials[social_index]['wide_icon'] = <img src={ socials[social_index]['wide_icon'] } alt={ socials[social_index]['name'] } className="img-fluid mwp-100"/>
                    }
                }
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: socials
                    }
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Socials", this.state.breadcrumbItems);

        this.loadSocials();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { tableData } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div className="mb-2 text-right">
                                    <button className="btn me-1 p-0" rounded=""><a className="btn btn-primary waves-effect waves-light " href={`/socials/create`}>Create New</a></button>
                                </div>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    postApi,
    deleteApi
})(Socials));