import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

class Page500 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <div className="ex-page-content text-center">
                                            <h1 className="text-dark">500</h1>
                                            <h3 className="">Internal Server Error</h3>
                                            <br/>
                                            <Link className="btn btn-info mb-4 waves-effect waves-light" to="/"><i className="mdi mdi-home"></i> Back to Home Page</Link>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center text-shadow">
                                    <p>©{new Date().getFullYear()} Tilk.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Page500;