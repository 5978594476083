import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import TwoLinesChart from "../../component/Chart/TwoLinesChart";

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class VisitorsPerDay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : this.props.router.params.urlId ? [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Visitors Per Day", link : "/visitors-per-day" },
                { title : 'Url Visitors Per Day', link : "#" },
            ]: [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Visitors Per Day", link : "#" },
            ],
            url: {},
            user: this.props.user
        }

        this.loadUrl = this.loadUrl.bind(this);
    }

    loadUrl = () => {
        if (this.props.router.params.urlId) {
            getApi('/urls/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
                (response) => {
                    this.setState({
                        url: response.url
                    });
                },
                (error) => {
                    this.props.router.navigate('/visitors-per-day');
                    return;
                }
            );
        }
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems(this.props.router.params.urlId ? 'Url Visitors Per Day' : 'Visitors Per Day', this.state.breadcrumbItems);

        this.loadUrl();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { url } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {
                            this.props.router.params.urlId &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Url</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <p><strong>Link:</strong> <span><Link target="_blank" to={ url.site_url }>{ url.site_url }</Link></span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <TwoLinesChart
                                    title="Visitors Per Day"
                                    label="Visitor"
                                    action_url="/visitors-per-day"
                                    action_param="visitors"/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(VisitorsPerDay));