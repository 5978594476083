import { REGISTER_USER, REGISTER_USER_SUCCESS, REGISTER_API_FAILED, REGISTER_EMPTY_ERROR,CONFIRM_MAIL,CONFIRM_SUCCESS,CONFIRM_ERROR } from './actionTypes';

export const registerUser = (user, navigate) => {
    return {
        type: REGISTER_USER,
        payload: { user, navigate }
    }
}

export const registerUserSuccess = (user) => {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: user
    }
}

export const apiRegisterError = (error) => {
    return {
        type: REGISTER_API_FAILED,
        payload: error
    }
}

export const clearRegisterError = () => {
    return {
        type: REGISTER_EMPTY_ERROR
    }
}

export const confirmMail = (token,userId) => {
    return {
        type: CONFIRM_MAIL,
        payload: { userId,token }
    }
}

export const confirmSuccess = (user) => {
    return {
        type: CONFIRM_SUCCESS,
        payload: { user }
    }
}

export const confirmError = (error) => {
    return {
        type: CONFIRM_ERROR,
        payload: error
    }
}