import React, { Component } from 'react';

import down_icon from "../../assets/images/svgs/down.svg";

class SelectInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            ref: React.createRef(),
            classNames: '',
            name: '',
            value: '',
            text: '',
            options: [],
            iconPosition: '',
            optionId: '',
            optionText: '',
            optionAltText: '',
            optionType: ''
        }

        this.getSelectOptionText = this.getSelectOptionText.bind(this);
        this.getSelectOptionTextValue = this.getSelectOptionTextValue.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.openOptions = this.openOptions.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                classNames: this.props.hasOwnProperty('classNames') ? this.props.classNames : '',
                name: this.props.hasOwnProperty('name') ? this.props.name : '',
                value: this.props.hasOwnProperty('value') ? this.props.value : '',
                text: this.getSelectOptionText(this.props.hasOwnProperty('value') ? this.props.value : ''),
                options: this.props.hasOwnProperty('options') ? this.props.options : [],
                iconPosition: this.props.hasOwnProperty('iconPosition') ? this.props.iconPosition : 'right',
                optionId: this.props.hasOwnProperty('optionId') ? this.props.optionId : 'id',
                optionText: this.props.hasOwnProperty('optionText') ? this.props.optionText : '',
                optionAltText: this.props.hasOwnProperty('optionAltText') ? this.props.optionAltText : '',
                optionType: this.props.hasOwnProperty('optionType') ? this.props.optionType : ''
            });
        }
    }

    componentDidMount = () => {
        this.setState({
            classNames: this.props.hasOwnProperty('classNames') ? this.props.classNames : '',
            name: this.props.hasOwnProperty('name') ? this.props.name : '',
            value: this.props.hasOwnProperty('value') ? this.props.value : '',
            text: this.getSelectOptionText(this.props.hasOwnProperty('value') ? this.props.value : ''),
            options: this.props.hasOwnProperty('options') ? this.props.options : [],
            iconPosition: this.props.hasOwnProperty('iconPosition') ? this.props.iconPosition : 'right',
            optionId: this.props.hasOwnProperty('optionId') ? this.props.optionId : 'id',
            optionText: this.props.hasOwnProperty('optionText') ? this.props.optionText : '',
            optionAltText: this.props.hasOwnProperty('optionAltText') ? this.props.optionAltText : '',
            optionType: this.props.hasOwnProperty('optionType') ? this.props.optionType : ''
        });

        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    getSelectOptionTextValue = (option, value, optionText, optionAltText, optionType) => {
        let stateText = '';
        if (option.hasOwnProperty(optionText)) {
            stateText = option[optionText];
            if (optionType === 'image') {
                if (option[optionText]) {
                    stateText = <img src={ option[optionText] } alt={ value } className="img-fluid mwp-100"/>
                } else {
                    if (option.hasOwnProperty(optionAltText)) {
                        stateText = option[optionAltText];
                    }
                }
            }
        }

        return stateText;
    }

    getSelectOptionText = (value) => {
        let stateText = '';
        let options = this.state.options.length ? this.state.options : (this.props.hasOwnProperty('options') ? this.props.options : []);
        let optionId = this.state.optionId ? this.state.optionId : (this.props.hasOwnProperty('optionId') ? this.props.optionId : '');
        let optionText = this.state.optionText ? this.state.optionText : (this.props.hasOwnProperty('optionText') ? this.props.optionText : '');
        let optionAltText = this.state.optionAltText ? this.state.optionAltText : (this.props.hasOwnProperty('optionAltText') ? this.props.optionAltText : '');
        let optionType = this.state.optionType ? this.state.optionType : (this.props.hasOwnProperty('optionType') ? this.props.optionType : '');

        if (options && options.length) {
            stateText = value;
        }

        if (options) {
            for (let option_index = 0; option_index < options.length; option_index++) {
                if (typeof options[option_index] === 'object') {
                    if (optionId && options[option_index].hasOwnProperty(optionId)) {
                        if (option_index === 0) {
                            stateText = this.getSelectOptionTextValue(options[0], value, optionText, optionAltText, optionType);
                        }
                        if (options[option_index][optionId] === value) {
                            stateText = this.getSelectOptionTextValue(options[option_index], value, optionText, optionAltText, optionType);
                        }
                    }
                }
            }
        }

        return stateText;
    }

    handleDocumentClick(event) {
        if (this.state.ref && this.state.ref.current && !this.state.ref.current.contains(event.target)) {
            this.setState({
                isOpen: false
            });
        }
    }

    openOptions = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    handleChangeField = (value) => {
        if (this.state.name) {
            this.setState({
                isOpen: false,
                value: value,
                text: this.getSelectOptionText(value)
            });

            if (this.props.onChange) this.props.onChange(this.state.name, value);
        } else {
            this.setState({
                isOpen: false,
                value: value
            });
        }
    };

    render() {
        const { isOpen, ref, classNames, value, text, options, iconPosition, optionId, optionText, optionAltText, optionType } = this.state;

        return (
            <div className={`select-input ${ classNames }`} ref={ ref }>
                <div className="wrapper">
                    <div className="icon-text" onClick={() => this.openOptions()}>
                        {
                            iconPosition === 'left' &&
                            <img src={ down_icon } height="30" alt="Down Icon" />
                        }
                        <span>{ text }</span>
                        {
                            iconPosition === 'right' &&
                            <img src={ down_icon } height="30" alt="Down Icon" />
                        }
                    </div>
                    {
                        isOpen &&
                        <div className="options">
                            {
                                options.map((option, i) => (
                                    <>
                                        {
                                            typeof option === 'object' &&
                                            <div key={ i } className={`input-value ${ (option.hasOwnProperty(optionId) ? option[optionId] : '') === value ? 'active' : '' }`} onClick={() => this.handleChangeField(option.hasOwnProperty(optionId) ? option[optionId] : '' )}>
                                                { this.getSelectOptionTextValue(option, option.hasOwnProperty(optionId) ? option[optionId] : '', optionText, optionAltText, optionType) }
                                            </div>
                                        }
                                        {
                                            typeof option !== 'object' &&
                                            <div key={ i } className={`input-value ${ option === value ? 'active' : '' }`} onClick={() => this.handleChangeField(option)}>
                                                { optionType === 'image' && <img src={ option } alt={ option } className="img-fluid mwp-100"/> }
                                                { optionType !== 'image' && option }
                                            </div>
                                        }
                                    </>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default SelectInput;