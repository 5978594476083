import React, { Component } from 'react';
import { Col, Row, Card, CardBody, Label } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from 'query-string';

import GoogleChart from "react-google-charts";
import 'chart.js/auto';
import { Line, Pie } from "react-chartjs-2";
import { MDBDataTable } from 'mdbreact';

import GoogleStateMap from "../../component/Common/GoogleStateMap";

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class Location extends Component {
    constructor(props) {
        super(props);

        const self = this;

        let locationQuery = queryString.parse(this.props.router.location.search);

        this.state = {
            breadcrumbItems : this.props.router.params.urlId ? [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Location", link : "/geo/location" },
                { title : 'Url Location', link : "#" },
            ]: [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Location", link : "#" },
            ],
            title: 'Location',
            type: locationQuery.country ? (locationQuery.state ? 'city' : 'state') : 'country',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            country: locationQuery.country ? locationQuery.country : '',
            state: locationQuery.state ? locationQuery.state : '',
            url: {},
            chartColors: [
                '#1967d2', 'rgba(25,103,210,0.9)', 'rgba(25,103,210,0.8)', 'rgba(25,103,210,0.7)', 'rgba(25,103,210,0.6)',
                'rgba(25,103,210,0.5)', 'rgba(25,103,210,0.4)', 'rgba(25,103,210,0.3)', 'rgba(25,103,210,0.2)', 'rgba(25,103,210,0.1)'
            ],
            geoChartData: {
                data: [],
                options: {}
            },
            pieChartData: {
                labels: [],
                datasets: []
            },
            lineChartData: {
                data: { labels: [], datasets: [] },
                options: { responsive: true }
            },
            tableData: {
                columns: [
                    { label: 'Country', field: 'location', sort: 'asc', width: 150 },
                    { label: 'Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            user: this.props.user,
            chartEvents: [
                {
                    eventName: "select",
                    callback({ chartWrapper }) {
                        self.gotoTopLocation(chartWrapper.getChart().getSelection()[0]['row']);
                    }
                }
            ]
        }

        this.changeDayRange = this.changeDayRange.bind(this);

        this.loadUrl = this.loadUrl.bind(this);
        this.loadLocation = this.loadLocation.bind(this);
        this.gotoTopLocation = this.gotoTopLocation.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadLocation();
        }, 500);
    }

    loadUrl = () => {
        if (this.props.router.params.urlId) {
            getApi('/urls/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
                (response) => {
                    this.setState({
                        url: response.url
                    });
                },
                (error) => {
                    this.props.router.navigate('/geo/location');
                    return;
                }
            );
        }
    }

    gotoTopLocation = (countryIndex) => {
        this.props.router.navigate('/geo/location/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') + '?' +
            (this.state.country ? 'country=' + this.state.country : '') +
            (this.state.state ? (this.state.country ? '&' : '?') + 'state=' + this.state.state : '') +
            ((this.state.country || this.state.state) ? '&' : '') + this.state.type + '=' + this.state.geoChartData['data'][countryIndex + 1][0]);
    }

    loadLocation = () => {
        getApi('/analytic/location/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') +
            '?range=' + this.state.range + (this.state.country ? '&country=' + this.state.country : '') + (this.state.state ? '&state=' + this.state.state : '')).then(
            (response) => {
                let location_key = 'country';
                if (this.state.country) {
                    if (this.state.state) {
                        location_key = 'city';
                    } else {
                        location_key = 'state';
                    }
                }

                let geo_location_clicks = [[(this.state.type === 'country' ? 'Country': (this.state.type === 'state' ? 'State': 'City')), 'Visitors']];
                let pie_location_labels = [];
                let pie_location_data = [];

                let locations = response.locations;
                let locations_data = locations.data;
                let location_table_label = '';
                let table_locations = [];
                for (let location_index = 0; location_index < locations_data.length; location_index++) {
                    geo_location_clicks.push([locations_data[location_index][location_key], locations_data[location_index]['count']]);
                    pie_location_labels.push(locations_data[location_index][location_key]);
                    pie_location_data.push({ data: [locations_data[location_index]['count']], backgroundColor: this.state.chartColors[pie_location_data.length] });

                    if (locations_data[location_index][location_key + '_link']) {
                        location_table_label = <Link to={`/geo/location/${(this.props.router.params.urlId ? this.props.router.params.urlId + '/': '')}?${ locations_data[location_index][location_key + '_link'] }`}>{ locations_data[location_index][location_key] }</Link>
                    } else {
                        location_table_label = locations_data[location_index][location_key];
                    }

                    if (this.state.type === 'country') {
                        table_locations.push({ [location_key]: <p><img src={`/static/media/flags/${ locations_data[location_index][location_key].replace(" ", "-").replace("'", "-") }.png`} alt={ locations_data[location_index][location_key] } className='me-1' height='12'/>{ location_table_label }</p>, count: locations_data[location_index]['count']});
                    } else {
                        table_locations.push({ [location_key]: location_table_label, count: locations_data[location_index]['count']});
                    }
                }

                let geo_chart_options = {};
                if (location_key === 'state') {
                    geo_chart_options = {
                        region: locations.region,
                        resolution: 'provinces'
                    }
                } else if (location_key === 'city') {
                    geo_chart_options = {
                        region: locations.region,
                        displayMode: "markers",
                        resolution: 'provinces'
                    }
                }

                this.setState({
                    geoChartData: {
                        data: geo_location_clicks,
                        options: geo_chart_options
                    },
                    lineChartData: {
                        ...this.state.lineChartData,
                        data: {
                            labels: locations.line_data.labels,
                            datasets: [{ label: 'Visitors', data: locations.line_data.data, borderColor: '#1967d2' }]
                        }
                    },
                    pieChartData: {
                        labels: pie_location_labels,
                        datasets: pie_location_data
                    },
                    tableData: {
                        columns: [
                            {
                                ...this.state.tableData.columns[0],
                                label: location_key === 'country' ? 'Country' : (location_key === 'state' ? 'State' : 'City'),
                                field: location_key
                            },
                            this.state.tableData.columns[1]
                        ],
                        rows: table_locations
                    }
                });
            },
            (error) => {
                this.setState({
                    geoChartData: {
                        data: [[(this.state.type === 'country' ? 'Country': (this.state.type === 'state' ? 'State': 'City')), 'Visitors']],
                        options: {}
                    },
                    lineChartData: {
                        ...this.state.lineChartData,
                        data: {
                            labels: '',
                            datasets: []
                        }
                    },
                    pieChartData: {
                        labels: [],
                        datasets: []
                    },
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems(this.props.router.params.urlId ? 'Url Location' : 'Location', this.state.breadcrumbItems);

        this.loadUrl();
        this.loadLocation();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });

            if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
                window.location.reload();
            }
        }
    }

    render() {
        const { title, range, dayRanges, url, pieChartData, geoChartData, lineChartData, tableData, chartEvents } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {
                            this.props.router.params.urlId &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Url</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <p><strong>Link:</strong> <span><Link target="_blank" to={ url.site_url }>{ url.site_url }</Link></span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs="12" md="3">
                                        <div className="mb-3">
                                            <h5>{ title }</h5>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={ dayRange['value'] }>{ dayRange['label'] }</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="12" md="9">
                                        <GoogleChart
                                            chartType="GeoChart" width="100%" height="400px"
                                            data={ geoChartData.data }
                                            options={ geoChartData.options }
                                            chartEvents={ chartEvents }
                                            mapsApiKey={ process.env.REACT_APP_GOOGLE_API_KEY }
                                        />
                                    </Col>
                                    <Col xs="12" md="3">
                                        <Pie data={ pieChartData } />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Line data={ lineChartData.data } options={ lineChartData.options } className="mhp-250"/>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <MDBDataTable
                                            responsive
                                            bordered
                                            btn
                                            data={ tableData }
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Location));