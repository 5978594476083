import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import logo_dark from "../../assets/images/logo-dark.png";
import user from "../../assets/images/users/user-6.jpg";

class PageLockScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            errors: null
        }
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let password_value = this.state.password;
        if (name === 'password') {
            password_value = value;
        }
        if (!password_value) {
            if (!form_errors) {
                form_errors = { password: 'Please enter your password' };
            } else {
                form_errors['password'] = 'Please enter your password';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'password') {
            this.setState(prevState => ({
                ...prevState,
                password: value,
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="/" className="logo logo-admin"><img src={logo_dark} height="30" alt="logo"/></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-1 text-center">Locked</h4>
                                            <p className="text-muted text-center">Hello Smith, enter your password to unlock the screen!</p>
                                            <form className="form-horizontal mt-4">
                                                <div className="user-thumb text-center mb-4">
                                                    <img src={user} className="rounded-circle avatar-md img-thumbnail" alt="thumbnail"/>
                                                    <h6 className="font-size-16 mt-3">Robert Smith</h6>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className={ `form-control mb-1 ${this.state.errors && 'password' in this.state.errors ? 'error' : ''}`}
                                                            placeholder="Enter name"
                                                            onChange={this.handleChangeField}/>
                                                        {
                                                            this.state.errors && "password" in this.state.errors &&
                                                            <span className="error-message text-danger small">{this.state.errors.password}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12 text-right">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Unlock</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center text-shadow">
                                    <p>Not you ? return <Link to="/login" className=" text-primary"> Sign In </Link> </p>
                                    <p>©{new Date().getFullYear()} Tilk.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PageLockScreen;