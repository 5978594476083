import {
    CHECK_LOGIN,
    LOGIN_USER_SUCCESS,
    LOGIN_API_FAILED,
    CHECK_OTP,
    VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_ERROR,
    LOGIN_ERROR_CLEAR
} from './actionTypes';

export const checkLogin = (user, navigate) => {
    return {
        type: CHECK_LOGIN,
        payload: { user, navigate }
    }
}

export const checkOtp = (user_id, otp, history) => {
    return {
        type: CHECK_OTP,
        payload: { user_id, otp, history }
    }
}

export const validateOtpSuccess = (user) => {
    return {
        type: VALIDATE_OTP_SUCCESS,
        payload: user
    }
}

export const validateOtpError = (error) => {
    return {
        type: VALIDATE_OTP_ERROR,
        payload: error
    }
}

export const loginUserSuccess = (user) => {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: user
    }
}

export const apiLoginError = (error) => {
    return {
        type: LOGIN_API_FAILED,
        payload: error
    }
}

export const clearLoginError = () => {
    return {
        type: LOGIN_ERROR_CLEAR
    }
}