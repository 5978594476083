import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import dateFormat from 'dateformat';
import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, deleteApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class DomainUrls extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "All Domains", link : "/domains" },
                { title : "Domain Urls", link : "#" },
            ],
            tableData: {
                columns: [
                    { label: 'Site URL', field: 'site_url', width: 250 },
                    { label: 'Redirection', field: 'redirection', sort: 'asc', width: 250 },
                    { label: 'Date', field: 'date', sort: 'asc', width: 200 },
                    { label: 'IP', field: 'last_ip', sort: 'asc', width: 200 },
                    { label: 'Clicks', field: 'clicks', sort: 'asc', width: 100 },
                    { label: 'Actions', field: 'actions', sort: 'disabled', width: 100 }
                ],
                rows: []
            },
            user: this.props.user,
            domain: {}
        }

        this.handleDeleteUrl = this.handleDeleteUrl.bind(this);
    }

    handleDeleteUrl(urlId) {
        deleteApi('/urls/' + urlId).then(
            (response) => {
                this.loadUrls();
            },
            (error) => {
            }
        )
    }

    loadUrls() {
        getApi('/domains/' + this.props.router.params.domainId + '/urls/').then(
            (response) => {
                let urls = response.urls;
                for (let url_index = 0; url_index < urls.length; url_index++) {
                    let redirection_url = urls[url_index]['redirection'];
                    if (redirection_url && redirection_url.length > 55) {
                        redirection_url = redirection_url.substr(0, 55) + '[...]';
                    }
                    urls[url_index]['last_view'] = dateFormat(urls[url_index]['date'], "mmm dd, yyyy") + ' ' + urls[url_index]['time'];
                    urls[url_index]['redirection'] = <div>
                        <a href={`${ urls[url_index]['redirection'] }`}>{ urls[url_index]['redirection_title'] }</a><br/>
                        <small><a href={`${ urls[url_index]['redirection'] }`}>{ redirection_url }</a></small>
                    </div>
                    if (this.state.user.role !== 'FREE') {
                        urls[url_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><a className="btn btn-primary waves-effect waves-light" href={`/urls/${urls[url_index]['id']}`}>Edit</a></button>
                            {/*<button className="btn me-1 p-0" rounded=""><a className="btn btn-info waves-effect waves-light" href={`/top-referers/${urls[url_index]['id']}`}><i className="fas fa-window-maximize"></i>&nbsp;Top Referers</a></button>*/}
                            <button className="btn me-1 p-0" rounded=""><a className="btn btn-info waves-effect waves-light" href={`/top-ips/${urls[url_index]['id']}`}><i className="fas fa-network-wired"></i>&nbsp;Top IPs</a></button>
                            {/*<button className="btn me-1 p-0" rounded=""><a className="btn btn-info waves-effect waves-light" href={`/top-countries/${urls[url_index]['id']}`}><i className="fas fa-globe-americas"></i>&nbsp;Top Countries</a></button>*/}
                            {/*<button className="btn me-1 p-0" rounded=""><a className="btn btn-info waves-effect waves-light" href={`/top-states/${urls[url_index]['id']}`}><i className="fas fa-map-marker-alt"></i>&nbsp;Top States</a></button>*/}
                            {/*<button className="btn me-1 p-0" rounded=""><a className="btn btn-info waves-effect waves-light" href={`/top-cities/${urls[url_index]['id']}`}><i className="fas fa-city"></i>&nbsp;Top Cities</a></button>*/}
                            <button className="btn me-1 p-0" rounded=""><a className="btn btn-info waves-effect waves-light" href={`/visitors-per-day/${urls[url_index]['id']}`}><i className="fas fa-chart-area"></i>&nbsp;Visitors Per Day</a></button>
                            <button className="btn btn-danger waves-effect waves-light" rounded="" onClick={(e) => window.confirm("Are you sure you wish to delete this user?") && this.handleDeleteUrl(urls[url_index]['id']) }>Delete</button>
                        </div>
                    } else {
                        urls[url_index]['actions'] = <div>
                            <button className="btn me-1 p-0" rounded=""><a className="btn btn-primary waves-effect waves-light" href={`/urls/${urls[url_index]['id']}`}>Edit</a></button>
                            <button className="btn btn-danger waves-effect waves-light" rounded="" onClick={(e) => window.confirm("Are you sure you wish to delete this user?") && this.handleDeleteUrl(urls[url_index]['id']) }>Delete</button>
                        </div>
                    }
                }
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: urls
                    },
                    domain: response.domain
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    },
                    domain: {}
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/urls');
            return;
        }
        this.props.setBreadcrumbItems("Domain Urls", this.state.breadcrumbItems);
        this.loadUrls();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { tableData, domain } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div className="mb-2">
                                    <label>Domain: {domain && (domain.name + ' - ' + domain.link)}</label>
                                </div>
                                <div className="mb-2 text-right">
                                    <button className="btn me-1 p-0" rounded=""><a className="btn btn-primary waves-effect waves-light " href={`/urls/create`}>Create New</a></button>
                                </div>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    deleteApi
})(DomainUrls));