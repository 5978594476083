import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class Visitors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Visitors", link : "#" },
            ],
            tableData: {
                columns: [
                    { label: 'Date', field: 'date', sort: 'asc', width: 150 },
                    { label: 'Time', field: 'time', sort: 'asc', width: 150 },
                    { label: 'IP', field: 'ip', sort: 'asc', width: 250 },
                    { label: 'Referer', field: 'referer', sort: 'asc', width: 200 },
                    { label: 'User Agent', field: 'ua_browser_family', sort: 'asc', width: 200 },
                    { label: 'Version', field: 'ua_browser_version', sort: 'asc', width: 100 },
                    { label: 'Country', field: 'country', sort: 'asc', width: 100 },
                    { label: 'State', field: 'state', sort: 'asc', width: 100 },
                    { label: 'City', field: 'city', sort: 'asc', width: 100 },
                    { label: 'Latitude', field: 'latitude', sort: 'asc', width: 100 },
                    { label: 'Longitude', field: 'longitude', sort: 'asc', width: 100 }
                ],
                rows: []
            },
            user: this.props.user
        }
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Visitors", this.state.breadcrumbItems);

        getApi('/visitors/').then(
            (response) => {
                let visitors = response.visitors;
                for (let visitor_index = 0; visitor_index < visitors.length; visitor_index++) {
                    visitors[visitor_index]['date'] = dateFormat(visitors[visitor_index]['date'], "mmm dd, yyyy");
                    visitors[visitor_index]['referer'] = <Link to={{ pathname: visitors[visitor_index]['referer'] }} target="_blank">{visitors[visitor_index]['referer']}</Link>
                }
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: visitors
                    }
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { tableData } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Visitors));