import React, { Component } from 'react';
import iro from '@jaames/iro';

class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: ''
        }
    }

    componentDidMount() {
        this.setState({ color: this.props.color });

        this.colorPicker = new iro.ColorPicker(this.el, { color: this.props.color });
        this.colorPicker.on('color:change', (color) => {
            this.setState({ color: color.hexString });
            if (this.props.onColorChange) this.props.onColorChange(color.hexString);
        });
    }

    componentDidUpdate() {
        if (this.state.color) this.colorPicker.color.set(this.state.color);
        this.colorPicker.setState(this.props.colorPickerState);
    }

    render() {
        return (
            <div className="color-picker">
                <div ref={el => this.el = el} />
                <div className="color-picker-color" style={{ background: this.state.color }}></div>
            </div>
        );
    }
}

export default ColorPicker;