import React, { Component } from 'react';
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { changeLayout, changeLayoutWidth, changePreloader } from '../../../store/actions';
import { withRouter } from '../../../helpers/funcUtils';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMail : false,
            isUi : false,
            isForms : false,
            isMore : false,
            isIcons : false,
            isTables : false,
            isMaps : false,
            isCharts : false,
            isPages : false,
            isLayouts : false,
            layoutType: this.props.layoutType,
            layoutWidth: this.props.layoutWidth,
            isPreloader: this.props.isPreloader
        };
        this.changeLayout = this.changeLayout.bind(this);
        this.changeLayoutWidth = this.changeLayoutWidth.bind(this);
        this.changeThemePreloader = this.changeThemePreloader.bind(this);
    }
    
    changeLayout() {
        this.props.changeLayout("vertical");
    }

    //change layout width
    changeLayoutWidth(value) {
        if(this.state.layoutWidth === "boxed")
            this.props.changeLayoutWidth("fluid", this.state.layoutType);
        else
            this.props.changeLayoutWidth("boxed", this.state.layoutType);
    }

    //theme preloader
    changeThemePreloader = (value) => {
        this.props.changePreloader(!this.props.isPreloader);
    }

    componentDidMount() {
        let matchingMenuItem = null;
        let ul = document.getElementById("navigation");
        let items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (this.props.router.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
          this.activateParentDropdown(matchingMenuItem);
        }
    }

    //update local state after changing layout
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                layoutWidth: this.props.layoutWidth,
                isPreloader: this.props.isPreloader
            });
        }

        if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
            this.initMenu();
        }
    }
    
    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="topnav">
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="index" reloadDocument={true}>
                                            <i className="ti-dashboard"></i>Dashboard
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    const { is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topbarTheme, isPreloader } = state.Layout;
    return { is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topbarTheme, isPreloader };
}

export default withRouter(connect(mapStateToProps, {
    changeLayout,
    changeLayoutWidth,
    changePreloader
})(Navbar));