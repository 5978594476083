import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_GUEST } from './actionTypes';
import { apiGuestError, registerGuestSuccess } from './actions';

// AUTH related methods
import { setRegisteredInGuest, postAuth } from '../../../helpers/authUtils';

//If user is login then dispatch redux action's are directly from here.
function* registerGuest({ payload: { guest_session } }) {
    try {
        const response = yield call(postAuth, '/guest', { guest_session: guest_session });
        setRegisteredInGuest(response.guest_session);
        yield put(registerGuestSuccess(response.guest_session));
    } catch (error) {
        yield put(apiGuestError(error));
    }
}

export function* watchGuestRegister() {
    yield takeEvery(CHECK_GUEST, registerGuest)
}

function* guestSaga() {
    yield all([fork(watchGuestRegister)]);
}

export default guestSaga;