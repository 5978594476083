import React from "react";
import { Navigate } from "react-router-dom";

// Get all Auth methods
import { isUserAuthenticated } from './helpers/authUtils';

const AppRoute = ({ component: Component, isAuthProtected, layout: Layout, ...props }) => {
    if (isUserAuthenticated()) {
        if (!isAuthProtected && props && props.hasOwnProperty('match') && props.match.hasOwnProperty('path') && (props.match.path === '/login' || props.match.path === '/login/' || props.match.path === '/register' || props.match.path === '/register/' || props.match.path === '/forget-password' || props.match.path === '/forget-password/')) {
            return (
                <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} exact />
            );
        }
    } else {
        if (isAuthProtected) {
            return (
                <Navigate to={{ pathname: "/login", state: { from: props.location } }} exact />
            );
        }
    }

    return (
        <Layout>
            <Component { ...props } />
        </Layout>
    );
};

export default AppRoute;