import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    FormGroup,
    InputGroup,
    Button,
    Alert
} from 'reactstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SelectInput from "../../component/Common/SelectInput";

// Import Components
import { checkGuest } from '../../store/actions';
import { getApi, postApi } from "../../helpers/apiUtils";
import { getLoggedInUser, getRegisteredInGuestSession } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

import image_analytics from "../../assets/images/svgs/analytics.svg";
import image_bio from "../../assets/images/svgs/bio.svg";
import image_stream from "../../assets/images/svgs/stream.svg";
import image_qr_code from "../../assets/images/svgs/qr-code.svg";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            guest_session: this.props.guest_session,
            domains: [],
            formUrl: {
                domain_id : 0,
                site_url: '',
            },
            errors: null,
            urls: [],
            successAlert: '',
            errorAlert: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);

        this.getDomainLink = this.getDomainLink.bind(this);
        this.getDomainName = this.getDomainName.bind(this);
        this.loadUrls = this.loadUrls.bind(this);
        this.loadDomains = this.loadDomains.bind(this);
        this.copyLink = this.copyLink.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    componentDidMount() {
        this.loadDomains();

        if (!this.state.user) {
            this.props.checkGuest(this.state.guest_session);

            setTimeout(() => {
                this.loadUrls();
            }, 500);
        } else {
            this.loadUrls();
        }

        setTimeout(() => {
            document.title = "TinyLink | Long URL? Shorten it.";
        }, 500);
    }

    getDomainLink = (domain_id = '') => {
        if (this.state.domains && this.state.domains.length) {
            return this.state.domains.filter((domain) => domain['id'] === (domain_id ? domain_id : this.state.formUrl.domain_id))[0]['link'];
        }
        return '';
    }

    getDomainName(url) {
        let constant_url = new URL(url);
        return constant_url.hostname;
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let site_url_value = this.state.site_url
        if (name === 'site_url') {
            site_url_value = value;
        }
        if (!site_url_value) {
            if (!form_errors) {
                form_errors = { site_url: 'Please enter your link' };
            } else {
                form_errors['site_url'] = 'Please enter your link';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'domain_id') {
            this.setState(prevState => ({
                ...prevState,
                formUrl: {
                    ...prevState.formUrl,
                    domain_id: parseInt(value),
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'site_url') {
            this.setState(prevState => ({
                ...prevState,
                formUrl: {
                    ...prevState.formUrl,
                    site_url: value.replace(/^\//, ''),
                },
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    loadDomains() {
        getApi('/domains').then(
            (response) => {
                this.setState({
                    domains: response.domains.map(domain => {
                        return { ...domain, link: domain['link'].replace(/^http:\/\//, '').replace(/^https:\/\//, '') };
                    }),
                    formUrl: {
                        ...this.state.formUrl,
                        domain_id: (response.domains && response.domains.length) ? response.domains[0]['id'] : 0,
                    }
                });
            },
            (error) => {
                this.setState({
                    domains: [],
                    formUrl: {
                        ...this.state.formUrl,
                        domain_id: 0,
                        site_url: ''
                    }
                });
            }
        );
    }

    loadUrls() {
        getApi('/urls').then(
            (response) => {
                this.setState({
                    urls: response.urls,
                });
            },
            (error) => {
                this.setState({
                    urls: [],
                });
            }
        );
    }

    copyLink(urlLink) {
        const textField = document.createElement('textarea');
        textField.innerText = urlLink;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    handleSubmit(event) {
        event.preventDefault();

        let api_link = '';
        if (this.state.user) {
            api_link = '/urls/site-url';
        } else {
            api_link = '/guest/urls/';
        }

        postApi(api_link, { domain_id: this.state.formUrl.domain_id, site_url: this.state.formUrl.site_url }).then(
            (response) => {
                this.setState({
                    successAlert: response.message,
                    formUrl: {
                        ...this.state.formUrl,
                        site_url: '',
                    }
                });
                this.loadUrls();
            },
            (error) => {
                if (error === 'Need to reload') {
                    window.location.reload();
                } else {
                    this.setState({
                        errorAlert: error
                    });
                }
            }
        )
    }

    render() {
        const { domains, urls, formUrl, successAlert, errorAlert } = this.state;

        return (
            <React.Fragment>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12" lg="12" xl="12">
                            <Row className="my-5">
                                <Col md="12">
                                    <h1 className="header-xl">Long URL? Shorten it.</h1>
                                </Col>
                            </Row>
                            <Row className="my-5">
                                <Col md="12">
                                    <form className="form-horizontal" onSubmit={ this.handleSubmit }>
                                        <FormGroup>
                                            <Row className="my-5">
                                                <Col md="10">
                                                    <InputGroup className="url-input">
                                                        <span className="input-group-text">
                                                            <SelectInput
                                                                name="domain_id"
                                                                iconPosition="left"
                                                                options={ domains }
                                                                value={ formUrl.domain_id }
                                                                optionId="id"
                                                                optionText="link"
                                                                onChange={ this.handleChangeField }
                                                            />
                                                        </span>
                                                        <input
                                                            type="text"
                                                            name="site_url"
                                                            className={ `form-control ${ this.state.errors && 'site_url' in this.state.errors ? 'error' : '' }` }
                                                            placeholder="Shorten your link"
                                                            value={ formUrl.site_url }
                                                            onChange={ this.handleChangeField } />
                                                        {
                                                            this.state.errors && "site_url" in this.state.errors &&
                                                            <span className="error-message text-danger small">{ this.state.errors.site_url }</span>
                                                        }
                                                    </InputGroup>
                                                </Col>
                                                <Col md="2">
                                                    <Button
                                                        color="primary"
                                                        className="waves-effect waves-light py-3 w-100"
                                                        type="submit"
                                                    >
                                                        Shorten
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </form>
                                </Col>
                                <Col md="12" className="my-5">
                                    { successAlert && <Alert color="success">{ successAlert }</Alert> }
                                    { errorAlert && <Alert color="danger">{ errorAlert }</Alert> }
                                </Col>
                                <Col md="12">
                                    <ul className="shorten-links">
                                        {
                                            urls && urls.map((url, i) =>
                                                <li key={i} className="shorten-link">
                                                    <span className="long-link">{ url['title'] }</span>
                                                    <span>
                                                        <Link to={ url['site_url'] } className="short-link">{ url['site_url'] }</Link>
                                                        <button className="btn btn-primary" onClick={ () => { this.copyLink(url['site_url']) } }>Copy</button>
                                                    </span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="my-5 text-center text-shadow">
                                <Col md="3" className="info-item">
                                    <div className="info-image">
                                        <img src={ image_analytics } alt="Analytics" />
                                    </div>
                                    <h5>Analytics</h5>
                                    <p>Track user clicks to your links and profiles or QR code scans</p>
                                </Col>
                                <Col md="3" className="info-item">
                                    <div className="info-image">
                                        <img src={ image_bio } alt="Bio" />
                                    </div>
                                    <h5>Link in Bio</h5>
                                    Create a link with all your social media profiles
                                </Col>
                                <Col md="3" className="info-item">
                                    <div className="info-image">
                                        <img src={ image_qr_code } alt="QRCode" />
                                    </div>
                                    <h5>QR Codes</h5>
                                    <p>Create QR Codes and view analytics</p>
                                </Col>
                                <Col md="3" className="info-item">
                                    <div className="info-image">
                                        <img src={ image_stream } alt="Stream" />
                                    </div>
                                    <h5>Streaming Links</h5>
                                    <p>Need a page with links to all streaming services for you song or album?</p>
                                </Col>
                            </Row>
                            <div className="mt-5 text-center text-shadow">
                                <p>©{new Date().getFullYear()} Tilk.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    let { guest_session } = state.Guest;
    if (!user) {
        if (!guest_session) {
            guest_session = getRegisteredInGuestSession();
        }
    }
    return { user, guest_session };
}

export default withRouter(connect(mapStateToProps, {
    checkGuest,
    getApi,
    postApi
})(Home));