import React, { Component } from 'react';

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');

        window.location.href = '/login';
    }

    render() {
        return (
            <React.Fragment>
               <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

export default Logout;