import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge } from "reactstrap";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },
            ],
            statistics: {},
            user: this.props.user
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Dashboard", this.state.breadcrumbItems);

        getApi('/statistics').then(
            (response) => {
                this.setState({
                    statistics: response.statistics
                });
            },
            (error) => {
                this.setState({
                    statistics: {}
                });
            }
        )
    }

    render() {
        let { statistics, user } = this.state;

        return (
            <React.Fragment>
                <Row>
                    {user.role === 'ADMIN' &&
                        <Col xl="3" md="6">
                            <Card className="mini-stat bg-primary">
                                <CardBody className="mini-stat-img">
                                    <div className="mini-stat-icon float-right">
                                        <i className="mdi mdi-account-multiple"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Users</h6>
                                        <h2 className="mb-4">{ statistics && statistics.users && statistics.users.all }</h2>
                                        <p><Badge color="success">{ statistics && statistics.users && statistics.users.active }</Badge> <span className="ms-2">Active</span></p>
                                        <p><Badge color="danger">{ statistics && statistics.users && statistics.users.disable }</Badge> <span className="ms-2">Disable</span></p>
                                        <p><Badge color="secondary">{ statistics && statistics.users && statistics.users.free }</Badge> <span className="ms-2">Free</span></p>
                                        <p><Badge color="warning">{ statistics && statistics.users && statistics.users.basic }</Badge> <span className="ms-2">Basic</span></p>
                                        <p><Badge color="info">{ statistics && statistics.users && statistics.users.plus }</Badge> <span className="ms-2">Plus</span></p>
                                        <p><Badge color="success">{ statistics && statistics.users && statistics.users.admin }</Badge> <span className="ms-2">Admin</span></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    {user.role === 'ADMIN' &&
                        <Col xl="3" md="6">
                            <Card className="mini-stat bg-primary">
                                <CardBody className="mini-stat-img">
                                    <div className="mini-stat-icon float-right">
                                        <i className="mdi mdi-account-remove"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Guests</h6>
                                        <h2 className="mb-4">{ statistics && statistics.guests && statistics.guests.all }</h2>
                                        <p><Badge color="success">{ statistics && statistics.guests && statistics.guests.active }</Badge> <span className="ms-2">Active</span></p>
                                        <p><Badge color="danger">{ statistics && statistics.guests && statistics.guests.disable }</Badge> <span className="ms-2">Disable</span></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    {(user.role === 'ADMIN' || user.role === 'PLUS') &&
                        <Col xl="3" md="6">
                            <Card className="mini-stat bg-success">
                                <CardBody className="mini-stat-img">
                                    <div className="mini-stat-icon float-right">
                                        <i className="fas fa-window-restore"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Domains</h6>
                                        <h2 className="mb-4">{ statistics && statistics.domains && statistics.domains.all }</h2>
                                        <p><Badge color="primary">{ statistics && statistics.domains && statistics.domains.active }</Badge> <span className="ms-2">Active</span></p>
                                        <p><Badge color="danger">{ statistics && statistics.domains && statistics.domains.disable }</Badge> <span className="ms-2">Disable</span></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    <Col xl="3" md="6">
                        <Card className="mini-stat bg-info">
                            <CardBody className="mini-stat-img">
                                <div className="mini-stat-icon float-right">
                                    <i className="mdi mdi-link"></i>
                                </div>
                                {(user.role === 'PLUS' || user.role === 'ADMIN') &&
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Urls</h6>
                                        <h2 className="mb-4">{ statistics && statistics.urls && statistics.urls.all }</h2>
                                        <p><Badge color="primary">{ statistics && statistics.urls && statistics.urls.all }</Badge> <span className="ms-2">All</span></p>
                                        <p><Badge color="success">{ statistics && statistics.urls && statistics.urls.own }</Badge> <span className="ms-2">Own</span></p>
                                    </div>
                                }
                                {(user.role !== 'PLUS' && user.role !== 'ADMIN') &&
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Urls</h6>
                                        <h2 className="mb-4">{ statistics && statistics.urls && statistics.urls.own }</h2>
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    {user.role !== 'FREE' &&
                        <Col xl="3" md="6">
                            <Card className="mini-stat bg-primary">
                                <CardBody className="mini-stat-img">
                                    <div className="mini-stat-icon float-right">
                                        <i className="fas fa-chart-area"></i>
                                    </div>
                                    {(user.role === 'PLUS' || user.role === 'ADMIN') &&
                                        <div className="text-white">
                                            <h6 className="text-uppercase mb-3 font-size-16">Visitors</h6>
                                            <h2 className="mb-4">{ statistics && statistics.visitors && statistics.visitors.all.counts } - { statistics && statistics.visitors && statistics.visitors.own.counts }</h2>
                                            <p><Badge color="primary">{ statistics && statistics.visitors && statistics.visitors.all.countries } - { statistics && statistics.visitors && statistics.visitors.own.countries }</Badge> <span className="ms-2">Countries</span></p>
                                            <p><Badge color="success">{ statistics && statistics.visitors && statistics.visitors.all.states } - { statistics && statistics.visitors && statistics.visitors.own.states }</Badge> <span className="ms-2">States</span></p>
                                            <p><Badge color="info">{ statistics && statistics.visitors && statistics.visitors.all.cities } - { statistics && statistics.visitors && statistics.visitors.own.cities }</Badge> <span className="ms-2">Cities</span></p>
                                            <p><Badge color="secondary">{ statistics && statistics.visitors && statistics.visitors.all.ips } - { statistics && statistics.visitors && statistics.visitors.own.ips }</Badge> <span className="ms-2">IPs</span></p>
                                            <p><Badge color="primary">{ statistics && statistics.visitors && statistics.visitors.all.referers } - { statistics && statistics.visitors && statistics.visitors.own.referers }</Badge> <span className="ms-2">Referers</span></p>
                                        </div>
                                    }
                                    {(user.role !== 'PLUS' && user.role !== 'ADMIN') &&
                                        <div className="text-white">
                                            <h6 className="text-uppercase mb-3 font-size-16">Visitors</h6>
                                            <h2 className="mb-4">{ statistics && statistics.visitors && statistics.visitors.own.counts }</h2>
                                            <p><Badge color="primary">{ statistics && statistics.visitors && statistics.visitors.own.countries }</Badge> <span className="ms-2">Countries</span></p>
                                            <p><Badge color="success">{ statistics && statistics.visitors && statistics.visitors.own.states }</Badge> <span className="ms-2">States</span></p>
                                            <p><Badge color="info">{ statistics && statistics.visitors && statistics.visitors.own.cities }</Badge> <span className="ms-2">Cities</span></p>
                                            <p><Badge color="secondary">{ statistics && statistics.visitors && statistics.visitors.own.ips }</Badge> <span className="ms-2">IPs</span></p>
                                            <p><Badge color="primary">{ statistics && statistics.visitors && statistics.visitors.own.referers }</Badge> <span className="ms-2">Referers</span></p>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Dashboard));