import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Account Redux states
import { REGISTER_USER } from './actionTypes';
import { apiRegisterError } from './actions';

// AUTH related methods
import { postAuth } from '../../../helpers/authUtils';

// Is user register successful then direct plot user in redux.
function* registerUser({ payload: { user, navigate } }) {
    try {
        yield call(postAuth, '/register', user);
        navigate('/login');
    } catch (error) {
        yield put(apiRegisterError(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;