import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from "reactstrap";

import { withRouter } from '../../../helpers/funcUtils';

import TopBar from "./TopBar";
import Navbar from "./Navbar";
import Footer from "./Footer";

import Breadcrumb from "../../Common/breadcrumb";

class LayoutV extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if(this.props.isPreloader === true) {
                document.getElementById('preloader').style.display = "block";
                document.getElementById('status').style.display = "block";

                setTimeout(function() {
                    document.getElementById('preloader').style.display = "none";
                    document.getElementById('status').style.display = "none";

                }, 2500);
            } else {
                document.getElementById('preloader').style.display = "none";
                document.getElementById('status').style.display = "none";
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let currentPage = this.capitalizeFirstLetter(this.props.router.location.pathname);

        if (this.props.router.location.pathname.match(/all-urls/)) {
            currentPage = 'All Urls';
        } else if (this.props.router.location.pathname.match(/urls\/create/)) {
            currentPage = 'Create Url';
        } else if (this.props.router.location.pathname.match(/urls\/(\d+)/)) {
            currentPage = 'Edit Url';
        } else if (this.props.router.location.pathname.match(/urls\/(\d+)\/statistics/)) {
            currentPage = 'Url Statistics';
        } else if (this.props.router.location.pathname.match(/users\/(\d+)\/urls/)) {
            currentPage = 'User Urls';
        } else if (this.props.router.location.pathname.match(/users\/(\d+)/)) {
            currentPage = 'Edit User';
        } else if (this.props.router.location.pathname.match(/domains\/(\d+)\/urls/)) {
            currentPage = 'Domain Urls';
        } else if (this.props.router.location.pathname.match(/domains\/(\d+)/)) {
            currentPage = 'Edit Domain';
        } else if (this.props.router.location.pathname.match(/domains\/create/)) {
            currentPage = 'Create Domain';
        } else if (this.props.router.location.pathname.match(/domains/)) {
            currentPage = 'All Domains';
        } else if (this.props.router.location.pathname.match(/services\/(\d+)/)) {
            currentPage = 'Edit Service';
        } else if (this.props.router.location.pathname.match(/services/)) {
            currentPage = 'Services';
        } else if (this.props.router.location.pathname.match(/socials\/(\d+)/)) {
            currentPage = 'Edit Social';
        } else if (this.props.router.location.pathname.match(/socials/)) {
            currentPage = 'Socials';
        } else if (this.props.router.location.pathname.match(/visitors/)) {
            currentPage = 'All Visitors';
        } else if (this.props.router.location.pathname.match(/acquisition/)) {
            currentPage = 'Acquisition';
        } else if (this.props.router.location.pathname.match(/browser-os/)) {
            currentPage = 'Browser & OS';
        } else if (this.props.router.location.pathname.match(/mobile-device/)) {
            currentPage = 'Mobile Device';
        } else if (this.props.router.location.pathname.match(/top-ips\/(\d+)/)) {
            currentPage = 'Top IPs of URL';
        } else if (this.props.router.location.pathname.match(/top-ips/)) {
            currentPage = 'Top IPs';
        } else if (this.props.router.location.pathname.match(/visitors-per-day/)) {
            currentPage = 'Visitors Per Day';
        } else if (this.props.router.location.pathname.match(/geo\/language\/(\d+)/)) {
            currentPage = 'Url Language';
        } else if (this.props.router.location.pathname.match(/geo\/language/)) {
            currentPage = 'Language';
        } else if (this.props.router.location.pathname.match(/geo\/location\/(\d+)/)) {
            currentPage = 'Url Location';
        } else if (this.props.router.location.pathname.match(/geo\/location/)) {
            currentPage = 'Location';
        } else if (this.props.router.location.pathname.match(/new-vs-return/)) {
            currentPage = 'New vs Returning';
        } else if (this.props.router.location.pathname.match(/visitor-explorer/)) {
            currentPage = 'Visitor Explorer';
        }

        document.title = currentPage + " | Tilk - Tiny Links";
    }

    render() {
        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                <div id="layout-wrapper">
                    <div className="layout-bg-image"></div>
                    <div className="layout-bg-color"></div>

                    {/* render topbar */}
                    <TopBar/>

                    {/* render navbar */}
                    <Navbar/>

                    <div className="main-content">
                        <div className="page-content">
                            <Container fluid>
                                <Breadcrumb/>
                                {this.props.children}
                            </Container>

                            {/* render Footer */}
                            <Footer/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const Layout = state.Layout;
    return {
        isPreloader: Layout.isPreloader
    };
};

export default withRouter(connect(mapStateToProps, null)(LayoutV));