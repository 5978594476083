import React, { Component } from 'react';
import { GoogleMap, Circle } from '@react-google-maps/api';

class GoogleStateMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            map: null
        }
    }

    render() {
        return (
            <GoogleMap
                mapContainerClassName="w-full h-full"
                defaultCenter={{ lat: 40.72, lng: -73.9595798 }}
                options={{
                    scrollwheel: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                }}>
                <Circle
                    center={{ lat: 27.672932021393862, lng: 85.31184012689732 }}
                />
            </GoogleMap>
        )
    }
}

export default GoogleStateMap;