import { Component } from 'react';
import {
    Row,
    Col,
    Label,
} from "reactstrap";

import { connect } from "react-redux";

// Import Components
import { checkGuest } from '../../store/actions';
import { postApi } from "../../helpers/apiUtils";
import { getLoggedInUser, getRegisteredInGuestSession } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class ShortUrlView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            load: false,
            url: null,
        }
    }

    componentDidMount() {
        if (!this.state.load) {
            if (!this.state.user) {
                this.props.checkGuest(this.state.guest_session);

                setTimeout(() => {
                    this.checkUrl();
                }, 500);
            } else {
                this.checkUrl();
            }
        }
    }

    checkUrl = () => {
        if (this.props.router.location.pathname) {
            postApi('/urls/check', { url: window.location.href }).then(
                (response) => {
                    if (response.status === 'success') {
                        if (response.url.type === 'REDIRECT') {
                            if (response.url.redirection) {
                                window.location.href = response.url.redirection;
                            } else {
                                this.props.router.navigate('/');
                            }
                        } else {
                            document.title = response.url.title + " | TinyLink";

                            this.setState({
                                ...this.state,
                                load: true,
                                url: response.url
                            });
                        }
                    } else {
                        this.props.router.navigate('/');
                    }
                },
                (error) => {
                    this.props.router.navigate('/');
                }
            )
        }
    }

    render() {
        const { url } = this.state;

        return (
            <Row>
                <Col xs="12">
                {
                    url && (
                        <>
                            {
                                url.type === 'SONG' && (
                                    <div className="url-container">
                                        {
                                            url.background && (
                                                <div className="url-bg-container">
                                                    <img className="url-bg-img" src={`/uploads/${ url.background }`} alt="BackgroundImage" />
                                                </div>
                                            )
                                        }
                                        <div className="url-content">
                                            <img className="url-artwork" src={`/uploads/${ url.cover_image }`} alt="URLArtwork" />
                                            <h1 className="url-title">{ url.title }</h1>
                                            <div className="url-list-container">
                                                <div className="url-list">
                                                    {
                                                        !!url.services && !!url.services.length && url.services.map((url_service) => (
                                                            <div key={ url_service.id } className="url-item-container" style={{ backgroundColor: url.button_background }}>
                                                                {
                                                                    url_service.service.icon_type === 'ICON_ONLY' && (
                                                                        <img className="url-item-logo" src={ url_service.service.icon } alt="ItemLogo" />
                                                                    )
                                                                }
                                                                {
                                                                    url_service.service.icon_type === 'ICON_NAME' && (
                                                                        <img className="url-item-logo" src={ url_service.service.icon_with_name } alt="ItemLogo" />
                                                                    )
                                                                }
                                                                {
                                                                    url_service.service.icon_type === 'ICON_TEXT' && (
                                                                        <>
                                                                            <img className="url-item-logo" src={ url_service.service.icon_with_text } alt="ItemLogo" />
                                                                            <Label className="music-link__logo_text" style={{ color: url.button_text }}>{ url_service.service.icon_text }</Label>
                                                                        </>
                                                                    )
                                                                }
                                                                <button className="url-item-button">
                                                                    <a href={ url_service.link }>Listen</a>
                                                                </button>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                url.type === 'SOCIAL' && (
                                    <div className="url-container">
                                        {
                                            url.background && (
                                                <div className="url-bg-container">
                                                    <img className="url-bg-img" src={`/uploads/${ url.background }`} alt="BackgroundImage" />
                                                </div>
                                            )
                                        }
                                        <div className="url-content">
                                            <img className="url-artwork" src={`/uploads/${ url.background }`} alt="URLArtwork" />
                                            <h1 className="url-title">{ url.title }</h1>
                                            <div className="url-list-container">
                                                <div className="url-list">
                                                    {
                                                        !!url.socials && !!url.socials.length && url.socials.map((url_social) => (
                                                            <a key={ url_social.id } href={ url_social.link } className="url-item-container" style={{ backgroundColor: url.button_background }}>
                                                                {
                                                                    url_social.social.icon_type === 'ICON_ONLY' && (
                                                                        <img className="url-item-logo" src={ url_social.social.icon } alt="ItemLogo" />
                                                                    )
                                                                }
                                                                {
                                                                    url_social.social.icon_type === 'ICON_NAME' && (
                                                                        <img className="url-item-logo" src={ url_social.social.icon_with_name } alt="ItemLogo" />
                                                                    )
                                                                }
                                                                {
                                                                    url_social.social.icon_type === 'ICON_TEXT' && (
                                                                        <>
                                                                            <img className="url-item-logo" src={ url_social.social.icon_with_text } alt="ItemLogo" />
                                                                            <Label className="music-link__logo_text" style={{ color: url.button_text }}>{ url_social.social.icon_text }</Label>
                                                                        </>
                                                                    )
                                                                }
                                                                <Label className="url-item-text" style={{ color: url.button_text }}>{ url_social.display_link }</Label>
                                                            </a>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                url.type === 'ALBUM' && url.urls.length && url.urls.map((url_url) => (
                                    <div key={ url_url.id }>
                                        {
                                            url_url.type === 'SONG' && (
                                                <div className="url-container">
                                                    {
                                                        url_url.background && (
                                                            <div className="url-bg-container">
                                                                <img className="url-bg-img" src={ url_url.background } alt="BackgroundImage" />
                                                            </div>
                                                        )
                                                    }
                                                    <div className="url-content">
                                                        <img className="url-artwork" src={ url_url.cover_image } alt="CoverImage" />
                                                        <h1 className="url-title">{ url_url.title }</h1>
                                                        <div className="url-list-container">
                                                            <div className="url-list">
                                                                {
                                                                    !!url_url.services && !!url_url.services.length && url_url.services.map((url_service) => (
                                                                        <div key={ url_service.id } className="url-item-container" style={{ backgroundColor: url.button_background }}>
                                                                            {
                                                                                url_service.service.icon_type === 'ICON_ONLY' && (
                                                                                    <img className="url-item-logo" src={ url_service.service.icon } alt="ItemLogo" />
                                                                                )
                                                                            }
                                                                            {
                                                                                url_service.service.icon_type === 'ICON_NAME' && (
                                                                                    <img className="url-item-logo" src={ url_service.service.icon_with_name } alt="ItemLogo" />
                                                                                )
                                                                            }
                                                                            {
                                                                                url_service.service.icon_type === 'ICON_TEXT' && (
                                                                                    <>
                                                                                        <img className="url-item-logo" src={ url_service.service.icon_with_text } alt="ItemLogo" />
                                                                                        <Label className="music-link__logo_text" style={{ color: url.button_text }}>{ url_service.service.icon_text }</Label>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <button className="url-item-button">
                                                                                <a href={ url_service.link }>Listen</a>
                                                                            </button>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            url_url.type === 'SOCIAL' && (
                                                <div className="url-container">
                                                    {
                                                        url_url.background && (
                                                            <div className="url-bg-container">
                                                                <img className="url-bg-img" src={ url_url.background } alt="BackgroundImage" />
                                                            </div>
                                                        )
                                                    }
                                                    <div className="url-content">
                                                        <h1 className="url-title">{ url_url.title }</h1>
                                                        <div className="url-list-container">
                                                            <div className="url-list">
                                                                {
                                                                    !!url_url.socials && !!url_url.socials.length && url_url.socials.map((url_social) => (
                                                                        <a key={ url_social.id } href={ url_social.link } className="url-item-container" style={{ backgroundColor: url_url.button_background }}>
                                                                            {
                                                                                url_social.social.icon_type === 'ICON_ONLY' && (
                                                                                    <img className="url-item-logo" src={ url_social.social.icon } alt="ItemLogo" />
                                                                                )
                                                                            }
                                                                            {
                                                                                url_social.social.icon_type === 'ICON_NAME' && (
                                                                                    <img className="url-item-logo" src={ url_social.social.icon_with_name } alt="ItemLogo" />
                                                                                )
                                                                            }
                                                                            {
                                                                                url_social.social.icon_type === 'ICON_TEXT' && (
                                                                                    <>
                                                                                        <img className="url-item-logo" src={ url_social.social.icon_with_text } alt="ItemLogo" />
                                                                                        <Label className="music-link__logo_text">{ url_social.social.icon_text }</Label>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <Label className="url-item-text" style={{ color: url_url.button_text }}>{ url_social.display_link }</Label>
                                                                        </a>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </>
                    )
                }
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    let { guest_session } = state.Guest;
    if (!user) {
        if (!guest_session) {
            guest_session = getRegisteredInGuestSession();
        }
    }
    return { user, guest_session };
}

export default withRouter(connect(mapStateToProps, {
    checkGuest,
    postApi,
})(ShortUrlView));