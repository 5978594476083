import React, { Component } from 'react';
import iro from '@jaames/iro';

class ColorTransparentPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: ''
        }
    }

    componentDidMount() {
        this.setState({ color: this.props.color });

        this.leftColorPicker = new iro.ColorPicker(this.leftEl, { color: this.props.color, layoutDirection: 'horizontal' });
        this.leftColorPicker.on('color:change', (color) => {
            this.setState({ color: color.hex8String });
            if (this.props.onColorChange) this.props.onColorChange(color.hex8String);
        });

        this.rightColorPicker = new iro.ColorPicker(this.rightEl, {
            color: this.props.color,
            layout: [
                { component: iro.ui.Slider, options: { sliderType: 'hue' } },
                { component: iro.ui.Slider, options: { sliderType: 'saturation' } },
                { component: iro.ui.Slider, options: { sliderType: 'value' } },
                { component: iro.ui.Slider, options: { sliderType: 'alpha' } }
            ],
            layoutDirection: 'horizontal'
        });
        this.rightColorPicker.on('color:change', (color) => {
            this.setState({ color: color.hex8String });
            if (this.props.onColorChange) this.props.onColorChange(color.hex8String);
        });
    }

    componentDidUpdate() {
        if (this.state.color) this.leftColorPicker.color.set(this.state.color);
        this.leftColorPicker.setState(this.props.colorPickerState);
        if (this.state.color) this.rightColorPicker.color.set(this.state.color);
        this.rightColorPicker.setState(this.props.colorPickerState);
    }

    render() {
        return (
            <div className="color-transparent-picker">
                <div className="picker-color" style={{ background: this.state.color }}></div>
                <div className="picker-wrapper">
                    <div className="left-picker">
                        <div ref={el => this.leftEl = el} />
                    </div>
                    <div className="right-picker">
                        <div ref={el => this.rightEl = el} />
                    </div>
                    <div className="picker-bar">
                        <div className="picker-circle"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ColorTransparentPicker;