import React, { Component } from 'react';
import { Container } from "reactstrap";

import { withRouter } from '../../helpers/funcUtils';

import TopMenu from "./TopMenu";

class LayoutF extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount() {
        let currentPage = this.capitalizeFirstLetter(this.props.router.location.pathname);

        if (this.props.router.location.pathname === '' || this.props.router.location.pathname === '/') {
            currentPage = 'URL Shortener - Short URLs & Custom Free Link Shortener';
        }

        document.title = currentPage + " | Tilk - Tiny Links";
    }

    render() {
        return <React.Fragment>
            <div id="layout-wrapper">
                <div className="layout-bg-image"></div>
                <div className="layout-bg-color"></div>

                <TopMenu/>

                <div className="main-content">
                    <div className="page-content">
                        <Container fluid>
                            { this.props.children }
                        </Container>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
}

export default (withRouter(LayoutF));