import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert
} from "reactstrap";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";
import { getApi, postApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class ServiceEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems: this.props.router.params.serviceId ? [
                { title: "Dashboard", link: "/dashboard" },
                { title: "Services", link: "/services" },
                { title: "Edit Service", link: "#" },
            ] : [
                { title: "Dashboard", link: "/dashboard" },
                { title: "Services", link: "/services" },
                { title: "Create Service", link: "#" },
            ],
            iconTypes: [
                { 'value': 'ICON_ONLY', 'label': 'Icon only' },
                { 'value': 'ICON_NAME', 'label': 'Icon with name' },
                { 'value': 'ICON_TEXT', 'label': 'Icon with text' },
            ],
            formService: {
                id: 0,
                name: '',
                link: '',
                icon_type: 'ICON_ONLY',
                icon: null,
                icon_with_name: null,
                icon_with_text: null,
                icon_text: '',
                status: 'ACTIVE'
            },
            errors: null,
            user: this.props.user,
            successAlert: '',
            errorAlert: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        if (this.state.formService.icon) formData.append("icon", this.state.formService.icon);
        if (this.state.formService.icon_with_name) formData.append("icon_with_name", this.state.formService.icon_with_name);
        if (this.state.formService.icon_with_text) formData.append("icon_with_text", this.state.formService.icon_with_text);
        formData.append("name", this.state.formService.name);
        formData.append("link", this.state.formService.link);
        formData.append("icon_type", this.state.formService.icon_type);
        formData.append("icon_text", this.state.formService.icon_text ? this.state.formService.icon_text : '');
        formData.append("status", this.state.formService.status);

        postApi('/services/' + (this.state.formService.id ? this.state.formService.id : ''), formData).then(
            (response) => {
                if (response.status === 'success') {
                    this.setState({
                        successAlert: response.message,
                        errorAlert: '',
                        formService: {
                            id: response.service.id,
                            name: response.service.name,
                            link: response.service.link,
                            icon_type: response.service.icon_type,
                            icon: response.service.icon,
                            icon_with_name: response.service.icon_with_name,
                            icon_with_text: response.service.icon_with_text,
                            icon_text: response.service.icon_text,
                            status: response.service.status
                        },
                        breadcrumbItems: [
                            { title: "Dashboard", link: "/dashboard" },
                            { title: "Services", link: "/services" },
                            { title: "Edit Service", link: "#" },
                        ]
                    });
                    this.props.setBreadcrumbItems("Edit Service", this.state.breadcrumbItems);
                } else {
                    this.setState({
                        successAlert: '',
                        errorAlert: response.message
                    });
                }
            },
            (error) => {
                this.setState({
                    successAlert: '',
                    errorAlert: error
                });
            }
        )
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let name_value = this.state.formService.name,
            link_value = this.state.formService.link;
        if (name === 'name') {
            name_value = value;
        }
        if (!name_value) {
            if (!form_errors) {
                form_errors = { name: 'Please enter your name' };
            } else {
                form_errors['name'] = 'Please enter your name';
            }
        }
        if (name === 'link') {
            link_value = value;
        }
        if (!link_value) {
            if (!form_errors) {
                form_errors = { link: 'Please enter your link' };
            } else {
                form_errors['link'] = 'Please enter your link';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value, files } = event.target;
        if (name === 'icon_with_text') {
            this.setState(prevState => ({
                ...prevState,
                formService: {
                    ...prevState.formService,
                    icon_with_text: files[0],
                },
                errors: this.validateFormErrors(name, value),
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                formService: {
                    ...prevState.formService,
                    [name]: value,
                },
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role !== 'ADMIN' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        if (this.props.router.params.serviceId) {
            this.props.setBreadcrumbItems("Edit Service", this.state.breadcrumbItems);

            getApi('/services/' + this.props.router.params.serviceId).then(
                (response) => {
                    if (response.status === 'success') {
                        this.setState({
                            formService: {
                                id: response.service.id,
                                name: response.service.name,
                                link: response.service.link,
                                icon_type: response.service.icon_type,
                                icon: response.service.icon,
                                icon_with_name: response.service.icon_with_name,
                                icon_with_text: response.service.icon_with_text,
                                icon_text: response.service.icon_text,
                                status: response.service.status
                            }
                        });
                    } else {
                        this.setState({
                            formService: {
                                id: 0,
                                name: '',
                                link: '',
                                icon_type: 'ICON_ONLY',
                                icon: null,
                                icon_with_name: null,
                                icon_with_text: null,
                                icon_text: '',
                                status: 'ACTIVE'
                            }
                        });
                    }
                },
                (error) => {
                    this.setState({
                        formService: {
                            id: 0,
                            name: '',
                            link: '',
                            icon_type: 'ICON_ONLY',
                            icon: null,
                            icon_with_name: null,
                            icon_with_text: null,
                            icon_text: '',
                            status: 'ACTIVE'
                        }
                    });
                }
            )
        } else {
            this.props.setBreadcrumbItems("Create Service", this.state.breadcrumbItems);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { iconTypes, formService, successAlert, errorAlert } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                { successAlert && <Alert color="success">{successAlert}</Alert> }

                                { errorAlert && <Alert color="danger">{errorAlert}</Alert> }

                                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <input
                                            type="text"
                                            name="name"
                                            className={ `form-control mb-1 ${this.state.errors && 'name' in this.state.errors ? 'error' : ''}` }
                                            placeholder="Enter name"
                                            value={ formService.name }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "name" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.name }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Link</Label>
                                        <input
                                            type="text"
                                            name="link"
                                            className={ `form-control mb-1 ${this.state.errors && 'link' in this.state.errors ? 'error' : ''}` }
                                            placeholder="Enter link"
                                            value={ formService.link }
                                            onChange={ this.handleChangeField } />
                                        {
                                            this.state.errors && "link" in this.state.errors &&
                                            <span className="error-message text-danger small">{ this.state.errors.link }</span>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Icon Type</Label>
                                        <select
                                            className="form-control"
                                            name="icon_type"
                                            onChange={ this.handleChangeField }
                                        >
                                            {
                                                iconTypes.map((iconType, i) =>
                                                    <option key={i} value={iconType['value']}>{iconType['label']}</option>
                                                )
                                            }
                                        </select>
                                    </FormGroup>
                                    {
                                        formService.icon_type === 'ICON_ONLY' &&
                                        <FormGroup>
                                            <Label>Icon</Label>
                                            <input
                                                type="file"
                                                name="icon"
                                                className={ `form-control mb-1 ${this.state.errors && 'icon' in this.state.errors ? 'error' : ''}` }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon }</span>
                                            }
                                            {
                                                formService.icon &&
                                                <img src={formService.icon} alt={ formService.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formService.icon_type === 'ICON_NAME' &&
                                        <FormGroup>
                                            <Label>Icon with name</Label>
                                            <input
                                                type="file"
                                                name="icon_with_name"
                                                className={ `form-control mb-1 ${this.state.errors && 'icon_with_name' in this.state.errors ? 'error' : ''}` }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon_with_name" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon_with_name }</span>
                                            }
                                            {
                                                formService.icon_with_name &&
                                                <img src={formService.icon_with_name} alt={ formService.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formService.icon_type === 'ICON_TEXT' &&
                                        <FormGroup>
                                            <Label>Icon</Label>
                                            <input
                                                type="text"
                                                name="icon_with_text"
                                                className={ `form-control mb-1 ${this.state.errors && 'icon_with_text' in this.state.errors ? 'error' : ''}` }
                                                value={ formService.icon_with_text }
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon_with_text" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon_with_text }</span>
                                            }
                                            {
                                                formService.icon_with_text &&
                                                <img src={formService.icon_with_text} alt={ formService.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    {
                                        formService.icon_type === 'ICON_TEXT' &&
                                        <FormGroup>
                                            <Label>Text with Icon</Label>
                                            <input
                                                type="file"
                                                name="icon_text"
                                                className={ `form-control mb-1 ${this.state.errors && 'icon_text' in this.state.errors ? 'error' : ''}` }
                                                placeholder="Enter Icon Text"
                                                onChange={ this.handleChangeField } />
                                            {
                                                this.state.errors && "icon_text" in this.state.errors &&
                                                <span className="error-message text-danger small">{ this.state.errors.icon_text }</span>
                                            }
                                            {
                                                formService.icon_text &&
                                                <img src={formService.icon_text} alt={ formService.name } className="img-fluid mwp-200"/>
                                            }
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <select
                                            className="form-control"
                                            name="status"
                                            value={ formService.status }
                                            onChange={ this.handleChangeField }
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="DISABLE">DISABLE</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color="primary" className="w-md waves-effect waves-light mr-1" type="submit">{formService.id ? 'Update' : 'Create'}</Button>
                                        <button className="btn p-0" type="button"><a className="btn btn-danger w-md waves-effect waves-light" href={`/services`}>Cancel</a></button>
                                    </FormGroup>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi,
    postApi
})(ServiceEdit));