import {
    CHECK_GUEST,
    REGISTER_GUEST_SUCCESS,
    GUEST_API_FAILED,
    GUEST_ERROR_CLEAR
} from './actionTypes';

export const checkGuest = (guest_session) => {
    return {
        type: CHECK_GUEST,
        payload: { guest_session }
    }
}

export const registerGuestSuccess = (guest_session) => {
    return {
        type: REGISTER_GUEST_SUCCESS,
        payload: guest_session
    }
}

export const apiGuestError = (error) => {
    return {
        type: GUEST_API_FAILED,
        payload: error
    }
}

export const clearErrorGuest = () => {
    return {
        type: GUEST_ERROR_CLEAR
    }
}