import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import {
    toggleSidebar,
    hideRightSidebar,
    changeTopBarTheme,
    changeLayout,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader
} from '../../../store/actions';

// Metis Menu
import MetisMenu from "metismenujs";

import { getLoggedInUser } from "../../../helpers/authUtils";
import { withRouter } from '../../../helpers/funcUtils';

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_toggle: false,
            layoutType: this.props.layoutType,
            topBarTheme: this.props.topbarTheme,
            leftSideBarTheme: this.props.leftSideBarTheme,
            layoutWidth: this.props.layoutWidth,
            sidebarType: this.props.leftSideBarType,
            isPreloader: this.props.isPreloader,
            user: this.props.user
        }
        this.changeLayout = this.changeLayout.bind(this);

        this.changeTopBarTheme = this.changeTopBarTheme.bind(this);
        this.changeLeftSidebarTheme = this.changeLeftSidebarTheme.bind(this);
        this.changeLayoutWidth = this.changeLayoutWidth.bind(this);
        this.changeLeftSidebarType = this.changeLeftSidebarType.bind(this);
        this.changeThemePreloader = this.changeThemePreloader.bind(this);
    }

    componentDidMount() {
        document.body.setAttribute('data-sidebar','dark');
        this.initMenu();
    }

    //update local state after changing layout
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                topBarTheme: this.props.topbarTheme,
                leftSideBarTheme: this.props.leftSideBarTheme,
                layoutWidth: this.props.layoutWidth,
                sidebarType: this.props.leftSideBarType,
                isPreloader: this.props.isPreloader,
                user: this.props.user
            });
        }

        if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        let matchingMenuItem = null;
        let ul = document.getElementById("side-menu");
        let items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (!items[i].classList.contains('has-arrow') && this.props.router.location.pathname.indexOf(items[i].pathname) !== -1) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('mm-active'); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add('mm-active');
                    }
                }
            }

            return false;
        }

        return false;
    }

    //change layput type and dispatch action
    changeLayout() {
        this.props.changeLayout("horizontal");
    }

    //theme preloader
    changeThemePreloader = (value) => {
        this.props.changePreloader(!this.props.isPreloader);
    }

    //change left sidebar theme
    changeLeftSidebarTheme(value) {
        this.props.changeSidebarTheme(value);
    }

    //change layout width
    changeLayoutWidth(value) {
        if(this.state.layoutWidth === "boxed")
            this.props.changeLayoutWidth("fluid", this.state.layoutType);
        else
            this.props.changeLayoutWidth("boxed", this.state.layoutType);
    }

    //change topbar theme and dispatch action
    changeTopBarTheme(value) {
        this.props.changeTopBarTheme(value);
    }

    //change sidebar type
    changeLeftSidebarType(value) {
        this.props.changeSidebarType(value);
    }

    render() {
        const { user } = this.state;

        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Main</li>
                        {(user.role === 'PLUS' || user.role === 'ADMIN') && (
                            <li>
                                <Link to="/dashboard" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-view-dashboard"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to="/overview" reloadDocument={true} className="waves-effect">
                                <i className="mdi mdi-home"></i>
                                <span>Overview</span>
                            </Link>
                        </li>

                        {user.role !== 'FREE' && (
                            <li className="menu-title">Realtime</li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/realtime" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-home"></i>
                                    <span>Overview</span>
                                </Link>
                            </li>
                        )}

                        {user.role !== 'FREE' && (
                            <li className="menu-title">Audience</li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/audience" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-home"></i>
                                    <span>Overview</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/visitor-explorer" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-account-network"></i>
                                    <span>Visitor Explorer</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="#" reloadDocument={true} className="waves-effect has-arrow">
                                    <i className="mdi mdi-crosshairs-gps"></i>
                                    <span>GEO</span>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to="/geo/language" reloadDocument={true} className="waves-effect">
                                            <i className="mdi mdi-web"></i>
                                            <span>Language</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/geo/location" reloadDocument={true} className="waves-effect">
                                            <i className="mdi mdi-map-marker"></i>
                                            <span>Location</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/new-vs-return" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-shape-circle-plus"></i>
                                    <span>New vs Returning</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/browser-os" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-broom"></i>
                                    <span>Browser & OS</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/mobile-device" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-mobile"></i>
                                    <span>Mobile Device</span>
                                </Link>
                            </li>
                        )}

                        {user.role !== 'FREE' && (
                            <li className="menu-title">Acquisition</li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/acquisition" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-window-maximize"></i>
                                    <span>Overview</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/top-ips" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-network-wired"></i>
                                    <span>Top IPs</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/visitors-per-day" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-chart-area"></i>
                                    <span>Visitors Per Day</span>
                                </Link>
                            </li>
                        )}

                        <li className="menu-title">Management</li>
                        <li>
                            <Link to="/urls" reloadDocument={true} className="waves-effect">
                                <i className="mdi mdi-link"></i>
                                <span>Shorten Urls</span>
                            </Link>
                        </li>
                        {(user.role === 'PLUS' || user.role === 'ADMIN') && (
                            <li>
                                <Link to="/all-urls" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-link-box-outline"></i>
                                    <span>All Shorten Urls</span>
                                </Link>
                            </li>
                        )}
                        {user.role !== 'FREE' && (
                            <li>
                                <Link to="/visitors" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-user"></i>
                                    <span>All Visitors</span>
                                </Link>
                            </li>
                        )}
                        {(user.role === 'PLUS' || user.role === 'ADMIN') && (
                            <li>
                                <Link to="/domains" reloadDocument={true} className="waves-effect">
                                    <i className="fas fa-window-restore"></i>
                                    <span>All Domains</span>
                                </Link>
                            </li>
                        )}
                        {(user.role === 'ADMIN') && (
                            <li>
                                <Link to="/services" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-server"></i>
                                    <span>All Services</span>
                                </Link>
                            </li>
                        )}
                        {(user.role === 'ADMIN') && (
                            <li>
                                <Link to="/socials" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-beats"></i>
                                    <span>All Socials</span>
                                </Link>
                            </li>
                        )}
                        {user.role === 'ADMIN' && (
                            <li>
                                <Link to="/users" reloadDocument={true} className="waves-effect">
                                    <i className="mdi mdi-account-multiple"></i>
                                    <span>Users</span>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topBarTheme, isPreloader } = state.Layout;
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topBarTheme, isPreloader, user };
}

export default withRouter(connect(mapStateToProps, {
    toggleSidebar,
    hideRightSidebar,
    changeLayout,
    changeTopBarTheme,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader
})(SidebarContent));