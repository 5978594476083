import React, { Component } from 'react';
import { Container, Row, Col, Alert,Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { registerUser, clearRegisterError } from '../../store/actions';
import logo_dark from "../../assets/images/logo-dark.png";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            password: "",
            confirm_password: "",
            errors: null,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFormErrors = this.validateFormErrors.bind(this);
        this.handleChangeField = this.handleChangeField.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.props.registerUser({
            email: this.state.email,
            name: this.state.name,
            password: this.state.password
        }, this.props.router.navigate);
    }

    validateFormErrors(name, value) {
        let form_errors = null;
        let email_value = this.state.email, name_value = this.state.name, password_value = this.state.password, confirm_password_value = this.state.confirm_password;
        if (name === 'email') { email_value = value; }
        if (!email_value) {
            if (!form_errors) {
                form_errors = { email: 'Please enter your email' };
            } else {
                form_errors['email'] = 'Please enter your email';
            }
        }
        if (name === 'name') { name_value = value; }
        if (!name_value) {
            if (!form_errors) {
                form_errors = { name: 'Please enter your name' };
            } else {
                form_errors['name'] = 'Please enter your name';
            }
        }
        if (name === 'password') { password_value = value; }
        if (!password_value) {
            if (!form_errors) {
                form_errors = { password: 'Please enter your password' };
            } else {
                form_errors['password'] = 'Please enter your password';
            }
        }
        if (name === 'confirm_password') { confirm_password_value = value; }
        if (!confirm_password_value) {
            if (!form_errors) {
                form_errors = { confirm_password: 'Please enter value to match password' };
            } else {
                form_errors['confirm_password'] = 'Please enter value to match password';
            }
        }
        return form_errors;
    }

    handleChangeField(event) {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'email') {
            this.setState(prevState => ({
                ...prevState,
                email: value,
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'name') {
            this.setState(prevState => ({
                ...prevState,
                name: value,
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'password') {
            this.setState(prevState => ({
                ...prevState,
                password: value,
                errors: this.validateFormErrors(name, value),
            }));
        } else if (name === 'confirm_password') {
            this.setState(prevState => ({
                ...prevState,
                confirm_password: value,
                errors: this.validateFormErrors(name, value),
            }));
        }
    }

    componentDidMount()
    {
        this.props.clearRegisterError();
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="/" className="logo logo-admin"><img src={logo_dark} height="30" alt="logo"/></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-1 text-center">Register</h4>
                                            {
                                                this.props.user &&
                                                <Alert color="success">Registration Done successfully.</Alert>
                                            }
                                            {
                                                this.props.registrationError &&
                                                <Alert color="danger">{ this.props.registrationError }</Alert>
                                            }

                                            <form onSubmit={this.handleSubmit} className="form-horizontal mt-4">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className={ `form-control mb-1 ${this.state.errors && 'email' in this.state.errors ? 'error' : ''}` }
                                                        placeholder="Enter email"
                                                        onChange={ this.handleChangeField } />
                                                    {
                                                        this.state.errors && "email" in this.state.errors &&
                                                        <span className="error-message text-danger small">{ this.state.errors.email }</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="name">Username</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className={ `form-control mb-1 ${this.state.errors && 'name' in this.state.errors ? 'error' : ''}` }
                                                        placeholder="Enter name"
                                                        onChange={ this.handleChangeField } />
                                                    {
                                                        this.state.errors && "name" in this.state.errors &&
                                                        <span className="error-message text-danger small">{ this.state.errors.name }</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className={ `form-control mb-1 ${this.state.errors && 'password' in this.state.errors ? 'error' : ''}` }
                                                        placeholder="Enter password"
                                                        onChange={ this.handleChangeField } />
                                                    {
                                                        this.state.errors && "password" in this.state.errors &&
                                                        <span className="error-message text-danger small">{ this.state.errors.password }</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        name="confirm_password"
                                                        className={ `form-control mb-1 ${this.state.errors && 'confirm_password' in this.state.errors ? 'error' : ''}` }
                                                        placeholder="Enter confirm password"
                                                        onChange={ this.handleChangeField } />
                                                    {
                                                        this.state.errors && "confirm_password" in this.state.errors &&
                                                        <span className="error-message text-danger small">{ this.state.errors.confirm_password }</span>
                                                    }
                                                </div>
                                                <div className="form-group row mt-4">
                                                    <Col xs="12" className="text-right">
                                                        <Button  color="primary" className="btn btn-primary w-md waves-effect waves-light" type="submit">Register</Button>
                                                    </Col>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center text-shadow">
                                    <p>Already have an account ? <Link to="/login" className="text-primary"> Login </Link></p>
                                    <p>© {new Date().getFullYear()} Tilk.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
}

export default connect(mapStateToProps, { registerUser, clearRegisterError })(Register);