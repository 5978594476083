import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import { toggleSidebar, changeSidebarType } from '../../store/actions';

import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

//Import Images
import logo_v1 from "../../assets/images/logo-v1.svg";
// import logo_sm from "../../assets/images/logo-sm.png";
// import logo_dark from "../../assets/images/logo-dark.png";
// import logo_light from "../../assets/images/logo-light.png";

class TopMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            menuOpen: false,
            menuCollapseClassName: '',
            user: this.props.user
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    initMenu() {
        let matchingMenuItem = null;
        let ul = document.getElementById("page-top-menu");
        let items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (this.props.router.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add('active');
        const parent = item.parentElement;

        if (parent) {
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add('active');
                    }
                }
            }
            return false;
        }
        return false;
    }

    menuToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    dropdownToggle = (e) => {
        let dropdown = e.target.closest('.nav-item').querySelector('.dropdown-menu');
        let dropdownIsOpen = dropdown.classList.contains('show');
        let ul = document.getElementById("page-top-menu");
        let items = ul.getElementsByTagName("ul.dropdown-menu");
        for (let i = 0; i < items.length; ++i) {
            items[i].classList.remove('show');
        }
        if (dropdownIsOpen) {
            dropdown.classList.remove('show');
        } else {
            dropdown.classList.add('show');
        }
    }

    render() {
        let { menuOpen, user } = this.state;

        return (
            <React.Fragment>
                <header id="page-top-menu">
                    <Container>
                        <div className="navbar-header navbar-expand-lg">
                            <div className="navbar-brand-box">
                                <Link to="https://ti.lk" reloadDocument={true} className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logo_v1} alt="" height="22"/>
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logo_v1} alt="" height="30"/>
                                    </span>
                                </Link>

                                <Link to="/" reloadDocument={true} className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logo_v1} alt="" height="22"/>
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logo_v1} alt="" height="30"/>
                                    </span>
                                </Link>
                            </div>

                            <button className="navbar-toggler ms-auto" type="button" onClick={ this.menuToggle }>
                                <i className="mdi mdi-menu"></i>
                            </button>

                            <div className={'justify-content-end navbar-collapse collapse' + (menuOpen ? ' show' : '')}>
                                <ul className="navbar-nav navbar-nav-scroll ms-auto">
                                    {
                                        !user &&
                                        <li className="nav-item dropdown">
                                            <Link to="/login" reloadDocument={true} className="nav-link">Login</Link>
                                        </li>
                                    }
                                    {
                                        !user &&
                                        <li className="nav-item dropdown">
                                            <Link to="/register" reloadDocument={true} className="nav-link">Register</Link>
                                        </li>
                                    }
                                    {
                                        user &&
                                        <li className="nav-item dropdown">
                                            <Link to="/dashboard" reloadDocument={true} className="nav-link">Dashboard</Link>
                                        </li>
                                    }
                                    {
                                        user &&
                                        <li className="nav-item dropdown">
                                            <Link to="/logout" reloadDocument={true} className="nav-link">Logout</Link>
                                        </li>
                                    }
                                    {/*<li className="nav-item dropdown">*/}
                                    {/*    <Link to="/register" className="nav-link dropdown-toggle" onClick={this.dropdownToggle}>Register</Link>*/}
                                    {/*    <ul className="dropdown-menu" aria-labelledby="homeMenu">*/}
                                    {/*        <li className="nav-item dropdown">*/}
                                    {/*            <Link to="/default" className="dropdown-item">Default</Link>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </Container>
                </header>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { is_toggle, leftSideBarType } = state.Layout;
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { is_toggle, leftSideBarType, user };
}

export default withRouter(connect(mapStateToProps, { toggleSidebar, changeSidebarType })(TopMenu));