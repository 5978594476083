import { combineReducers  } from 'redux';

// Front
import Layout from './layout/reducer';

import Breadcrumb from "./breadcrumb/reducer";

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Guest from './auth/guest/reducer';
import Forget from './auth/forgetpwd/reducer';

const rootReducer = combineReducers({
    // Public
    Layout,

    // Breadcrumb items
    Breadcrumb,

    // Authentication
    Guest,
    Account,
    Login,
    Forget
});

export default rootReducer;