import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from 'query-string';

import { MDBDataTable } from 'mdbreact';
import 'chart.js/auto';
import { Line, Pie } from 'react-chartjs-2';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class Acquisition extends Component {
    constructor(props) {
        super(props);

        let locationQuery = queryString.parse(this.props.router.location.search);

        this.state = {
            breadcrumbItems : this.props.router.params.urlId ? [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Acquisition", link : "/acquisition" },
                { title : 'Url Acquisition', link : "#" },
            ]: [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Acquisition", link : "#" },
            ],
            title: 'Acquisition',
            prefix: locationQuery.prefix ? locationQuery.prefix : '',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            url: {},
            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Visitors',
                        data: [],
                        fill: false,
                    },
                ]
            },
            pieChartData: {
                labels: ['Referer', 'Visitors'],
                datasets: []
            },
            tableData: {
                columns: [
                    { label: 'Referer', field: 'referer', sort: 'asc', width: 150 },
                    { label: 'Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            user: this.props.user
        }

        this.changeDayRange = this.changeDayRange.bind(this);

        this.loadUrl = this.loadUrl.bind(this);
        this.loadAcquisitionData = this.loadAcquisitionData.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadAcquisitionData();
        }, 500);
    }

    loadUrl = () => {
        if (this.props.router.params.urlId) {
            getApi('/urls/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
                (response) => {
                    this.setState({
                        url: response.url
                    });
                },
                (error) => {
                    this.props.router.navigate('/geo/acquisition');
                    return;
                }
            );
        }
    }

    loadAcquisitionData() {
        getApi('/acquisition/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') + '?range=' + this.state.range + (this.state.prefix ? '&prefix=' + this.state.prefix : '')).then(
            (response) => {
                let referers = response.referers;
                for (let referer_index = 0; referer_index < referers.table_data.length; referer_index++) {
                    if (referers.table_data[referer_index]['prefix']) {
                        referers.table_data[referer_index]['referer'] = <Link to={`/acquisition${(this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')}/?prefix=${referers.table_data[referer_index]['prefix']}`}>{referers.table_data[referer_index]['referer']}</Link>
                    }
                }
                this.setState({
                    pieChartData: {
                        ...this.state.pieChartData,
                        datasets: referers.pie_chart
                    },
                    lineChartData: {
                        labels: referers.line_chart.labels,
                        datasets: [
                            {
                                label: this.state.lineChartData.datasets[0].label,
                                fill: this.state.lineChartData.datasets[0].fill,
                                data: referers.line_chart.data
                            }
                        ]
                    },
                    tableData: {
                        columns: [
                            {
                                ...this.state.tableData.columns[0],
                                label: referers.table_label
                            },
                            this.state.tableData.columns[1],
                        ],
                        rows: referers.table_data
                    }
                });
            },
            (error) => {
                this.setState({
                    pieChartData: {
                        ...this.state.pieChartData,
                        datasets: []
                    },
                    lineChartData: {
                        labels: [],
                        datasets: [
                            {
                                label: this.state.lineChartData.datasets[0].label,
                                fill: this.state.lineChartData.datasets[0].fill,
                                data: []
                            }
                        ]
                    },
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems(this.props.router.params.urlId ? 'Url Acquisition' : 'Acquisition', this.state.breadcrumbItems);

        this.loadUrl();
        this.loadAcquisitionData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });

            if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
                window.location.reload();
            }
        }
    }

    render() {
        const { title, range, dayRanges, url, lineChartData, pieChartData, tableData, prefix } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {
                            this.props.router.params.urlId &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Url</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <p><strong>Link:</strong> <span><Link target="_blank" to= {url.site_url }>{ url.site_url }</Link></span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col xs="12" md="4">
                                        <div className="mb-3">
                                            <h5>{ title }</h5>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={ dayRange['value'] }>{ dayRange['label'] }</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    prefix &&
                                    <Row>
                                        <Col xs="12" md="6">
                                            <Line data={ lineChartData } options={{ scales: { yAxes: [ { ticks: { beginAtZero: true } } ] } }} />
                                        </Col>
                                        <Col xs="12" md="6">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                btn
                                                data={ tableData }
                                            />
                                        </Col>
                                    </Row>
                                }
                                {
                                    !prefix &&
                                    <Row>
                                        <Col xs="12" md="4">
                                            <Pie data={ pieChartData } />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <Line data={ lineChartData } options={{ scales: { yAxes: [ { ticks: { beginAtZero: true } } ] } }} />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                btn
                                                data={ tableData }
                                            />
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Acquisition));