import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { MDBDataTable } from 'mdbreact';
import 'chart.js/auto';
import { Line, Pie } from 'react-chartjs-2';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class BrowserOS extends Component {
    constructor(props) {
        super(props);

        let locationQuery = queryString.parse(this.props.router.location.search);

        this.state = {
            breadcrumbItems : this.props.router.params.urlId ? [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Browser & OS", link : "/browser-os" },
                { title : 'Url Browser & OS', link : "#" },
            ]: [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Browser & OS", link : "#" },
            ],
            type: locationQuery.type ? locationQuery.type : 'browser',
            title: 'Browser & OS',
            view_types: [
                { 'value': 'browser', 'label': 'Browser' },
                { 'value': 'os', 'label': 'Operating System' }
            ],
            family: locationQuery.family ? locationQuery.family : '',
            version: locationQuery.version ? locationQuery.version : '',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            url: {},
            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Browser & OS',
                        data: [],
                        fill: false,
                    },
                ]
            },
            pieChartData: {
                labels: ['Browser', 'Operating System'],
                datasets: []
            },
            tableData: {
                columns: [
                    { label: 'Browser OS', field: 'browser_os', sort: 'asc', width: 150 },
                    { label: 'Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            user: this.props.user
        }

        this.changeDayRange = this.changeDayRange.bind(this);

        this.loadUrl = this.loadUrl.bind(this);
        this.loadBrowserOS = this.loadBrowserOS.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadBrowserOS();
        }, 500);
    }

    loadUrl = () => {
        if (this.props.router.params.urlId) {
            getApi('/urls/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
                (response) => {
                    this.setState({
                        url: response.url
                    });
                },
                (error) => {
                    this.props.router.navigate('/browser-os');
                    return;
                }
            );
        }
    }

    loadBrowserOS = () => {
        getApi('/browser-os/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '') +
            '?range=' + this.state.range + '&type=' + this.state.type + (this.state.family ? '&family=' + this.state.family : '') +
            (this.state.version ? '&version=' + this.state.version : '')).then(
            (response) => {
                let browser_os = response.browser_os;
                for (let browser_os_index = 0; browser_os_index < browser_os.table_data.length; browser_os_index++) {
                    if (browser_os.table_data[browser_os_index]['family'] || browser_os.table_data[browser_os_index]['version']) {
                        browser_os.table_data[browser_os_index]['browser_os'] = <Link to={`/browser-os/${(this.props.router.params.urlId ? this.props.router.params.urlId + '/': '')}?type=${this.state.type}${browser_os.table_data[browser_os_index]['browser_os'] ? '&family=' + browser_os.table_data[browser_os_index]['family'] : ''}${browser_os.table_data[browser_os_index]['version'] ? '&version=' + browser_os.table_data[browser_os_index]['version'] : ''}`}>{browser_os.table_data[browser_os_index]['browser_os']}</Link>
                    }
                }
                this.setState({
                    pieChartData: {
                        labels: browser_os.pie_chart.labels,
                        datasets: [
                            {
                                label: '#',
                                data: browser_os.pie_chart.data,
                            }
                        ]
                    },
                    lineChartData: {
                        labels: browser_os.line_chart.labels,
                        datasets: [
                            {
                                label: this.state.lineChartData.datasets[0].label,
                                fill: this.state.lineChartData.datasets[0].fill,
                                data: browser_os.line_chart.data
                            }
                        ]
                    },
                    tableData: {
                        columns: [
                            {
                                ...this.state.tableData.columns[0],
                                label: browser_os.table_label
                            },
                            this.state.tableData.columns[1],
                        ],
                        rows: browser_os.table_data
                    }
                });
            },
            (error) => {
                this.setState({
                    pieChartData: {
                        labels: [],
                        datasets: []
                    },
                    lineChartData: {
                        labels: [],
                        datasets: [
                            {
                                label: this.state.lineChartData.datasets[0].label,
                                fill: this.state.lineChartData.datasets[0].fill,
                                data: []
                            }
                        ]
                    },
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems(this.props.router.params.urlId ? 'Url Browser & OS' : 'Browser & OS', this.state.breadcrumbItems);

        this.loadUrl();
        this.loadBrowserOS();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });

            if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
                window.location.reload();
            }
        }
    }

    render() {
        const { title, range, dayRanges, url, view_types, lineChartData, pieChartData, tableData, family, version } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {
                            this.props.router.params.urlId &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Url</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <p><strong>Link:</strong> <span><Link target="_blank" to={ url.site_url }>{ url.site_url }</Link></span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs="12" md="3">
                                        <div className="mb-3">
                                            <h5>{ title }</h5>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={ dayRange['value'] }>{ dayRange['label'] }</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="12" md="4" lg="3">
                                        <div>
                                            <ul className="d-flex fa-ul">
                                                {view_types.map((view_type, i) =>
                                                    <li className="p-2" key={i} value={view_type['value']}><Link to={`/browser-os/?type=${view_type['value']}`}>{view_type['label']}</Link></li>
                                                )}
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>

                                {
                                    (family || version) &&
                                    <Row>
                                        <Col xs="12" md="6">
                                            <Line data={ lineChartData } options={{ scales: { yAxes: [ { ticks: { beginAtZero: true } } ] } }} />
                                        </Col>
                                        <Col xs="12" md="6">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                btn
                                                data={ tableData }
                                            />
                                        </Col>
                                    </Row>
                                }
                                {
                                    (!family && !version) &&
                                    <Row>
                                        <Col xs="12" md="4">
                                            <Pie data={ pieChartData } />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <Line data={ lineChartData } />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                btn
                                                data={ tableData }
                                            />
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(BrowserOS));