import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { withRouter } from '../../../helpers/funcUtils';

//Import Images
import user from "../../../assets/images/users/user-4.jpg";

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <img className="rounded-circle header-profile-user" src={user} alt="Header Avatar"/>
                    </DropdownToggle>
                    <DropdownMenu end>
                    <DropdownItem tag="a" href="/logout" className="text-danger"><i className="mdi mdi-power font-size-17 text-muted align-middle mr-1 text-danger"></i> Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withRouter(ProfileMenu);