import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Label
} from "reactstrap";
import { connect } from "react-redux";
import 'chart.js/auto';
import { Line } from "react-chartjs-2";

import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class TwoLinesChart extends Component {
    constructor(props) {
        super(props);

        let self = this;

        this.state = {
            title: '',
            label: '',
            actionUrl: '',
            actionParam: '',
            range: '7days',
            day_ranges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            data: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        fill: false,
                        data: [],
                        borderColor: '#1967d2'
                    },
                    {
                        label: '',
                        fill: false,
                        data: [],
                        borderDash: [10, 10],
                        borderColor: '#1967d2'
                    }
                ]
            },
            options: {
                scaleOverride : true,
                plugins: {
                    tooltip: {
                        // Disable the on-canvas tooltip
                        enabled: false,

                        external: function(context) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            let tooltipModel = context.tooltip;
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                let dataIndex = tooltipModel.dataPoints[0].dataIndex;
                                let rationValue = 0;

                                let innerHtml = '<thead>';
                                innerHtml += '<tr><th>' + self.state.tooltipLabels[dataIndex] + '</th></tr>';
                                innerHtml += '</thead><tbody>';

                                let firstValue = parseInt(self.state.data.datasets[0].data[dataIndex]);
                                let secondValue = parseInt(self.state.data.datasets[1].data[dataIndex]);
                                if (!secondValue) {
                                    if (firstValue) rationValue = 100;
                                } else {
                                    if (!firstValue) {
                                        rationValue = -100;
                                    } else {
                                        rationValue = (firstValue - secondValue) / secondValue * 100;
                                    }
                                }
                                rationValue = rationValue.toFixed(2);
                                innerHtml += '<tr><td>' + self.state.label + '&nbsp;&nbsp;' + firstValue + '&nbsp;&nbsp;<span class="';
                                if (rationValue > 0) innerHtml += 'text-success"> <i class="mdi mdi-arrow-up"></i>';
                                else if (rationValue < 0) innerHtml += 'text-danger"> <i class="mdi mdi-arrow-down"></i>';
                                else if (!rationValue) innerHtml += '">';
                                innerHtml += '&nbsp;&nbsp;' + rationValue + '%</span></td></tr>';
                                innerHtml += '</tbody>';

                                let tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            let position = context.chart.canvas.getBoundingClientRect();
                            // let bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                            // tooltipEl.style.font = bodyFont.string;
                            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.backgroundColor = 'white';
                            tooltipEl.style.border = 'solid 1px #999';
                            tooltipEl.style.borderRadius = '3px';
                            tooltipEl.style.padding = '7px';
                        }
                    }
                },
                scales: {
                    y: {
                        min: -1,
                        max: 1,
                        stepSize: 0.1,
                    }
                }
            },
            totalData: [],
            tooltipLabels: [],
        }

        this.changeDayRange = this.changeDayRange.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadData();
        }, 500);
    }

    loadData = () => {
        if (!this.state.actionUrl) return;

        getApi(this.state.actionUrl + (this.state.range ? '?range=' + this.state.range : '')).then(
            (response) => {
                let response_data = response[this.state.actionParam];
                this.setState({
                    data: {
                        labels: response_data.labels,
                        datasets: [
                            {
                                ...this.state.data.datasets[0],
                                label: response_data.data_labels && response_data.data_labels.length ? response_data.data_labels[0] : '',
                                data: response_data.data && response_data.data.length ? response_data.data[0] : [],
                            },
                            {
                                ...this.state.data.datasets[1],
                                label: response_data.data_labels && response_data.data_labels.length > 1 ? response_data.data_labels[1] : '',
                                data: response_data.data && response_data.data.length > 1 ? response_data.data[1] : [],
                            }
                        ],
                    },
                    totalData: response_data.total_data,
                    tooltipLabels: response_data.tooltip_labels,
                });
            },
            (error) => {
                this.setState({
                    data: {
                        labels: [],
                        datasets: [
                            {
                                ...this.state.data.datasets[0],
                                label: '',
                                data: []
                            },
                            {
                                ...this.state.data.datasets[1],
                                label: '',
                                data: [],
                            }
                        ],
                    },
                    totalData: [],
                    tooltipLabels: [],
                });
            }
        )
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.action_url && this.props.action_param) {
                setTimeout(() => {
                    this.loadData();
                }, 500);
            }
            this.setState({
                title: this.props.title,
                label: this.props.label,
                actionUrl: this.props.action_url,
                actionParam: this.props.action_param
            });
        }
    }

    render() {
        const { data, title, totalData, range, day_ranges, options } = this.state;

        let first_data = totalData.length ? parseInt(totalData[0]) : 0;
        let second_data = totalData.length ? parseInt(totalData[1]) : 0;
        let data_ratio = 0;
        let ratio_class = '';
        let arrow_class = '';
        if (!second_data) {
            if (first_data) data_ratio = 100;
        } else {
            if (!first_data) {
                data_ratio = -100;
            } else {
                data_ratio = (first_data - second_data) / second_data * 100;
            }
        }
        data_ratio = data_ratio.toFixed(2);
        if (data_ratio > 0) { ratio_class = 'text-success'; arrow_class = 'up'; }
        else if (data_ratio < 0) { ratio_class = 'text-danger'; arrow_class = 'down'; }

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12" lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12">
                                        <div className="mb-3">
                                            <h5>{title}</h5>
                                            <span>{first_data}</span> <span className={ratio_class}><i className={`mdi mdi-arrow-${arrow_class}`}></i> {data_ratio}%</span>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    day_ranges.map((day_range, i) =>
                                                    <option key={i} value={day_range['value']}>{day_range['label']}</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <Line data={data} options={options}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
}

export default withRouter(connect(mapStateToProps, {
    getApi
})(TwoLinesChart));