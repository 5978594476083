import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import 'chart.js/auto';
import { Bar } from "react-chartjs-2";

import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class BarChartTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            actionUrl: '',
            actionParam: '',
            range: '7days',
            day_ranges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            tabs: [],
            chartData: [],
            chartColors: ['#1967d2', 'rgba(25,103,210,0.9)', 'rgba(25,103,210,0.8)', 'rgba(25,103,210,0.7)', 'rgba(25,103,210,0.6)',
                'rgba(25,103,210,0.5)', 'rgba(25,103,210,0.4)', 'rgba(25,103,210,0.3)', 'rgba(25,103,210,0.2)', 'rgba(25,103,210,0.1)'],
            chartOptions: {
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true
                    }
                }
            },
            activeTab: '',
        }

        this.changeDayRange = this.changeDayRange.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadData();
        }, 500);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    loadData = () => {
        if (!this.state.actionUrl) return;

        getApi(this.state.actionUrl + (this.state.range ? '?range=' + this.state.range : '')).then(
            (response) => {
                let chart_data = {};
                if (this.state.actionParam && response[this.state.actionParam]) {
                    let response_data = response[this.state.actionParam];
                    for (let tab_index = 0; tab_index < this.state.tabs.length; tab_index++) {
                        let tab_value = this.state.tabs[tab_index]['key'];
                        let tab_chart_data = {
                            labels: response_data.labels,
                            datasets: []
                        }

                        let data_key_count = 0;
                        let data_key_color_index = 0;
                        if (response_data.data[tab_value]) {
                            for (let data_key in response_data.data[tab_value]) {
                                data_key_count += 1;
                                data_key_color_index = this.state.chartColors.length ? (data_key_count % this.state.chartColors.length) : -1;
                                tab_chart_data.datasets.push({
                                    ...response_data.data[tab_value][data_key],
                                    stack: tab_value,
                                    backgroundColor: data_key_color_index >=0 ? this.state.chartColors[data_key_color_index] : '#1967d2',
                                    borderColor: data_key_color_index >=0 ? this.state.chartColors[data_key_color_index] : '#1967d2'
                                });
                            }
                        }
                        chart_data[tab_value] = tab_chart_data;
                    }
                }

                this.setState({
                    chartData : chart_data
                });
            },
            (error) => {
                this.setState({
                    chartData : []
                });
            }
        )
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.action_url && this.props.action_param) {
                setTimeout(() => {
                    this.loadData();
                }, 500);
            }
            this.setState({
                title: this.props.title,
                tabs: this.props.tabs,
                activeTab: this.props.tabs.length ? this.props.tabs[0]['key'] : '',
                actionUrl: this.props.action_url,
                actionParam: this.props.action_param
            });
        }
    }

    render() {
        const { title, tabs, activeTab, range, day_ranges, chartData, chartOptions } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12">
                                        <div className="mb-3">
                                            <h5>{title}</h5>
                                        </div>
                                        <div className="mb-3">
                                            <Label>Day of Range</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {day_ranges.map((day_range, i) =>
                                                    <option key={i} value={day_range['value']}>{day_range['label']}</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <Nav tabs justified className="nav-tabs-custom" role="tablist">
                                            {tabs.map((tab, i) =>
                                                <NavItem key={i}>
                                                    <NavLink
                                                        style={{ cursor : "pointer" }}
                                                        className={classnames({
                                                            active: activeTab === tab['key']
                                                        })}
                                                        onClick={() => {
                                                            this.toggleTab(tab['key']);
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                        <span className="d-none d-sm-block">{tab['label']}</span>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                            {tabs.map((tab, i) =>
                                                <TabPane key={i} tabId={tab['key']} className="p-3">
                                                    {
                                                        chartData[tab['key']] &&
                                                        <Bar data={chartData[tab['key']]} options={chartOptions}/>
                                                    }
                                                </TabPane>
                                            )}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
}

export default withRouter(connect(mapStateToProps, {
    getApi
})(BarChartTabs));