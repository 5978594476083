import axios from 'axios';

const SERVER_AUTH_URL = process.env.REACT_APP_SERVER_URL + "/" + process.env.REACT_APP_AUTH_ENDPOINT;

//Set the logged in user data in local session 
const setLoggedInUser = (user, token) => {
    localStorage.setItem('access_token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));
}

const getLoggedInUserToken = () => {
    if (!localStorage.getItem('access_token') || localStorage.getItem('access_token') === 'undefined') return null;
    return JSON.parse(localStorage.getItem('access_token'));
}

const getLoggedInUser = () => {
    if (!localStorage.getItem('user') || localStorage.getItem('user') === 'undefined') return null;
    return JSON.parse(localStorage.getItem('user'));
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUserToken() !== null;
}

//Set the logged in user data in local session
const setRegisteredInGuest = (guest_session) => {
    localStorage.setItem('guest_session', JSON.stringify(guest_session));
}

const getRegisteredInGuestSession = () => {
    if (!localStorage.getItem('guest_session')) return null;
    return JSON.parse(localStorage.getItem('guest_session'));
}

const isGuestAuthenticated = () => {
    return getRegisteredInGuestSession() !== null;
}

// Post Method
const postAuth = (url, data) => {
    axios.defaults.headers = {
        'Guest-Session': getRegisteredInGuestSession(),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,PATCH,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "Origin,X-Requested-With,Content-Type,Accept,Accept-Language,AuthorizationX-Authorization",
    }

    return axios.post(SERVER_AUTH_URL + url, data).then(response => {
        if (response.status >= 200 || response.status <= 299) {
            return response.data;
        }
        throw response.data;
    }).catch(err => {
        let message;
        if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
        } else {
            message = err[1];
        }
        throw message;
    });
}

// postForgetPwd 
const postForgetPwd = (url, data) => {
    return axios.post(SERVER_AUTH_URL + url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

export {
    setLoggedInUser,
    getLoggedInUser,
    getLoggedInUserToken,
    isUserAuthenticated,
    setRegisteredInGuest,
    getRegisteredInGuestSession,
    isGuestAuthenticated,
    postAuth,
    postForgetPwd
}