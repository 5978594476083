import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getApi } from "../../helpers/apiUtils";
import { getLoggedInUser } from "../../helpers/authUtils";
import { withRouter } from '../../helpers/funcUtils';

class TopIPs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : this.props.router.params.urlId ? [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Top Ips", link : "/top-ips" },
                { title : 'Url Top Ips', link : "#" },
            ]: [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Top Ips", link : "#" },
            ],
            url: {},
            tableData: {
                columns: [
                    { label: 'Ip', field: 'ip', sort: 'asc', width: 150 },
                    { label: 'Visitors', field: 'count', sort: 'asc', width: 250 },
                ],
                rows: []
            },
            user: this.props.user
        }

        this.loadUrl = this.loadUrl.bind(this);
        this.loadTopIps = this.loadTopIps.bind(this);
    }

    loadUrl = () => {
        if (this.props.router.params.urlId) {
            getApi('/urls/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
                (response) => {
                    this.setState({
                        url: response.url
                    });
                },
                (error) => {
                    this.props.router.navigate('/top-ips');
                    return;
                }
            );
        }
    }

    loadTopIps = () => {
        getApi('/top-ips/' + (this.props.router.params.urlId ? this.props.router.params.urlId + '/' : '')).then(
            (response) => {
                let ips = response.ips;
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: ips
                    }
                });
            },
            (error) => {
                this.setState({
                    tableData: {
                        columns: this.state.tableData.columns,
                        rows: []
                    }
                });
            }
        )
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems(this.props.router.params.urlId ? 'Url Top Ips' : 'Top Ips', this.state.breadcrumbItems);

        this.loadUrl();
        this.loadTopIps();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            });
        }
    }

    render() {
        const { url, tableData } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        {
                            this.props.router.params.urlId &&
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <div className="mb-3">
                                                <h5>Url</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <p><strong>Link:</strong> <span><Link target="_blank" to={ url.site_url }>{ url.site_url }</Link></span></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    btn
                                    data={ tableData }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(TopIPs));