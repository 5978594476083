import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

import TwoLinesChart from "../../component/Chart/TwoLinesChart";
import BarChartTabs from "../../component/Chart/BarChartTabs";
import GeoBarChart from "../../component/Chart/GeoBarChart";

class Overview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Overview", link : "#" },
            ],
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            visitors: {
                title: 'Visitors',
                label: 'Visitor',
                action_url: '/analytic/visitors/',
                action_param: 'visitors',
            },
            acquisition: {
                title: 'How do you acquire users?',
                tabs: [{key: 'all', label: 'Traffic Channel'}, {key: 'social', label: 'Social'}, {key: 'referral', label: 'Referrals'}],
                action_url: '/analytic/acquisition/',
                action_param: 'acquisition',
            },
            locations: {
                title: 'Where are your visitors?',
                action_url: '/analytic/locations/',
                action_param: 'locations',
            },
            user: this.props.user
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Overview", this.state.breadcrumbItems);
    }

    render() {
        let { visitors, acquisition, locations } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12" md="6" lg="4">
                        <Card>
                            <CardBody>
                                <TwoLinesChart
                                    title={visitors.title}
                                    label={visitors.label}
                                    action_url={visitors.action_url}
                                    action_param={visitors.action_param} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <Card>
                            <CardBody>
                                <BarChartTabs
                                    title={acquisition.title}
                                    tabs={acquisition.tabs}
                                    action_url={acquisition.action_url}
                                    action_param={acquisition.action_param} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <Card>
                            <CardBody>
                                <GeoBarChart
                                    title={locations.title}
                                    action_url={locations.action_url}
                                    action_param={locations.action_param} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Overview));