import axios from 'axios';
import { isUserAuthenticated, getLoggedInUserToken, getRegisteredInGuestSession } from './authUtils';

const SERVER_API_URL = process.env.REACT_APP_SERVER_URL + "/" + process.env.REACT_APP_API_ENDPOINT;
const SERVER_FRONT_URL = process.env.REACT_APP_SERVER_URL + "/" + process.env.REACT_APP_FRONT_ENDPOINT;

const setApiHeader = () => {
    if (isUserAuthenticated()) {
        axios.defaults.headers = {
            "Authorization": 'Bearer ' + getLoggedInUserToken()
        }
    } else {
        axios.defaults.headers = {
            'Guest-Session': getRegisteredInGuestSession(),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,PATCH,POST,DELETE,OPTIONS",
            "Access-Control-Allow-Headers": "Origin,X-Requested-With,Content-Type,Accept,Accept-Language,AuthorizationX-Authorization",
        }
    }
}

// GET Method
const getApi = (url) => {
    setApiHeader();

    return axios.get((isUserAuthenticated() ? SERVER_API_URL : SERVER_FRONT_URL) + url).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        let message;
        if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
        } else {
            message = err[1];
        }
        throw message;
    });
}

// POST Method
const postApi = (url, data) => {
    setApiHeader();

    return axios.post((isUserAuthenticated() ? SERVER_API_URL : SERVER_FRONT_URL) + url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        let message;
        if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
        } else {
            message = err[1];
        }
        throw message;
    });
}

// DELETE Method
const deleteApi = (url) => {
    setApiHeader();


    return axios.delete((isUserAuthenticated() ? SERVER_API_URL : SERVER_FRONT_URL) + url).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        let message;
        if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
        } else {
            message = err[1];
        }
        throw message;
    });
}

export { getApi, postApi, deleteApi }