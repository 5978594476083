import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import 'chart.js/auto';
import { Line, Pie } from "react-chartjs-2";
import { MDBDataTable } from 'mdbreact';

import { setBreadcrumbItems } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/authUtils";
import { getApi } from "../../helpers/apiUtils";
import { withRouter } from '../../helpers/funcUtils';

class Audience extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems : [
                { title : "Audience Overview", link : "#" },
            ],
            title: 'Visitors',
            range: '7days',
            dayRanges: [
                { 'value': '7days', 'label': 'Last 7 days' },
                { 'value': '14days', 'label': 'Last 14 days' },
                { 'value': '28days', 'label': 'Last 28 days' },
                { 'value': 'month', 'label': 'This Month' },
                { 'value': '90days', 'label': 'Last 90 days' },
                { 'value': '180days', 'label': 'Last 180 days' },
                { 'value': 'year', 'label': 'Last Calendar Year' }
            ],
            visitors: {
                all: 0,
                new: 0,
                urlClicks: 0,
                lineChartData: { data: { labels: [], datasets: [] }, options: { responsive: true } },
                // pieChartData: { data: { columns: [], type: "pie" }, pie: { label: { show: !1 }}, color: { pattern: ["#A8D0ED","#99C4E4","#8AB8DB","#7BACD2","#6BA1C9","#5C95C0","#4D89B7","#3E7DAE","#2E72A5","#1F669C"] } }
                pieChartData: { data: { labels: ['New Visitors', 'Return Remaining'], datasets: [] } }
            },
            activeTab: '',
            tabGroups: [
                {
                    label: 'Demographics',
                    tabs: [
                        { label: 'Language', key: 'language', link: '/geo/language/', link_key: 'language', link_append: true },
                        { label: 'Country',  key: 'country', link: '/geo/location/', link_key: 'country', link_append: true },
                        { label: 'City',     key: 'city', link: '/geo/location/', link_key: ['country', 'state', 'city'], link_append: true }
                    ]
                },
                {
                    label: 'System',
                    tabs: [
                        { label: 'Browser', key: 'ua_browser_family', link: 'browser-os/?type=browser', link_key: 'family', link_append: false },
                        { label: 'Operating System',  key: 'ua_os_family', link: 'browser-os/?type=os', link_key: 'family', link_append: false }
                    ]
                },
                {
                    label: 'Mobile',
                    tabs: [
                        { label: 'Device Info', key: 'ua_device_family', link: 'mobile-device/?type=info', link_key: 'family', link_append: false },
                        { label: 'Device Branding', key: 'ua_device_brand', link: 'mobile-device/?type=brand', link_key: 'family', link_append: false },
                    ]
                }
            ],
            tabDataList: {},
            user: this.props.user
        }

        this.changeDayRange = this.changeDayRange.bind(this);

        this.toggleTab = this.toggleTab.bind(this);
        this.loadAudienceData = this.loadAudienceData.bind(this);
    }

    changeDayRange = (e) => {
        this.setState({
            range: e.target.value
        });
        setTimeout(() => {
            this.loadAudienceData();
        }, 500);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    loadAudienceData = () => {
        getApi('/analytic/audience/overview/?range=' + this.state.range).then(
            (response) => {
                let audience = response.audience;
                let visitors = audience.visitors;
                let tab_data_list = {};
                let tab_info = {};
                let tab_info_link = '';
                let tab_data;
                let tab_data_total_value = 0;
                let tab_table_rows = [];
                let tab_row_val = '';
                let tab_data_val = '';
                let tab_data_percent = 0;
                for (let tab_group_index = 0; tab_group_index < this.state.tabGroups.length; tab_group_index++) {
                    for (let tab_index = 0; tab_index < this.state.tabGroups[tab_group_index]['tabs'].length; tab_index++) {
                        tab_table_rows = [];
                        tab_info = this.state.tabGroups[tab_group_index]['tabs'][tab_index];
                        if (tab_info['key'] in audience) {
                            tab_data_total_value = 0;
                            tab_data = audience[tab_info['key']];
                            for (let tab_data_index = 0; tab_data_index < tab_data.length; tab_data_index++) {
                                tab_data_total_value += tab_data[tab_data_index]['count'];
                            }
                            for (let tab_data_index = 0; tab_data_index < tab_data.length; tab_data_index++) {
                                tab_data_val = tab_data[tab_data_index][tab_info['key']];
                                tab_row_val = tab_data_val;
                                if (tab_info['key'] === 'country') {
                                    tab_row_val = <p className="mb-0"><img src={`/static/media/flags/${ tab_data[tab_data_index][tab_info['key']].replace(" ", "-").replace("'", "-") }.png`} alt={ tab_data[tab_data_index][tab_info['key']] } className='me-1' height='12'/>{ tab_data[tab_data_index][tab_info['key']] }</p>
                                }
                                tab_data_percent = (tab_data[tab_data_index]['count'] / tab_data_total_value * 100).toFixed(2);
                                if (tab_info['link_append']) {
                                    tab_info_link = '?';
                                } else {
                                    tab_info_link = '';
                                }
                                if (Array.isArray(tab_info['link_key'])) {
                                    for (let tab_link_index = 0; tab_link_index < tab_info['link_key'].length; tab_link_index++) {
                                        if (tab_info['link_append']) {
                                            if (tab_link_index) {
                                                tab_info_link += '&';
                                            }
                                        } else {
                                            tab_info_link += '&';
                                        }
                                        tab_info_link += tab_info['link_key'][tab_link_index] + '=' + tab_data[tab_data_index][tab_info['link_key'][tab_link_index]];
                                    }
                                } else {
                                    if (!tab_info['link_append']) {
                                        tab_info_link += '&';
                                    }
                                    tab_info_link += tab_info['link_key'] + '=' + tab_data_val;
                                }
                                tab_table_rows.push({
                                    [tab_info['key']]: <Link to={`${tab_info['link']}${tab_info_link}`}>{tab_row_val}</Link>,
                                    count: tab_data[tab_data_index]['count'],
                                    pcount: <div><div style={{ flex: `0 0 calc(${tab_data_percent}% - ${65 * parseFloat(tab_data_percent / 100)}px)` }}></div><div>{tab_data_percent}%</div></div>
                                });
                            }
                            tab_data_list[tab_info['key']] = {
                                columns: [
                                    { label: tab_info['label'], field: tab_info['key'], width: 150 },
                                    { label: 'Visitors', field: 'count', sort: 'desc', width: 150 },
                                    { label: '%Visitors', field: 'pcount', width: 150 },
                                ],
                                rows: tab_table_rows
                            };
                        }
                    }
                }
                this.setState({
                    visitors: {
                        all: visitors.visitors,
                        new: visitors.new_visitors,
                        urlClicks: visitors.url_clicks,
                        lineChartData: {
                            ...this.state.visitors.lineChartData,
                            data: {
                                labels: visitors.labels,
                                datasets: [{ label: 'Visitors', data: visitors.data, borderColor: '#1967d2' }]
                            }
                        },
                        pieChartData: {
                            ...this.state.visitors.pieChartData,
                            data: {
                                ...this.state.visitors.pieChartData.data,
                                datasets: [
                                    {
                                        label: 'My First Dataset',
                                        data: [visitors.new_visitors, parseInt(visitors.visitors) - parseInt(visitors.new_visitors)],
                                        backgroundColor: ["#A8D0ED", "#99C4E4" ],
                                    }
                                ]
                            }
                        }
                    },
                    tabDataList: tab_data_list
                })
            },
            (error) => {
                this.setState({
                    visitors: {
                        all: 0,
                        new: 0,
                        urlClicks: 0,
                        lineChartData: {
                            ...this.state.visitors.lineChartData,
                            data: {
                                labels: [],
                                datasets: [{ label: 'Visitors', data: [], borderColor: '#1967d2' }]
                            }
                        },
                        pieChartData: {
                            ...this.state.visitors.pieChartData,
                            data: {
                                ...this.state.visitors.pieChartData.data,
                                datasets: []
                            }
                        }
                    },
                    tabDataList: []
                });
            }
        );
    }

    componentDidMount() {
        if (this.props.user && (this.props.user.role === 'FREE' || this.props.user.status !== 'ACTIVE')) {
            this.props.router.navigate('/dashboard');
            return;
        }
        this.props.setBreadcrumbItems("Audience Overview", this.state.breadcrumbItems);

        this.setState({
            activeTab: this.state.tabGroups[0]['tabs'][0]['key']
        });

        this.loadAudienceData();
    }

    render() {
        const { title, range, dayRanges, visitors, activeTab, tabGroups, tabDataList } = this.state;

        console.log(visitors);

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col xs="12" md="4">
                                        <div className="mb-3">
                                            <h5>{title}</h5>
                                        </div>
                                        <div>
                                            <Label>Range of Day</Label>
                                            <select
                                                className="form-control"
                                                value={ range }
                                                onChange={ this.changeDayRange }
                                            >
                                                {
                                                    dayRanges.map((dayRange, i) =>
                                                        <option key={i} value={dayRange['value']}>{dayRange['label']}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="12">
                                        <Line data={visitors.lineChartData.data} options={visitors.lineChartData.options} className="mhp-150"/>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="12" md="8">
                                        <Row>
                                            <Col xs="12" md="4">
                                                <Card>
                                                    <CardBody>
                                                        <h6 className="mb-3 font-size-16">Visitors</h6>
                                                        <p><span className="ms-2">{visitors.all}</span></p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <Card>
                                                    <CardBody>
                                                        <h6 className="mb-3 font-size-16">New Visitors</h6>
                                                        <p><span className="ms-2">{visitors.new}</span></p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <Card>
                                                    <CardBody>
                                                        <h6 className="mb-3 font-size-16">Page Views</h6>
                                                        <p><span className="ms-2">{visitors.urlClicks}</span></p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" md="4">
                                        {
                                            visitors.pieChartData.data && visitors.pieChartData.data.datasets && !!visitors.pieChartData.data.datasets.length &&
                                            <Pie className="mhp-150" data={visitors.pieChartData.data} />
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        {tabGroups.map((tagGroup, i) =>
                                            <Card key={i} className="mt-2">
                                                <CardBody>
                                                    <Label>{tagGroup.label}</Label>
                                                    <Nav tabs  className="nav-tabs-custom-list" role="tablist">
                                                        {
                                                            tagGroup['tabs'].map((tab, j) =>
                                                            <NavItem key={j}>
                                                                <NavLink
                                                                    style={{ cursor : "pointer" }}
                                                                    className={classnames({
                                                                        active: activeTab === tab['key']
                                                                    })}
                                                                    onClick={() => {
                                                                        this.toggleTab(tab['key']);
                                                                    }}
                                                                >
                                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                                    <span className="d-none d-sm-block">{tab['label']}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                    </Nav>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Card>
                                            <CardBody>
                                                <TabContent activeTab={activeTab} className="tab-group">
                                                    {tabGroups.map((tagGroup, i) =>
                                                        <div key={i}>
                                                            {tagGroup['tabs'].map((tab, j) =>
                                                                <TabPane key={j} tabId={tab['key']} className="p-3">
                                                                    {
                                                                        tabDataList[tab['key']] &&
                                                                        <MDBDataTable
                                                                            className="table-list"
                                                                            responsive
                                                                            paging={false}
                                                                            sortable={false}
                                                                            searching={false}
                                                                            data={tabDataList[tab['key']]}
                                                                        />
                                                                    }
                                                                </TabPane>
                                                            )}
                                                        </div>
                                                    )}
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    let { user } = state.Login;
    if (!user) {
        user = getLoggedInUser();
    }
    return { user };
}

export default withRouter(connect(mapStateToProps, {
    setBreadcrumbItems,
    getApi
})(Audience));